import React, { useEffect, useState } from 'react'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { MinimalActionButton } from '../../../../../components/form/minimalActionButton'
import { formatDateWithTime } from '../../../../../utils/dateFormat'
import { DefaultParamsForRecall } from '../../../../../utils/defaulStates'
import { Actions } from '../../../../../components/form/actions'
import { ModalForm } from '../../../../../components/modals/ModalForm'
import { RegistroDeudaEnvaseHistorialValues } from '../../../../../FormSchemes/DeudaEnvaseHistorialScheme'
import { UpdateValuesModal } from '../../../../../FormSchemes/GeneralFunctions'
import { DeleteValues } from '../../../../../FormSchemes/RolScheme'
import { useSelector } from 'react-redux'
import { Callers } from '../../../../../hooks/Callers'

export const ListDeudaEnvaseHistorialTable = ({
    reload,
    setReload,
    globalParams
}) => {
    const {
        dispatch,
        params,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        requestAuthPaginate,
        TableContainer,
        Paginator,
        Searcher,
        TableSection,
        ActionSection,
        Section,
    } = useGeneralParams()
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)
    const [RegistrarCobroODevolucionModal, setRegistrarCobroODevolucionModal] = useState(false)
    const [DeleteModal, setDeleteModal] = useState(false)
    const [CurrentData, setCurrentData] = useState(false)
    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/deudaEnvases/garantía/${params.deuda_id}/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'producto' }
        })
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), params.deuda_id, reloadSubTable]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), params.deuda_id, reloadSubTable]);

    const reloadTable = () => {
        Callers(dispatch)
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
    }
    return (
        <Section>
            <ActionSection>
                {
                    <Actions
                        buttons={[
                            {
                                icon: 'minus',
                                label: `Registrar ${globalParams?.tipoDeuda === 'garantía' ? 'devolución directa de garantía' : 'recojo directo de préstamo'}`,
                                action: () => setRegistrarCobroODevolucionModal(true),
                                className: 'dark:bg-red-700 bg-red-500 text-white'
                            },
                        ]}
                    />
                }
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Producto',
                            value: 'producto'
                        },
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>

                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[

                        {
                            label: 'Cuaderno',
                            columns: ['cuaderno']
                        },
                        ...(globalParams?.tipoDeuda === 'garantía' ?
                            [
                                {
                                    label: 'Monto Garantia',
                                    columns: ['monto'],
                                    numberValue: true,
                                },
                                {
                                    label: 'Saldo Garantia',
                                    columns: ['monto_garantia'],
                                    numberValue: true,
                                },
                            ] :
                            []
                        ),
                        {
                            label: 'Cantidad producto',
                            columns: ['cantidad'],
                            numberValue: true,
                        },
                        {
                            label: 'Saldo producto',
                            columns: ['saldo'],
                            numberValue: true,
                        },
                        {
                            label: `Fecha de ${globalParams?.tipoDeuda === 'garantía' ? 'devolución' : 'recojo'}`,
                            columns: ['createdAt'],
                            transform: true,
                            func: formatDateWithTime

                        },
                        {
                            label: 'Obs',
                            columns: ['obs']
                        },
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'delete',
                                    icon: 'fa-trash',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                    reference: 'id'
                                },
                            ],
                        },
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {
                RegistrarCobroODevolucionModal &&
                <ModalForm
                    setModal={setRegistrarCobroODevolucionModal}
                    label={`Registrar ${globalParams?.tipoDeuda}`}
                    dataValues={RegistroDeudaEnvaseHistorialValues(params.deuda_id, globalParams?.tipoDeuda)}
                    urlApi={`/deudaEnvaseHistorialDirecto`}
                    method={'post'}
                    call={() => {
                        reloadTable()
                        // dispatch(setReloadSubTable())
                        // dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                DeleteModal &&
                <ModalForm
                    setModal={setDeleteModal}
                    label='Eliminar deuda'
                    dataValues={DeleteValues(CurrentData)}
                    urlApi={`/deudaEnvaseHistorialDirecto/${CurrentData.id}`}
                    method={'delete'}
                    call={reloadTable}
                    buttonLabel='Eliminar'
                />
            }

        </Section>
    )
}