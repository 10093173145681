import { Pie } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js'
import { PieChartTemplate } from '../../../../components/chart/BarChartTemplate'
import { listToString } from '../../../../utils/dataValidations';
// import Draggable from 'react-draggable'

ChartJS.register(ArcElement, Tooltip, Legend);

// const getRandomColor = () => {
//     return '#' + Math.floor(Math.random() * 16777215).toString(16);
// }

const listColors = [
    'rgba(255, 99, 132, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(254,157,66,1)',
    'rgba(199,201,205,1)',
    'rgba(151,101,255,1)'
]

export default function Pies({ data, title, position }) {
    // const backgroundColors = aditionalCols?.map(() => getRandomColor());
    var dataSets = data?.map(() => 0) ?? []
    if (data != null) {
        let counter = 0
        for (const item of data) {
            dataSets[counter] = item.cantidades[position]
            counter++
        }
    }

    var misoptions = {
        responsive: true,
        animation: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: {
                    color: 'rgba(150,150,150)',
                    fontSize: 16
                }
            }
        },
        scales: {
            y: {
                min: 0,
                max: 1,
                ticks: { color: 'rgba(170,170,170)' },
                grid: {
                    color: 'rgba(170,170,170,.7)'
                },
            },
            x: {
                min: 0,
                max: 1,
                ticks: { color: 'rgba(170,170,170)' },
                grid: {
                    color: 'rgba(170,170,170,.7)'
                },
            }
        }
    }

    var midata = {
        labels: data?.map(a => listToString([a.nombres, a.apellido_paterno, a.apellido_materno], ' ')) ?? [],
        datasets: [
            {
                label: 'Ventas',
                data: dataSets,
                backgroundColor: listColors,
                // borderColor: [
                //     'rgba(255, 99, 132, 1)',
                //     'rgba(255, 206, 86, 1)',
                //     'rgba(54, 162, 235, 1)',
                //     'rgba(75, 192, 192, 1)',
                //     'rgba(153, 102, 255, 1)',
                // ],
                borderWidth: 0.5,
            },
        ]
    }

    return (
        <PieChartTemplate
            dataSets={dataSets}
            title={title}
        >
            <Pie data={midata} options={misoptions} />
        </PieChartTemplate>
    )
}