import React from 'react'
import { useParams } from 'react-router-dom';
import { DescriptionTargetInfo, ItemCardTargetInfo, LogoTargetInfo, MainTargetInfo, NumberCalculatorsTargetInfo, NumberGridTargetInfo, TitleTargetInfo } from '../../../../../components/card/MainTargetInfo';
import { formatDate } from '../../../../../utils/dateFormat';
import { useSelector } from 'react-redux';

const CardInfo = ({ reload, data, setData, globalParams }) => {
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)
    const params = useParams()
    return (
        <MainTargetInfo
            extraClassName='grid-cols-1 lg:grid-cols-3 gap-1'
            urlApi={`/deudas/${params.deuda_id}`}
            setData={setData}
            dataRef={[params.deuda_id, reloadSubTable]}
        >
            <ItemCardTargetInfo
                logo={<LogoTargetInfo logo='fa-boxes-packing' />}
                // logoLabel={<LogoLabelTargetInfo logo={<NoteIconSvg />} />}
                aditionalClassName='lg:col-span-2'
            >
                <TitleTargetInfo label={null} data={data?.producto} />
                <DescriptionTargetInfo label={'Fecha límite de deuda:'} data={formatDate(data?.fecha_limite)} />
                <DescriptionTargetInfo label='Fecha de registro:' data={formatDate(data?.createdAt)} />
                <DescriptionTargetInfo label='Observaciones:' data={data?.obs} />
            </ItemCardTargetInfo>
            <NumberCalculatorsTargetInfo colspan=''>
                <NumberGridTargetInfo label='Monto inicial Bs.' data={data?.saldo_inicial} />
                <NumberGridTargetInfo label='Monto Bs.' data={data?.saldo} />
                <NumberGridTargetInfo label='' data={''} />
                <NumberGridTargetInfo label='' data={''} borderBotton={false} />
            </NumberCalculatorsTargetInfo>
        </MainTargetInfo>
    )
}

export default CardInfo