// import logotipo from '../../../../../assets/logo.png'
import View from '../../../../../components/configPdf/View'
import { Page, Document, PDFViewer, Image } from '@react-pdf/renderer'
import fullLogo from '../../../../../assets/fullLogo.png'
import Text from '../../../../../components/configPdf/Text'
import { useEffect, useState } from 'react'
import { requestDefaulAuth } from '../../../../../http/httpRequest'
import { useDispatch } from 'react-redux'
import Text3 from '../../../../../components/configPdf/Text3'
import { listToString } from '../../../../../utils/dataValidations'

const NotaEntregaProductoPdf = ({
  orientation,
  data,
  selecteds = []
}) => {
  const [DataNotaEntrega, setDataNotaEntrega] = useState(null)
  const dispatch = useDispatch()
  const getNotaEntrega = async () => {
    await requestDefaulAuth(
      'post',
      `/almacenNotaEntregaProducto`,
      data,
      setDataNotaEntrega,
      dispatch
    )
  }
  useEffect(() => {
    getNotaEntrega()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const MyDoc = (
    <Document>
      <Page size='A4' orientation={orientation} style={{ padding: '0px 6px' }}>
        {DataNotaEntrega?.map((nota, index) => (
          <View
            key={index}
            width='100%'
            flexDirection='column'
            margin='14px 0px 13px 0px'
          >
            <View
              alignItems='flex-start'
              width='100%'
              flexDirection='column'
            >
              <View
              >
                <View
                  margin='0px 0px 0px 0px'
                // border={{ bottom: true }}
                >
                  <View
                    width='25%'
                    // padding='0px 30px'
                    border={{ left: true, top: true, right: true, bottom: true }}
                    alignItems='center'
                    justifyContent='center'
                  >
                    <View width='70%'>
                      <Image src={fullLogo} />
                    </View>
                  </View>
                  <View
                    width='50%'
                    border={{ top: true, right: true, bottom: true }}
                    textAlign='center'
                    alignItems='center'
                    justifyContent='center'
                    flexDirection='column'
                  >
                    <View
                      width='100%'
                      justifyContent='center'
                    >
                      <Text fontSize='10px' bold={true}>
                        NOTA DE ENTREGA
                      </Text>
                    </View>
                    <View
                      width='100%'
                      justifyContent='center'
                    >
                      <Text fontSize='10px' bold={true}>
                        DE PRODUCTO
                      </Text>
                    </View>
                  </View>
                  <View
                    width='25%'
                    justifyContent='center'
                    flexDirection='column'
                    border={{ top: true, right: true, bottom: true }}
                  >
                    <View
                      width='100%'
                      border={{ bottom: true }}
                    >
                      <Text3
                        bold
                      >
                        ENCARGADO DEL DESPACHO:
                      </Text3>
                    </View>
                    <View
                      width='100%'
                      border={{ bottom: true }}
                    >
                      <Text3
                        bold
                      >
                        {data?.encargado_despacho !== null && data?.encargado_despacho !== '' ? data?.encargado_despacho : ' '}
                      </Text3>
                    </View>
                    <View
                      width='100%'
                    >
                      <Text3 style={{ color: 'red' }} bold>N° DE SALIDA: {nota?.boleta}</Text3>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              margin='3px 0 0 0'
            >
              <View justifyContent='center' border={{ right: true, bottom: true, left: true, top: true }} width='20%'>
                <Text3 bold={true}>
                  FECHA DE SALIDA:
                </Text3>
              </View>
              <View justifyContent='center' border={{ right: true, bottom: true, top: true }} width='20%'>
                <Text3 bold={true}>
                  {data?.fecha_salida}
                </Text3>
              </View>
              <View
                // margin='5px 0 0 0'
                width='20%'
              >
              </View>
              <View justifyContent='center' border={{ right: true, bottom: true, left: true, top: true }} width='20%'>
                <Text3 bold={true}>
                  TRANSPORTISTA:
                </Text3>
              </View>
              <View justifyContent='center' border={{ right: true, bottom: true, top: true }} width='20%'>
                <Text3 bold={true}>
                  {data?.transportista !== null && data?.transportista !== '' ? data?.transportista : ' '}
                </Text3>
              </View>
            </View>
            <View
              margin='3px 0 0 0'
            >
              <View justifyContent='center' border={{ right: true, bottom: true, left: true, top: true }} width='25%'>
                <Text3 bold={true}>
                  NOMBRE DEL RECEPTOR:
                </Text3>
              </View>
              <View justifyContent='center' border={{ right: true, bottom: true, top: true }} width='50%'>
                <Text3 bold={true}>
                  {listToString([nota?.receptor?.encargado_nombres, nota?.receptor?.encargado_apellido_paterno, nota?.receptor?.encargado_apellido_materno], ' ')}
                </Text3>
              </View>
            </View>
            <View
              margin='3px 0 0 0'
            >
              <View justifyContent='center' alignItems='center' border={{ right: true, bottom: true, left: true, top: true }} width='20%'>
                <Text3 bold={true}>
                  DETALLES / DESCRIPCION:
                </Text3>
              </View>
              <View flexDirection='column' width='80%'>
                <View border={{ right: true, bottom: true, top: true }} justifyContent='center' width='81%'>
                  <Text bold>
                    CANTIDAD EN UNIDADES
                  </Text>
                </View>
                <View width='100%'>
                  <View border={{ bottom: true, right: true }} justifyContent='center' width='27%' style={{ backgroundColor: '#ffe7cc' }}>
                    <Text bold>
                      200 LT
                    </Text>
                  </View>
                  <View border={{ bottom: true, right: true }} justifyContent='center' width='27%' style={{ backgroundColor: '#ffe7cc' }}>
                    <Text bold>
                      100 LT
                    </Text>
                  </View>
                  <View border={{ bottom: true, right: true }} justifyContent='center' width='27%' style={{ backgroundColor: '#e3e4fc' }}>
                    <Text bold>
                      PRODUCTOS ENVASADOS
                    </Text>
                  </View>
                  <View border={{ bottom: true }} justifyContent='center' width='19%'>
                    <Text bold>
                    </Text>
                  </View>
                </View>
                <View width='100%'>
                  <View justifyContent='center' border={{ right: true, bottom: true }} width='9%'>
                    <Text3 bold={true}>
                      SDO.
                    </Text3>
                  </View>
                  <View justifyContent='center' border={{ right: true, bottom: true }} width='9%'>
                    <Text3 bold={true}>
                      ING.
                    </Text3>
                  </View>
                  <View justifyContent='center' border={{ right: true, bottom: true }} width='9%'>
                    <Text3 bold={true}>
                      TOTAL
                    </Text3>
                  </View>
                  <View justifyContent='center' border={{ right: true, bottom: true }} width='9%'>
                    <Text3 bold={true}>
                      SDO.
                    </Text3>
                  </View>
                  <View justifyContent='center' border={{ right: true, bottom: true }} width='9%'>
                    <Text3 bold={true}>
                      ING.
                    </Text3>
                  </View>
                  <View justifyContent='center' border={{ right: true, bottom: true }} width='9%'>
                    <Text3 bold={true}>
                      TOTAL
                    </Text3>
                  </View>
                  <View justifyContent='center' border={{ right: true, bottom: true }} width='9%'>
                    <Text3 bold={true}>
                      TRIMALTA
                    </Text3>
                  </View>
                  <View justifyContent='center' border={{ right: true, bottom: true }} width='9%'>
                    <Text3 bold={true}>
                      DIVINO
                    </Text3>
                  </View>
                  <View justifyContent='center' border={{ right: true, bottom: true }} width='9%'>
                    <Text3 bold={true}>
                      DÑA. LUISA
                    </Text3>
                  </View>
                  <View justifyContent='center' border={{ right: true, bottom: true }} width='19%'>
                    <Text3 bold={true}>
                      OBSERVACIONES
                    </Text3>
                  </View>
                </View>
              </View>
            </View>
            {/* AQUI */}
            <View>
              <View alignItems='center' border={{ right: true, bottom: true, left: true }} width='20%'>
                <Text3 bold={true}>
                  Saldo anterior
                </Text3>
              </View>
              <View flexDirection='column' width='80%'>
                <View width='100%'>
                  <RenderItem
                    data={[nota?.tcc200?.saldo, 0, nota?.tcc200?.saldo, nota?.tcc100?.saldo, 0, nota?.tcc100?.saldo, nota?.envasados?.trimaltaSaldo, nota?.envasados?.divinoSaldo]}
                  />
                  <View justifyContent='center' border={{ right: true, bottom: true }} width='9%'>
                    <Text3 bold={true}>
                      {nota?.envasados?.dluisaSaldo}
                    </Text3>
                  </View>
                  <View justifyContent='center' border={{ right: true, bottom: true }} width='19%'>
                    <Text3 bold={true}>
                    </Text3>
                  </View>
                </View>
              </View>
            </View>
            <View>
              <View alignItems='center' border={{ right: true, bottom: true, left: true }} width='20%'>
                <Text3 bold={true}>
                  Antes de medio día
                </Text3>
              </View>
              <View flexDirection='column' width='80%'>
                <View width='100%'>
                  <RenderItem
                    data={[0, nota?.tcc200?.ingresoAm, nota?.tcc200?.ingresoAm, 0, nota?.tcc100?.ingresoAm, nota?.tcc100?.ingresoAm, nota?.envasados?.trimaltaIngresoAm, nota?.envasados?.divinoIngresoAm]}
                  />
                  <View justifyContent='center' border={{ right: true, bottom: true }} width='9%'>
                    <Text3 bold={true}>
                      {nota?.envasados?.dluisaIngresoAm}
                    </Text3>
                  </View>
                  <View justifyContent='center' border={{ right: true, bottom: true }} width='19%'>
                    <Text3 bold={true}>
                    </Text3>
                  </View>
                </View>
              </View>
            </View>
            <View>
              <View alignItems='center' border={{ right: true, bottom: true, left: true }} width='20%'>
                <Text3 bold={true}>
                  Después de medio día
                </Text3>
              </View>
              <View flexDirection='column' width='80%'>
                <View width='100%'>
                  <RenderItem
                    data={[0, nota?.tcc200?.ingresoPm, nota?.tcc200?.ingresoPm, 0, nota?.tcc100?.ingresoPm, nota?.tcc100?.ingresoPm, nota?.envasados?.trimaltaIngresoPm, nota?.envasados?.divinoIngresoPm]}
                  />
                  <View justifyContent='center' border={{ right: true, bottom: true }} width='9%'>
                    <Text3 bold={true}>
                      {nota?.envasados?.dluisaIngresoPm}
                    </Text3>
                  </View>
                  <View justifyContent='center' border={{ right: true, bottom: true }} width='19%'>
                    <Text3 bold={true}>
                    </Text3>
                  </View>
                </View>
              </View>
            </View>
            {/* AQUI */}
            <View>
              <View justifyContent='center' alignItems='center' width='10%'>
              </View>
              <View justifyContent='center' alignItems='center' border={{ right: true, bottom: true, left: true }} width='10%' style={{ backgroundColor: '#a4e9fc' }}>
                <Text3 bold={true}>
                  TOTAL
                </Text3>
              </View>
              <View flexDirection='column' width='80%'>
                <View width='100%'>
                  <RenderItemTotal
                    data={[nota?.tcc200?.saldo, nota?.tcc200?.totalIngreso, nota?.tcc200?.total, nota?.tcc100?.saldo, nota?.tcc100?.totalIngreso, nota?.tcc100?.total, nota?.envasados?.trimaltaTotal, nota?.envasados?.divinoTotal]}
                  />
                  <View justifyContent='center' border={{ right: true, bottom: true }} width='9%'>
                    <Text3 bold={true}>
                      {nota?.envasados?.dluisaTotal}
                    </Text3>
                  </View>
                </View>
              </View>
            </View>
            <View
              margin='51px 0 0 0'
              justifyContent='center'
            >
              <View
                width='30%'
                flexDirection='column'
              >
                <View justifyContent='center' border={{ right: true, bottom: true, left: true, top: true }}>
                  <Text3 bold>
                    ENCARGADO DEL ALMACÉN
                  </Text3>
                </View>
              </View>
              <View
                width='30%'
                flexDirection='column'
                style={{ marginLeft: '100px' }}
              >
                <View justifyContent='center' border={{ right: true, bottom: true, left: true, top: true }}>
                  <Text3 bold>
                    VENDEDOR
                  </Text3>
                </View>
              </View>
            </View>
            <View
              margin='3px 0 0 0'
              justifyContent='center'
            >
              <View justifyContent='center' border={{ right: true, bottom: true, left: true, top: true }} width='100%'>
                <Text3 bold={true}>
                  NOTA: TOMAR EN CUENTA QUE EL RETORNO DE ENVASES ES OBLIGATORIO, CASO CONTRARIO EL RESPONSABLE TENDRA QUE REALIZAR LA REPOSICION DE Bs 400
                </Text3>
              </View>
            </View>
          </View>
        ))}
      </Page>
    </Document>
  )

  return (
    DataNotaEntrega ?
      <PDFViewer
        style={{
          width: orientation === 'landscape' ? 1200 : 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
      :
      <div className='mx-16 my-12'>
        <div>
          <svg aria-hidden='true' className='inline w-14 h-14 text-gray-200 animate-spin dark:text-gray-500 dark:fill-yellow-500 fill-yellow-600' viewBox='0 0 100 101' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z' fill='currentColor' />
            <path d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z' fill='currentFill' />
          </svg>
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
  )
}

const RenderItem = ({ data }) => {
  return (
    data.map((item, index) => (
      <View key={index} justifyContent='center' border={{ right: true, bottom: true }} width='9%'>
        <Text3 bold={true}>
          {item}
        </Text3>
      </View>
    ))
  )
}

const RenderItemTotal = ({ data }) => {
  return (
    data.map((item, index) => (
      <View key={index} justifyContent='center' border={{ right: true, bottom: true }} width='9%'>
        <Text3 bold={true}>
          {item}
        </Text3>
      </View>
    ))
  )
}
export default NotaEntregaProductoPdf