import { useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'

export const VerificationPathByString = (value) => {
    const location = useLocation()

    return location.pathname.includes(value)
}
export const VerificationPathSubByString = (value) => {
    const params = useParams()

    return params['*'].includes(value)
}

const images = {
    'Abarrotes': 'Abarrotes.png',
    'Restaurante': 'Restaurante.png',
    'Carniceria': 'Carniceria.png',
    'Tienda': 'Tienda.png',
    'Puesto': 'Puesto movil.png',
    'Kiosco': 'Kiosco.png',
    'Feria': 'Feria.png',
    'Empresa': 'Empresa.png',
    'Mayorista': 'Mayorista.png',
    'Fabrica': 'Fabrica.png',
}

export function VerificationImageExist(nameImage) {
    if (images[nameImage]) {
        return (images[nameImage])
    }
    else {
        return 'profile.png';
    }
}