import React, { useState } from 'react'
// import MainTargetInfo from './MainTargetInfo'
import { ListAlmacenCierreTable } from './ListAlmacenCierreTable'
import { Route, Routes } from 'react-router-dom';
import { AlmacenHistorials } from './almacen_historials_cierres';
import { ErrorPage } from '../../../../../error/errorPage';
import { TabTableSection } from '../../../../../../components/tab/TabTableSection';
import CardInfoAlmacenProducto from '../../elementosGenerales/CardInfoAlmacenProducto';

export const AlmacenCierresHistorial = () => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <>
            <TabTableSection>
                <CardInfoAlmacenProducto
                    data={data}
                    setData={setData}
                />
                <ListAlmacenCierreTable
                    reload={reload}
                    setReload={setReload}
                    data={data}
                    setData={setData}
                />
            </TabTableSection>
            <Routes>
                <Route index element={
                    null
                } />
                <Route
                    path={`/historial/:almacen_cierre_id`}
                    element={<AlmacenHistorials />}
                />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </>
    )
}
