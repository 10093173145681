import React, { useEffect } from 'react'
import { MinimalActionButton } from '../../../../components/form/minimalActionButton'
import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { DefaultParamsForRecall } from '../../../../utils/defaulStates'
import { useSelector } from 'react-redux'
import { Callers } from '../../../../hooks/Callers'
import { formatDateWithTime } from '../../../../utils/dateFormat'

export const ListCuadernoGastoTable = () => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        stateData, setStateData,
        requestAuthPaginate,
        TableContainer,
        Paginator,
        Searcher,
        RangeDate,
        TableSection,
        ActionSection,
        setSelectAllChecked,
        setIsChecked,
        selecteds,
        setSelecteds,
        selectAllChecked,
        isChecked,
    } = useGeneralParams()
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)
    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/cuaderno/${null}/gastos/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: '$Cuaderno.User.nombres$' }
        })
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, reloadSubTable]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, reloadSubTable]);

    const reloadTable = () => {
        Callers(dispatch)
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
    }

    return (
        <>
            <ActionSection>
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Nombre',
                            value: 'nombre'
                        },
                        {
                            label: 'Estado',
                            value: 'estado'
                        }
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Cuaderno',
                            columns: ['codigo']
                        },
                        {
                            label: 'Vendedor',
                            columns: ['user_nombres', 'user_apellido_paterno:user_apellido_materno']
                        },
                        {
                            label: 'Tipo de Gasto',
                            columns: ['estado_gasto_nombre']
                        },
                        {
                            label: 'Efectivo Bs.',
                            columns: ['monto'],
                            numberValue: true,
                        },
                        {
                            label: 'Descripcion',
                            columns: ['obs'],
                            // tag: true
                        },
                        {
                            label: 'Fecha',
                            columns: ['createdAt'],
                            transform: true,
                            func: formatDateWithTime
                            // tag: true
                        }
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
        </>
    )
}