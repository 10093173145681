import React, { useState } from 'react'

import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { ErrorPage } from '../../../../error/errorPage'
import CardInfo from '../elementosGenerales/CardInfo'
import { ListComprasTable } from './ListComprasTable'
import { TabTableSection } from '../../../../../components/tab/TabTableSection'

export const Compras = () => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <>
            <TabTableSection>
                <CardInfo
                    reload={reload}
                    data={data}
                    setData={setData}
                // setReload={setReload}
                />
                <ListComprasTable
                    reload={reload}
                    setReload={setReload}
                    dataCard={data}
                />
            </TabTableSection>
            <div className=''>
                <Routes>
                    <Route index element={
                        null
                    } />
                    <Route path='*' element={<ErrorPage />} />
                </Routes>
            </div>
        </>
    )
}
