import React from 'react'
import { Link } from 'react-router-dom'

export const LogoTitle = ({ to, src = null, text }) => {
    return (
        <Link
            to={to}
            className='flex items-center text-2xl font-semibold text-gray-900 dark:text-white m-auto '>
            {
                src &&
                <img className='max-w-40 max-h-40 sm:max-w-72 sm:max-h-72 mr-2 rounded-xl' src={src} alt='logo' />
            }
            {text}
        </Link>
    )
}
