import * as Yup from 'yup';

export const RegistroDeudaHistorialValues = (deuda_id) => {
    return {
        initialValues: {
            deuda_id: deuda_id,
            metodo_pago: 'efectivo',
            monto: 0,
            cantidad: 0,
            obs: '',
        },
        fieldsValidation: Yup.object().shape({
            // cantidad: NumberIntegerRequired,            
        }),
        fields: [
            {
                label: 'Método de pago',
                name: 'metodo_pago',
                type: 'select',
                required: true,
                options: [
                    {
                        label: 'Efectivo',
                        value: 'efectivo'
                    },
                    {
                        label: 'Transferencia',
                        value: 'transferencia'
                    },
                    {
                        label: 'Cheque',
                        value: 'cheque'
                    },
                ],
            },
            {
                label: 'Monto',
                name: 'monto',
                type: 'groupnumber',
                subData: 'Bs.',
                disabled: false,
                required: true,
                highlight: 'bg-green-600 dark:bg-green-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                type: 'divider'
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const DeleteValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}