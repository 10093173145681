import React from 'react'
import { useParams } from 'react-router-dom'
import { DescriptionTargetInfo, ItemCardTargetInfo, LogoTargetInfo, MainTargetInfo, NumberCalculatorsTargetInfo, NumberGridTargetInfo, SubTitleTargetInfo } from '../../../../../../components/card/MainTargetInfo'
import { formatDate } from '../../../../../../utils/dateFormat'
import { useSelector } from 'react-redux'

const CardInfoEfectivoCierre = ({ reload, data, setData, nameLabel }) => {
    const params = useParams()
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)
    return (
        <MainTargetInfo
            extraClassName='grid-cols-1 md:grid-cols-2 gap-1'
            urlApi={`/efectivoCierre/${params.efectivo_cierre_id}`}
            setData={setData}
            dataRef={[params.efectivo_cierre_id, reloadSubTable]}
            reload={reload}
        >
            <ItemCardTargetInfo
                logo={<LogoTargetInfo logo='fa-coins' />}
                aditionalClassName='col-span-2 md:col-span-1'
            >
                <SubTitleTargetInfo label={'CIERRE'} data={data?.nombre} />
                <DescriptionTargetInfo label='Desde:' data={formatDate(data?.fecha_inicio)} />
                <DescriptionTargetInfo label='Hasta:' data={formatDate(data?.fecha_fin)} />
            </ItemCardTargetInfo>
            <NumberCalculatorsTargetInfo>
                <NumberGridTargetInfo label='Monto inicial' data={data?.monto_inicial} />
                <NumberGridTargetInfo label='Monto final' data={data?.monto_final} />
                <NumberGridTargetInfo label='Total ingreso' data={data?.monto_total_ingreso} />
                <NumberGridTargetInfo label='Total egreso' data={data?.monto_total_egreso} borderBotton={false} />
            </NumberCalculatorsTargetInfo>
        </MainTargetInfo>
    )
}

export default CardInfoEfectivoCierre