import React from 'react'
import { ListEstadoTable } from './ListEstadoTable'
import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { ErrorPage } from '../../../error/errorPage'
import { SubEstados } from './sub_estados'

export const Estados = () => {
    return (
        <div className='grid grid-cols-1 xl:grid-cols-2 gap-2'>
            <ListEstadoTable
            />
            <Routes>
                <Route index element={
                    null
                } />
                <Route
                    path={`/items/:estado_id/*`}
                    element={<SubEstados />}
                />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </div>
    )
}