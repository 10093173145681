import React from 'react'
import { ModalContainer } from './ModalContainer';
import { ModalHeader } from './ModalHeader';
import { ModalBodySample } from './ModalFormBody';
export const ModalSample = ({
    setModal,
    label,
    component,
    width = 'md:max-w-5xl',
    aditionalClassname=''
}) => {
    return (
        <ModalContainer
            setModal={setModal}
            width={width}
        >
            <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
                <ModalHeader
                    setModal={setModal}
                    label={label}
                />
                <ModalBodySample aditionalClassname={aditionalClassname}>
                    {component}
                </ModalBodySample>
            </div>
        </ModalContainer>
    )
}