import React from 'react'
import { ListCuadernoTable } from './ListCuadernoTable'
import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { ErrorPage } from '../../../error/errorPage'
import { CuadernoMovimientos } from './historialMovimientos'

export const IngresoCuaderno = () => {
    return (
        <div className='grid grid-cols-1 gap-2'>
            <div className='col-span-1 xl:col-span-1'>
                <ListCuadernoTable
                    globalParams={{}}
                />
            </div>
            <Routes>
                <Route index element={
                    null
                } />
                <Route
                    path={`/historial/:cuaderno_id/*`}
                    element={<CuadernoMovimientos
                        globalParams={{}}
                    />}
                />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </div>
    )
}