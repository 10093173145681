import React, { useState } from 'react'
// import MainTargetInfo from './MainTargetInfo'
import { ListEfectivoCierreTable } from './ListEfectivoCierreTable';
import CardInfoEfectivo from '../elementosGenerales/CardInfo';
import { Route, Routes } from 'react-router-dom';
import { ErrorPage } from '../../../../error/errorPage';
import { EfectivoHistorial } from '../efectivo_historial';
import { TabTableSection } from '../../../../../components/tab/TabTableSection';

export const EfectivoCierresHistorial = ({ efectivoParams, reloaders }) => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <div className='grid grid-cols-1 gap-2'>
            <TabTableSection>
                <CardInfoEfectivo
                    reload={reload}
                    data={data}
                    setData={setData}
                    nameLabel='CIERRES'
                    efectivoParams={efectivoParams}
                />
                <ListEfectivoCierreTable
                    reload={reload}
                    setReload={setReload}
                    dataCard={data}
                    efectivoParams={efectivoParams}
                    reloaders={reloaders}
                />
            </TabTableSection>
            <Routes>
                <Route index element={
                    null
                } />
                <Route
                    path={`/historial/:efectivo_cierre_id`}
                    element={<EfectivoHistorial
                        efectivoParams={{
                            type: 'efectivoCierre'
                        }}
                    />}
                />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </div>
    )
}
