import React, { useEffect } from 'react'
import { useGeneralParams } from '../../hooks/useDataPaginate'

export const ModalTableBody = ({
    field
}) => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        // selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData,
        setStateData,
        // createModal, setCreateModal,
        // currentData,
        setCurrentData,
        // deleteModal,
        setDeleteModal,
        // imports
        requestAuthPaginate,
        TableContainer,
        Paginator,
        // formatFilters,
        // Searcher,
        // FilterSelect,
        // RangeDate,
        // Actions,
        TableSection,
        // ActionSection,
        Section,
        // ModalForm,
        UpdateValuesModal,
        // redirect
    } = useGeneralParams('nombre')
    // const [dataList, setDataList] = useState([])

    // const getData = async () => {
    //     await requestAuth(
    //         'get',
    //         field.urlApi,
    //         null
    //     )
    //         .then((response) => {
    //             setData(response.data)
    //         }
    //         )
    //         .catch(error => {
    //             dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las opciones' }))
    //         })
    // }
    const getDataPaginate = async () => {
        await requestAuthPaginate(
            'get',
            field.urlApi,
            null,
            paginate,
            setData,
            setStateData,
            setPaginate,
            dispatch
        )
    }

    useEffect(() => {
        if (!field.noApi) {
            getDataPaginate()
        } else {
            setData(field.dataReq)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div>
            <Section>
                {/* <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Nombre',
                            value: 'nombre'
                        },
                        {
                            label: 'Estado',
                            value: 'estado'
                        }
                    ]}
                /> */}
                <TableSection
                    stateData={stateData}
                    paginator={Paginator}
                    data={data}
                >
                    <TableContainer
                        headers={[
                            ...field.tableParams.headers,
                            {
                                label: 'Acciones',
                                actions: [
                                    {
                                        type: 'delete',
                                        icon: 'fa-trash',
                                        action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                        reference: 'id',
                                        validate: { value: 'abierto', validator: true }
                                    }
                                ],
                                // stickyR: true
                            },
                        ]}
                        data={data.data}
                        checkList={true}
                        selecteds={selecteds}
                        setSelecteds={setSelecteds}
                        selectAllChecked={selectAllChecked}
                        setSelectAllChecked={setSelectAllChecked}
                        isChecked={isChecked}
                        setIsChecked={setIsChecked}
                        stateData={stateData}
                    />
                </TableSection>
            </Section>
        </div>
    )
}
