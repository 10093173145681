import React from 'react'
import TableContainer from '../table/TableContainer'
import { LoadingSection } from '../modals/formularioDeCompra/aditionalComponents'

export const CardTable = ({
    titulo,
    saldoAnterior,
    ingreso,
    totalTurriles,
    egreso,
    saldoActual,
    fecha,
    dataList,
    header,
    Loading,
    setLoading
}) => {
    return (
        <div className='dark:text-gray-100 p-2 bg-white dark:bg-transparent rounded-md'>
            <div className='text-center font-semibold text-xl'>{titulo}</div>
            <div className='grid grid-cols-1 sm:grid-cols-2 font-semibold'>
                <div><span>Saldo anterior: </span><span className=' text-red-500 dark:text-green-500'>{saldoAnterior}</span></div>
                <div><span>Egreso: </span><span className=' text-red-500 dark:text-green-500'>{egreso}</span></div>
                <div><span>Ingreso: </span><span className=' text-red-500 dark:text-green-500'>{ingreso}</span></div>
                <div><span>Saldo actual: </span><span className=' text-red-500 dark:text-green-500'>{saldoActual}</span></div>
                <div><span>Total turriles: </span><span className=' text-red-500 dark:text-green-500'>{totalTurriles}</span></div>
                <div><span>Fecha: </span><span className=' text-red-500 dark:text-green-500'>{fecha}</span></div>
            </div>
            <div className='overflow-x-scroll mt-1'>
                <TableContainer
                    headers={header}
                    data={dataList ?? []}
                    checkList={false}
                />
            </div>
            {
                Loading ?
                    <LoadingSection />
                    :
                    (dataList === null || dataList === undefined || dataList.length === 0) &&
                    <div className='text-center text-gray-600 bg-gray-300 dark:bg-gray-700 dark:text-gray-300 py-1'>Lista vacia!</div>
            }
        </div>
    )
}