import React, { useState } from 'react'
// import { ListAlmacenProductoTable } from './ListAlmacenProductoTable'
// import { Routes } from 'react-router-dom'
// import { Route } from 'react-router-dom'
// import { ErrorPage } from '../../../../error/errorPage'
// import { AlmacenHistorials } from './almacen_historials'
// import { AlmacenCierresHistorial } from './almacen_cierres'
import CardInfo from './CardInfo'
import { ListProductoEstadoTable } from './ListProductoEstadoTable';
import { TabTableSection } from '../../../../../components/tab/TabTableSection';

export const ProductosEstados = () => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <TabTableSection>
            {/* <div className='dark:bg-[#4a5c68] bg-[#4a5c6830] p-1 rounded-md'> */}
                <CardInfo
                    reload={reload}
                    data={data}
                    setData={setData}
                // setReload={setReload}
                />
                <ListProductoEstadoTable
                    reload={reload}
                    setReload={setReload}
                    dataCard={data}
                />
            {/* </div> */}
        </TabTableSection>
    )
}
