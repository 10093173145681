import React, { useEffect, useState } from 'react'
import { ButtonSubmit, Divisor, InputDataList, Loader, SimpleAlert, TitleFormData } from './aditionalComponents'
import { requestAuth } from '../../../http/httpRequest'
import { toastOn } from '../../../store/slices/toast'
import { useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import { ModalFormField } from '../ModalFormField'
import { TagsStepCompraDictionary } from '../../../utils/dictionary'
import { CompraProductosPaso3 } from '../../../FormSchemes/CompraScheme'

export const Step3 = ({ generalData, setMainRecall, setReload, saveUrlApi, stepNumber, stepsPermissions }) => {
  const dispatch = useDispatch()
  const [currentData, setCurrentData] = useState(null)
  const [vaciosSalida, setVaciosSalida] = useState([])
  const [data, setData] = useState([])
  const [loding, setLoading] = useState(true)
  const [options, setOptions] = useState([])
  const [defaultValue, setDefaultValue] = useState(null)

  const initialValues = {
    transportista_id: generalData?.transportista_id ?? '',
    productos: vaciosSalida,
    // monto: generalData?.monto ?? 0,
  };

  const getData = async () => {
    await requestAuth(
      'get',
      `compra/${generalData?.id}/paso/3`,
      null
    )
      .then((response) => {
        setCurrentData(response.data)
        setVaciosSalida(response.data.vaciosSalidaMap)

        setData(response.data.vaciosSalidaMap.map(s => ({ 'almacen_producto_id': s.id + '', 'cantidad': 0 })))


        const opt = response.data?.transportistasMap?.map(item => ({
          value: item['id'] ?? item.id.toString(),
          label: item['etiqueta']
        }))
        if (response?.data?.transportista_id)
          setDefaultValue({ value: response?.data?.transportista_id, label: response?.data?.transportista_nombre })

        setOptions(opt)

        setLoading(false)
      }
      )
      .catch(error => {
        dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las casillas.' }))
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addData = (value, name) => {
    const newData = data.map(item => {
      if (item.compra_historial_id === name) {
        return { ...item, cantidad: parseFloat(value) };
      }
      return item;
    })
    setData(newData)
  }
  const onSubmit = async (values) => {
    await requestAuth(
      'put',
      saveUrlApi,
      values
    )
      .then((response) => {
        dispatch(toastOn({ type: 'success', message: response.data.message }))
        setMainRecall(a => !a)
        setReload()
      }
      )
      .catch(error => {
        dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message ?? 'Error al enviar el formulario!' }))
      })
  }
  const sumarCantidades = (listObj) => {
    if (listObj == null || listObj.lenght <= 0)
      return false

    for (const objeto of listObj) {
      if (objeto.cantidad > 0)
        return true
    }
    return false
  }

  return (
    <>
      <TitleFormData
        title={TagsStepCompraDictionary[3]}
        subtitle={currentData?.fecha_confirmacion_salida}
      />
      {
        loding ?
          <Loader />
          :
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ errors, isSubmitting, touched, values, submitForm, handleChange, setFieldValue }) => (
              <>

                <InputDataList
                  list={vaciosSalida}
                  addData={addData}
                  field={{ indexLabel: 'etiqueta', indexId: 'id', indexRight: 'cantidadAlmacen' }}
                  setFieldValue={setFieldValue}
                  setDataList={setVaciosSalida}
                // title={<span>CANTIDAD DE EGRESO PARA EL ALMACÉN <b>{currentData?.almacen_nombre ?? ''}</b> E INGRESO PARA EL PROVEEDOR <b>{currentData?.almacen_proveedor_nombre ?? ''}</b></span>}
                />
                <Divisor />
                <Form className='col-span-12 grid grid-cols-12 bg-white rounded-lg dark:bg-gray-700 gap-3'>
                  <ModalFormField
                    fields={CompraProductosPaso3(options, defaultValue)?.fields}
                    errors={errors}
                    touched={touched}
                    values={values}
                    setValues={setFieldValue}
                    handleChange={handleChange}
                    calculators={CompraProductosPaso3()?.calculators}
                    submitForm={submitForm}
                  />
                  {
                    sumarCantidades(values.productos) &&
                    <SimpleAlert
                      data={<>Se creará un movimiento en egreso para el almacén <span className='font-semibold'>{currentData?.almacen_nombre ?? ''}</span> y un movimiento de ingreso para el proveedor <span className='font-semibold'>{currentData?.almacen_proveedor_nombre ?? ''}</span>.</>}
                    />
                  }
                  <div className='col-span-12 text-right mt-3'>
                    <ButtonSubmit
                      isSubmitting={isSubmitting}
                      disabled={generalData?.paso === stepNumber && stepsPermissions?.buttonsActive?.includes(3) ? false : true}
                      textButton='Siguiente'
                    />
                  </div>
                </Form>
              </>
            )}
          </Formik>
      }
    </>
  )
}