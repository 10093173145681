import React, { useState } from 'react';

function Tooltip({ text, children }) {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div className='relative inline-block'>
            {showTooltip && text && (
                <div className='transition-all duration-40 absolute right-9 top-0.5 z-10 bg-gray-600 dark:bg-gray-800 text-white dark:text-gray-100 text-sm font-semibold px-1.5 py-1 rounded-sm shadow-md tooltip w-max max-w-[200px]'>
                    {text}
                    {/* <div className='tooltip-arrow absolute -bottom-1 right-1.5 bg-gray-600 dark:bg-gray-800' /> */}
                    {/* <div className=' absolute -bottom-1 right-1.5 bg-gray-600 dark:bg-gray-800' /> */}
                </div>
            )}
            <div
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                className='relative inline-block'
            >
                {children}
            </div>
        </div>
    );
}

export default Tooltip;
