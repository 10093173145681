import React, { useEffect } from 'react'
import { MinimalActionButton } from '../../../../../components/form/minimalActionButton'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { formatDateWithTime } from '../../../../../utils/dateFormat'
import { DefaultParamsForRecall } from '../../../../../utils/defaulStates'
import { useSelector } from 'react-redux'
import { Callers } from '../../../../../hooks/Callers'

export const ListVendedorPrestamoTable = ({
    reload,
    setReload,
    //
    params,
    setSelectAllChecked,
    setIsChecked,
    selecteds,
    setSelecteds,
    selectAllChecked,
    isChecked,
    recallState,
}) => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        stateData, setStateData,
        requestAuthPaginate,
        TableContainer,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        RangeDate,
        TableSection,
        ActionSection,
    } = useGeneralParams()
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)
    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/users_vend/${params.user_vend_id}/prestamos/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'nombres' }
        })
    }
    useEffect(() => {
        getDataPaginate()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, params.user_vend_id, reloadSubTable])

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, params.user_vend_id, reloadSubTable])

    const reloadTable = () => {
        Callers(dispatch)
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
    }

    return (
        <>
            <ActionSection>
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Nombre',
                            value: 'nombre'
                        },
                        {
                            label: 'Estado',
                            value: 'estado'
                        }
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: 'Estado',
                                value: ''
                            },
                            {
                                label: 'Activo',
                                value: 'activo'
                            },
                            {
                                label: 'Inactivo',
                                value: 'inactivo'
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Cuaderno',
                            columns: ['codigo_cuaderno']
                        },
                        {
                            label: 'Cliente',
                            columns: ['nombres', 'apellido_paterno', 'apellido_materno'],
                        },
                        {
                            label: 'Producto',
                            columns: ['producto'],
                        },
                        {
                            label: 'Saldo inicial',
                            columns: ['saldo_inicial'],
                            numberValue: true,
                        },
                        {
                            label: 'Saldo Actual',
                            columns: ['saldo'],
                            numberValue: true,
                        },
                        {
                            label: 'Fecha Limite',
                            columns: ['fecha_limite'],
                            transform: true,
                            func: formatDateWithTime

                        },
                        {
                            label: 'Estado',
                            columns: ['estado'],
                            boolean: true,
                            booleanOptions: ['Completado', 'Pendiente'],
                            booleanIconTrue: 'fa-check',
                            booleanIconFalse: 'fa-circle'
                        },
                        {
                            label: 'Fecha de Prestamo',
                            columns: ['createdAt'],
                            transform: true,
                            func: formatDateWithTime

                        },
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />

            </TableSection>
        </>
    )
}