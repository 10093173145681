import React from 'react'
import useThemeHandling from '../../hooks/useThemeHandling'
import useNavigationHandling from '../../hooks/useNavigationHandling'

export const Setting = () => {
    const {
        theme,
        toggleTheme
    } = useThemeHandling()

    const {
        nav,
        toggleButton
    } = useNavigationHandling()
    return (
        <>
            <div className='grid grid-cols-3 gap-4 mb-4'>
                <div>
                    <h1 className='dark:text-white font-semibold text-md'>Configuración</h1>
                </div>
            </div>
            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12 xl:col-span-6 grid grid-cols-6 rounded bg-gray-50 dark:bg-gray-800 transition-all duration-500 ease-in-out'>
                    <div className='col-span-6 gap-4 p-2'>
                        <h1 className='text-gray-600 dark:text-gray-400 text-sm'>Aspecto del sistema</h1>
                    </div>
                    <hr className='col-span-6 dark:border-gray-600 border-gray-300' />
                    <div className='col-span-6 grid grid-cols-6 gap-4'>
                        <div className='flex col-span-4 items-center justify-between rounded px-2'>
                            <p className='text-sm text-gray-700 font-semibold dark:text-gray-300'>Modo oscuro</p>
                        </div>
                        <div className='flex col-span-2 items-center justify-end rounded'>
                            <button
                                onClick={toggleTheme}
                                className=' dark:hover:bg-gray-600 hover:bg-gray-300 text-white font-bold py-2 px-4 rounded'
                            >
                                <i className={`text-lg fa-solid fa-toggle-${theme === 'dark' ? 'on dark:text-sky-500 text-green-600' : 'off dark:text-gray-200 text-gray-400'}`} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-span-12 xl:col-span-6 grid grid-cols-6 rounded bg-gray-50 dark:bg-gray-800 transition-all duration-500 ease-in-out'>
                    <div className='col-span-6 gap-4 p-2'>
                        <h1 className='text-gray-600 dark:text-gray-400 text-sm'>Navegación</h1>
                    </div>
                    <hr className='col-span-6 dark:border-gray-600 border-gray-300' />
                    <div className='col-span-6 grid grid-cols-6 gap-4'>
                        <div className='flex col-span-4 items-center justify-between rounded px-2'>
                            <p className='text-sm text-gray-700 font-semibold dark:text-gray-300'>Desplegar todos los menús al recargar</p>
                        </div>
                        <div className='flex col-span-2 items-center justify-end rounded'>
                            <button
                                onClick={toggleButton}
                                className=' dark:hover:bg-gray-600 hover:bg-gray-300 text-white font-bold py-2 px-4 rounded'
                            >
                                <i className={`text-lg fa-solid fa-toggle-${nav === 'deploy' ? 'on dark:text-sky-500 text-green-600' : 'off dark:text-gray-200 text-gray-400'}`} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='grid grid-cols-6'>
            </div> */}
        </>
    )
}