import React, { useEffect, useState } from 'react'
import { requestDefaulAuth } from '../../../../http/httpRequest'
import { Section } from '../../../../components/table/Section'
import { useDispatch } from 'react-redux'
import { ActionSection } from '../../../../components/table/ActionSection'
import { RangeDate } from '../../../../components/datePicker/CustomDateRangePicker'
import { FilterSelect } from '../../../../components/form/FilterSelect'
import { MinimalActionButton } from '../../../../components/form/minimalActionButton'
import chico from '../../../../assets/chico.png';
import chica from '../../../../assets/chica.png';

export const IncentivosCards = () => {
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const [Load, setLoad] = useState(false)
    const [Paginate, setPaginate] = useState({ initial: '', final: '' })
    const [SelectedDay, setSelectedDay] = useState(undefined)
    const getDatosGenerales = async () => {
        setLoad(true)
        await requestDefaulAuth(
            'get',
            `/incentivos`,
            data,
            setData,
            dispatch,
            Paginate
        )
        setLoad(false)
    }
    useEffect(() => {
        getDatosGenerales()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Paginate?.initial, Paginate?.final, Paginate?.rolnames])

    const reload = () => {
        getDatosGenerales()
    }
    return (
        <>
            <Section>
                <div className='text-gray-700 dark:text-gray-300 font-semibold text-xl text-center mb-2'>
                    <div>
                        INCENTIVOS
                    </div>
                    <div>
                        VENTAS EN EFECTIVO DE VENDEDORES
                    </div>
                </div>
                <ActionSection>
                    <div className='flex gap-2 flex-wrap'>
                        <RangeDate
                            selectedDay={SelectedDay}
                            setSelectedDay={setSelectedDay}
                            setPaginate={setPaginate}
                        />
                        <FilterSelect
                            paginate={Paginate}
                            setPaginate={setPaginate}
                            name='rolnames'
                            options={[
                                {
                                    label: 'Todos',
                                    value: ''
                                },
                                {
                                    label: 'Carro distribuidor',
                                    value: 'ventas'
                                },
                                {
                                    label: 'Punto de venta',
                                    value: 'punto-de-venta'
                                },
                                {
                                    label: 'Oficina',
                                    value: 'oficina'
                                },
                            ]}
                        />
                        <MinimalActionButton
                            icon='repeat'
                            label=''
                            action={reload}
                        />
                    </div>
                </ActionSection>
                {
                    Load ?
                        <LoaderCards />
                        :
                        <CardUserVentas
                            data={data?.userList}
                        />
                }
            </Section>
        </>

    )
}

export const CardUserVentas = ({ data = [], zeroDanger = true }) => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-3'>
            {data?.map((i, index) => (
                <div
                    className='bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'
                    key={index}
                >
                    <div className='flex flex-col items-center p-5'>
                        <img src={i.genero === 'masculino' ? chico : chica} className='self-center h-20 ml-2 mr-3 my-0' alt='widev' />
                        {
                            zeroDanger ?
                                <h5 className={`text-xl font-semibold ${i.efectivoTotal === 0 ? 'text-red-500 dark:text-red-500' : 'text-green-500 dark:text-green-500'} `}>Bs. {i.efectivoTotal.toLocaleString('es-BO', { minimumFractionDigits: 2 })}</h5>
                                :
                                <h5 className={`text-xl font-semibold ${i.efectivoTotal === 0 ? 'text-green-500 dark:text-green-500' : 'text-red-500 dark:text-red-500'} `}>Bs. {i.efectivoTotal.toLocaleString('es-BO', { minimumFractionDigits: 2 })}</h5>
                        }
                        <h5 className='text-xl font-medium text-gray-900 dark:text-white'>{i.nombre}</h5>
                        <span className='text-md text-gray-500 dark:text-gray-400'>{i.rolName}</span>
                        {
                            i.ventaList?.map((y, idx) => (
                                <div
                                    className='flex gap-1 w-full mt-1 font-semibold dark:text-gray-300 text-indigo-600 justify-between border-b-[1px] border-dashed border-gray-500'
                                    key={idx}
                                >
                                    <div className='w-full'>{y.productoNombre}</div>
                                    <div className={`${y.cantidad === 0 ? 'dark:text-green-800 text-green-300' : 'dark:text-green-500 text-green-600'}`}>{y.cantidad}</div>
                                </div>
                            ))
                        }
                    </div>
                </div>


            ))}
        </div>
    )
}

export const LoaderCards = ({ length = 9 }) => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-3 animate-pulse'>
            {[1, 2, 3, 4].map((i, index) => (
                <div
                    className='bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'
                    key={index}
                >
                    <div className='flex flex-col items-center p-5'>
                        <i className='fa fa-user-circle self-center text-[80px] ml-2 mr-3 my-0 text-gray-500' />
                        {
                            Array.from({ length }, (_, index) => (
                                <div
                                    key={index}
                                    className='flex items-center justify-between pt-4'
                                >
                                    <div>
                                        <div className='h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5' />
                                        <div className='w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700' />
                                    </div>
                                    <div className='h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12' />
                                </div>
                            ))
                        }

                    </div>
                </div>
            ))}
        </div>
    )
}