import React from 'react'
import { GastosCuadernosCards } from './GastosCuadernosCards'
import { ListCuadernoGastoTable } from './ListCuadernoGastoTable'

export const GastosCuadernos = () => {
    return (
        <div className='flex gap-4 flex-col'>
            <GastosCuadernosCards />
            <ListCuadernoGastoTable />
        </div>
    )
}