import { Routes, Route } from 'react-router-dom'
import { Menu } from './menu'
import { ErrorPage } from '../error/errorPage'
import { ListMenus } from './ListMenus'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const Rol = () => {
    const location = useLocation()
    useEffect(() => {
        window.localStorage.setItem('location', location.pathname)
    }, [location.pathname])

    return (
        <>
            <Routes>
                <Route path='/' element={
                    <ListMenus
                        description={`Lista de menus disponibles:`}
                    />
                } />
                <Route
                    path={`menu/:menuname/*`}
                    element={<Menu />}
                />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </>
    )
}
