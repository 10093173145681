import React, { useState } from 'react'
import CardInfo from './CardInfo'
import { TabTableSection } from '../../../../../../../components/tab/TabTableSection';
import { AlmacenHistorialData } from '../../../AlmacenHistorialData';

export const AlmacenHistorials = () => {
    const [data, setData] = useState(null);
    return (
        <TabTableSection>
            <CardInfo
                data={data}
                setData={setData}
            />
            <AlmacenHistorialData
                dataCard={data}
                toList='cierre'
            />
        </TabTableSection>
    )
}
