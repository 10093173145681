import React, { useState } from 'react'
import CardInfoEfectivo from '../elementosGenerales/CardInfo';
import { ListEfectivoHistorialTable } from './ListEfectivoHistorialTable';
import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { ErrorPage } from '../../../../error/errorPage';
import CardInfoEfectivoCierre from '../efectivo_cierres/elementosGenerales/CardInfo';
import { useParams } from 'react-router-dom';
import { TabTableSection } from '../../../../../components/tab/TabTableSection';

export const EfectivoHistorial = ({ efectivoParams }) => {
    const [data, setData] = useState(null)
    const params = useParams()
    return (
        <TabTableSection>
            {
                efectivoParams?.type === 'efectivoCierre' ?
                    <CardInfoEfectivoCierre
                        reload={params.sub_efectivo_id}
                        data={data}
                        setData={setData}
                        nameLabel='CIERRES'
                        efectivoParams={efectivoParams}
                    />
                    :
                    <CardInfoEfectivo
                        reload={params.sub_efectivo_id}
                        data={data}
                        setData={setData}
                        nameLabel='INGRESOS/EGRESOS'
                        efectivoParams={efectivoParams}
                    />
            }
            <Routes>
                <Route index element={
                    <ListEfectivoHistorialTable
                        efectivoParams={efectivoParams}
                        dataCard={data}
                    />
                } />
                <Route
                    path={`/historial/:sub_efectivo_id/*`}
                    element={<ListEfectivoHistorialTable
                        efectivoParams={efectivoParams}
                        dataCard={data}
                    />}
                />
                <Route
                    path={`/cierres/:sub_efectivo_id/*`}
                    element={<EfectivoHistorial
                    />}
                />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </TabTableSection>
    )
}
