import { Bar } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js'
import { BarChartTemplate } from '../../../../components/chart/BarChartTemplate'
// import Draggable from 'react-draggable'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
)

// const getRandomColor = () => {
//     return '#' + Math.floor(Math.random() * 16777215).toString(16);
// }

export default function Bars({ data, aditionalCols, title, hoverTitle }) {
    // const backgroundColors = aditionalCols?.map(() => getRandomColor());
    var dataSets = aditionalCols?.map(() => 0) ?? []
    if (data != null) {
        for (const item of data) {
            let counter = 0
            for (const i of item.cantidades) {
                dataSets[counter] = dataSets[counter] + i
                counter++
            }
        }
    }

    var misoptions = {
        responsive: true,
        animation: true,
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            y: {
                min: 0,
                max: Math.max(...dataSets),
                ticks: { color: 'rgba(170,170,170)' },
                grid: {
                    color: 'rgba(170,170,170,.7)'
                },
            },
            x: {
                ticks: { color: 'rgba(150,150,150)' },
                grid: {
                    color: 'rgba(150,150,150,.7)'
                },
            }
        }
    }

    var midata = {
        labels: aditionalCols,
        datasets: [
            {
                label: hoverTitle,
                data: dataSets,
                backgroundColor: 'rgba(54, 162, 235, 1)'
            }
        ]
    }

    return (
        <BarChartTemplate
            dataSets={dataSets}
            title={title}
        >
            {/* <Draggable> */}
            <Bar data={midata} options={misoptions} />
            {/* </Draggable> */}
        </BarChartTemplate>
    )
}