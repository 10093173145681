import * as Yup from 'yup';

export const CreateVendedorValues = () => {
    return {
        initialValues: {
            // // codigo: '',
            // nombres: '',
            // apellido_paterno: '',
            // apellido_materno: '',
            // email: '',
            // ci: '',
            // estado: '',
            // // direccion: '',
            // password: '',
            userIds: [],
        },
        fieldsValidation: Yup.object().shape({
            // codigo: Yup.string()
            //     .min(4, 'Mínimo 4 caracteres!')
            //     .max(10, 'Máximo 10 caracteres!')
            //     .required('Campo requerido'),
            // nombres: Yup.string()
            //     .min(3, 'Mínimo 4 caracteres!')
            //     .max(50, 'Máximo 40 caracteres!')
            //     .required('Campo requerido'),
            // estado: Yup.string()
            //     .required('Campo requerido'),
            // password: Yup.string()
            //     .min(4, 'Mínimo 4 caracteres!')
            //     .required('Campo requerido'),
        }),
        fields: [
            {
                label: 'Clientes',
                name: 'userIds',
                type: 'multiSelectSearch',
                urlApi: `/clientes`,
                required: true,
                disabled: false,
                labelDescription: 'nombre',
                placeholder: 'Cliente...',
                autoFocus: false,
                // defaultValue: { value: '2', label: 'Clientes' }
            },
        ]
    }
}

export const AdicionarClientesValues = (vendedor) => {
    return {
        initialValues: {
            // // codigo: '',
            // nombres: '',
            // apellido_paterno: '',
            // apellido_materno: '',
            // email: '',
            // ci: '',
            // estado: '',
            // // direccion: '',
            // password: '',
            userIds: [],
        },
        fieldsValidation: Yup.object().shape({
            // codigo: Yup.string()
            //     .min(4, 'Mínimo 4 caracteres!')
            //     .max(10, 'Máximo 10 caracteres!')
            //     .required('Campo requerido'),
            // nombres: Yup.string()
            //     .min(3, 'Mínimo 4 caracteres!')
            //     .max(50, 'Máximo 40 caracteres!')
            //     .required('Campo requerido'),
            // estado: Yup.string()
            //     .required('Campo requerido'),
            // password: Yup.string()
            //     .min(4, 'Mínimo 4 caracteres!')
            //     .required('Campo requerido'),
        }),
        fields: [
            {
                label: 'Clientes',
                name: 'userIds',
                type: 'multiSelectSearch',
                urlApi: `/vendedor/${vendedor.id}/clientesExternos`,
                required: true,
                disabled: false,
                labelDescription: 'nombre',
                placeholder: 'Cliente...',
                autoFocus: false,
                // defaultValue: { value: '2', label: 'Clientes' }
            },
        ]
    }
}