import React, { useEffect } from 'react'
import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { MinimalActionButton } from '../../../../components/form/minimalActionButton'
import { setReloadMainTable } from '../../../../store/slices/auth/authSlice'
import { useSelector } from 'react-redux'
import { TabBoard } from '../../../../components/tab/TabBoard'
import Bars from './VentaPorVendedorProductoBarsCharts'
import Pies from './VentasCreditoPiesCharts'
import { DefaultParamsForRecall } from '../../../../utils/defaulStates'

export const AlmacenPorVendedor = ({ urlApi, title, subtitle }) => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        // updateModal, setUpdateModal,
        // deleteModal, setDeleteModal,
        // currentData, setCurrentData,
        // imports
        requestAuthPaginate,
        TableContainer,
        Paginator,
        // formatFilters,
        // FilterSelect,
        RangeDate,
        // Actions,
        TableSection,
        ActionSection,
        Section,

    } = useGeneralParams()
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)

    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: urlApi,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'nombre' }
        })
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), reloadSubTable]);

    // useEffect(() => {
    //     console.log(selecteds)
    // }, [selecteds]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate)]);

    const recall = () => {
        getDataPaginate()
        setSelecteds([])
        setSelectAllChecked(false)
        setIsChecked(false)
        dispatch(setReloadMainTable())
    }
    return (
        <TabBoard
            title={title}
            subtitle={subtitle}
        >
            <Section>
                <ActionSection>
                    <div className='flex gap-2 flex-wrap'>
                        <RangeDate
                            selectedDay={selectedDay}
                            setSelectedDay={setSelectedDay}
                            paginate={paginate}
                            setPaginate={setPaginate}
                        />
                        <MinimalActionButton
                            icon='repeat'
                            label=''
                            action={recall}
                        />
                    </div>
                </ActionSection>
                <TableSection
                    stateData={stateData}
                    paginator={Paginator}
                    data={data}
                >
                    <TableContainer
                        headers={[
                            {
                                label: 'Responsable',
                                columns: ['nombres:apellido_paterno:apellido_materno', 'roles']
                            },
                        ]}
                        data={data.data}
                        aditionalCols={data.aditionalCols}
                        aditionalColsRef='cantidades'
                        aditionalColsAmount={false}
                        checkList={false}
                        selecteds={selecteds}
                        setSelecteds={setSelecteds}
                        selectAllChecked={selectAllChecked}
                        setSelectAllChecked={setSelectAllChecked}
                        isChecked={isChecked}
                        setIsChecked={setIsChecked}
                        stateData={stateData}
                    />
                </TableSection>
            </Section>
            <Bars
                data={data.data}
                aditionalCols={data.aditionalCols}
                title='INGRESOS DE VACIOS Y EGRESO CON CONTENIDO'
                hoverTitle='Cantidad'
            />
            <Pies
                data={data.data}
                aditionalCols={data.aditionalCols}
                title='INGRESOS DE VACIOS'
                position={0}
                hoverTitle='Cantidad'
            />
            <Pies
                data={data.data}
                aditionalCols={data.aditionalCols}
                title='EGRESO CON CONTENIDO'
                position={1}
                hoverTitle='Cantidad'
            />
            <Pies
                data={data.data}
                aditionalCols={data.aditionalCols}
                title='TRASPASO CON CONTENIDO'
                hoverTitle='Cantidad'
                position={2}
            />
            <Pies
                data={data.data}
                aditionalCols={data.aditionalCols}
                title='TRASPASO DE VACIOS'
                hoverTitle='Cantidad'
                position={3}
            />
        </TabBoard>
    )
}