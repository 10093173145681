import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { requestDefaulAuth } from '../../../../../http/httpRequest'
import TableContainer from '../../../../../components/table/TableContainer'
import { UpdateValuesModal } from '../../../../../FormSchemes/GeneralFunctions'
import { ModalForm } from '../../../../../components/modals/ModalForm'
import { CreateValues, DeleteValues, UpdateValues } from '../../../../../FormSchemes/EstadoScheme'
import { ActionSection } from '../../../../../components/table/ActionSection'
import { Actions } from '../../../../../components/form/actions'

export const ListSubEstados = ({
    setModal,
    call,
    selectedData
}) => {
    const [Data, setData] = useState(null)
    const dispatch = useDispatch()
    const [CurrentData, setCurrentData] = useState(null)
    const [UpdateModal, setUpdateModal] = useState(false)
    const [DeleteModal, setDeleteModal] = useState(false)
    const [Reload, setReload] = useState(false)
    const [CreateModal, setCreateModal] = useState(false)

    const getAllData = async () => {
        await requestDefaulAuth(
            'get',
            `/estado/${selectedData?.id}/subs`,
            null,
            setData,
            dispatch
        )
    }
    useEffect(() => {
        getAllData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Reload])

    return (
        <div className='flex flex-col'>
            <ActionSection className='pb-1'>
                <Actions
                    buttons={[
                        {
                            icon: 'add',
                            label: 'Añadir elemento',
                            action: () => setCreateModal(true)
                        },
                    ]}
                />
            </ActionSection>
            <div className='overflow-x-scroll'>
                <TableContainer
                    headers={[
                        {
                            label: 'Nombre',
                            columns: ['nombre']
                        },
                        {
                            label: 'Descripción',
                            columns: ['descripcion']
                        },
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'edit',
                                    icon: 'fa-edit',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setUpdateModal),
                                },
                                {
                                    type: 'delete',
                                    icon: 'fa-trash',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                    reference: 'id'
                                }
                            ],
                            // stickyR: true
                        },
                    ]}
                    data={Data ?? []}
                    checkList={false}
                />
            </div>
            {
                (Data === null || Data === undefined || Data.length === 0) &&
                <div className='text-center text-gray-600 bg-gray-300 dark:bg-gray-600 dark:text-gray-400 py-1'>Lista vacia!</div>
            }
            {
                CreateModal &&
                <ModalForm
                    setModal={setCreateModal}
                    label='Crear estado'
                    dataValues={CreateValues(selectedData.id)}
                    urlApi={'/estado'}
                    method={'post'}
                    call={() => setReload(x => !x)}
                    buttonLabel='Registrar'
                />
            }
            {
                UpdateModal &&
                <ModalForm
                    setModal={setUpdateModal}
                    label='Editar estado'
                    dataValues={UpdateValues(CurrentData)}
                    urlApi={'/estado'}
                    method={'put'}
                    call={() => setReload(x => !x)}
                    buttonLabel='Editar'
                />
            }
            {
                DeleteModal &&
                <ModalForm
                    setModal={setDeleteModal}
                    label='Eliminar estado'
                    dataValues={DeleteValues(CurrentData)}
                    urlApi={`/estado/${CurrentData.id}`}
                    method={'delete'}
                    call={() => setReload(x => !x)}
                    buttonLabel='Eliminar'
                />
            }
        </div>
    )
}
