import React from 'react'
import { Link } from 'react-router-dom'

export const TabItem = ({ to, label, active, aditionalClassname='' }) => {
    return (
        <Link
            to={to}
            className={`group flex flex-nowrap justify-center items-center gap-2 w-auto ${aditionalClassname} py-4 px-5 rounded-t-xl active ${active ? 'bg-white dark:bg-gray-800 text-yellow-600 dark:text-yellow-500 font-semibold' : 'bg-transparent text-gray-600 dark:bg-transparent dark:text-gray-300 font-semibold hover:bg-gray-50/70 hover:dark:bg-gray-800/30'}`}
        >
            {
                active ?
                    <i className='fa pt-1 fa-angle-down group-hover:translate-y-1 transition-transform duration-200' />
                    :
                    <i className='fa-regular pt-1 fa-window-maximize dark:text-gray-300/50' />
            }
            {label}
        </Link>
    )
}