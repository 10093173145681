import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { requestAuth } from '../../http/httpRequest'
import { toastOn } from '../../store/slices/toast'
export const AlmacenTraspasosIngresosForm = ({
    field,
    values,
    setValues,
}) => {
    const dispatch = useDispatch()
    const [list, setList] = useState([])
    const [data, setData] = useState([])
    const [cantidadEgreso, setCantidadEgreso] = useState(0)
    const [cantidadRestante, setCantidadRestante] = useState(field.generalData?.cantidad || 0)

    const getData = async () => {
        await requestAuth(
            'get',
            field?.urlApi,
            null
        )
            .then((response) => {
                setList(response.data)
                setData(response.data.map(s => ({ 'almacen_producto_id': s.id + '', 'cantidad': 0 })))
            }
            )
            .catch(error => {
                dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las casillas.' }))
            })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addData = (value, name) => {
        const newData = data.map(item => {
            if (item.almacen_producto_id === name) {
                return { ...item, cantidad: parseFloat(value) };
            }
            return item;
        })
        setData(newData)
        const totalCantidad = newData.reduce((total, item) => total + parseFloat(item.cantidad || 0), 0);
        setCantidadEgreso(totalCantidad)
        setCantidadRestante(parseFloat(field.generalData?.cantidad) + totalCantidad)
        setValues('dataList', newData)
    }
    return (
        <div className='col-start-1 col-end-13 dark:text-gray-100 bg-gray-100 dark:bg-gray-700 border border-gray-200 dark:border-gray-500 rounded p-3' >
            <center className='font-semibold mb-2'>{field.title}</center>
            <div className='flex flex-col mb-2 text-sm bg-gray-200 dark:bg-gray-800 p-2 rounded font-semibold dark:text-gray-300 text-gray-700'>
                <div>
                    <span>Producto:</span> <span className='dark:text-blue-400 text-blue-600'>{field.generalData?.producto_nombre}, {field.generalData?.estado_nombre}</span>
                </div>
                <div>
                    <span>Cantidad actual:</span> <span className='dark:text-blue-400 text-blue-600'>{field.generalData?.cantidad}</span>
                </div>
                <div>
                    <span>Cantidad ingreso:</span> <span className='dark:text-blue-400 text-blue-600'>{cantidadEgreso}</span>
                </div>
                <div>
                    <span>Cantidad total:</span> <span className='text-green-500'>{cantidadRestante}</span> {cantidadRestante < 0 && <span className='text-red-500'>error al distribuir!</span>}
                </div>
            </div>
            <div
                className='grid grid-cols-1 sm:grid-cols-3 gap-2 max-h-80 overflow-auto'
            >
                {
                    list?.map((i, index) => (
                        <div
                            key={index}
                            className='dark:bg-gray-800 bg-gray-300 p-2 rounded-lg'
                        >
                            <div className='flex mb-2'>
                                <div className='mr-auto'>
                                    {
                                        i.encargado &&
                                        <label
                                            htmlFor={index}
                                            className='dark:text-gray-400 text-gray-600 font-semibold'
                                        >
                                            {i.codigo}{' - '}
                                            {i.encargado}
                                        </label>
                                    }
                                    {
                                        i.nombre_general != null && i.nombre_general !== '' &&
                                        <label
                                            htmlFor={index}
                                            className='dark:text-red-400 text-red-700 font-bold'
                                        >
                                            {i.nombre_general}
                                        </label>
                                    }
                                    {
                                        i.nombre_prov != null && i.nombre_prov !== '' &&
                                        <label
                                            htmlFor={index}
                                            className='dark:text-orange-400 text-orange-700 font-bold'
                                        >
                                            {i.nombre_prov}
                                        </label>
                                    }
                                </div>
                                <div
                                    className='dark:text-red-400 text-red-700 font-bold ml-auto'
                                >
                                    {i.cantidad}
                                </div>
                            </div>
                            <div>
                                <input
                                    id={index}
                                    type='number'
                                    name={i.id + ''}
                                    className={`shadow-sm outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-semibold disabled:opacity-55 disabled:text-gray-500 dark:disabled:text-gray-400 ${field.highlight}`}
                                    defaultValue={0}
                                    onChange={(e) => addData(e.target.value, e.target.name)}
                                />
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}