import React, { useEffect } from 'react'
import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { MinimalActionButton } from '../../../../components/form/minimalActionButton'
import { DefaultParamsForRecall } from '../../../../utils/defaulStates'
import { Callers } from '../../../../hooks/Callers'
import { useSelector } from 'react-redux'

export const ListChangeHistorialTable = () => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        // imports
        requestAuthPaginate,
        TableContainer,
        formatDateWithTime,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        RangeDate,
        TableSection,
        ActionSection,
        Section,
    } = useGeneralParams()
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)
    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/auth/changes/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'nombres' }
        })
    }

    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.tipo_accion, reloadSubTable]);

    // useEffect(() => {
    //     console.log(selecteds)
    // }, [selecteds]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.tipo_accion, reloadSubTable]);

    const reloadTable = () => {
        Callers(dispatch)
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
    }
    return (
        <Section>
            <ActionSection>
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    width='6/12'
                    options={[
                        {
                            label: 'Nombre',
                            value: 'nombres'
                        },
                        {
                            label: 'Código',
                            value: 'codigo'
                        }
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='tipo_accion'
                        options={[
                            {
                                label: 'Tipo de acción',
                                value: ''
                            },
                            {
                                label: 'Creado',
                                value: 'creado'
                            },
                            {
                                label: 'Modificado',
                                value: 'modificado'
                            },
                            {
                                label: 'Eliminado',
                                value: 'eliminado'
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Autor',
                            columns: ['codigo', 'nombres:apellido_paterno:apellido_materno'],
                            // icon: 'fa-solid fa-user-circle text-lg',
                            // skipLine: true,
                            className: 'flex items-center',
                            // stickyL: true
                        },
                        {
                            label: 'Descripción',
                            columns: ['descripcion'],
                        },
                        {
                            label: 'Tipo de acción',
                            columns: ['tipo_accion'],
                            tag: true
                        },
                        {
                            label: 'Fecha',
                            columns: ['fecha_accion'],
                            transform: true,
                            func: formatDateWithTime
                        },
                    ]}
                    data={data.data}
                    checkList={false}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
        </Section>
    )
}
