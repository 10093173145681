import React, { useState } from 'react'
import CardInfo from './CardInfo'
import { TabTableSection } from '../../../../../components/tab/TabTableSection';
import { ListDeudaEnvaseHistorialTable } from './ListDeudaEnvaseHistorialTable';

export const HistorialGarantia = ({ globalParams }) => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <TabTableSection>
            <CardInfo
                reload={reload}
                data={data}
                setData={setData}
                globalParams={globalParams}
            />
            <ListDeudaEnvaseHistorialTable
                reload={reload}
                setReload={setReload}
                dataCard={data}
                globalParams={globalParams}
            />
        </TabTableSection>
    )
}
