import React, { useEffect, useState } from 'react'
import { requestAuth, requestDefaulAuth } from '../../../../http/httpRequest'
import { Section } from '../../../../components/table/Section'
import { useDispatch } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { toastOn } from '../../../../store/slices/toast'
import * as Yup from 'yup';

export const ListDatosGeneralesForm = () => {
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const [vals, setVals] = useState(null)
    const [Load, setLoad] = useState(false)
    const getDatosGenerales = async () => {
        await requestDefaulAuth(
            'get',
            `/datosGenerales`,
            data,
            setData,
            dispatch
        )
        await requestDefaulAuth(
            'get',
            `/datosGeneralesInitialValues`,
            vals,
            setVals,
            dispatch
        )
    }
    useEffect(() => {
        getDatosGenerales()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Load])
    const validationSchema = Yup.object().shape({
        // nombre: Yup.string().required('El nombre es obligatorio'),
        // Otros campos y reglas de validación aquí
    })

    return (
        <Section>
            <div className='dark:text-gray-200 font-semibold text-lg'>
                PARAMETROS GENERALES DE LA EMPRESA
            </div>
            {
                vals &&
                <Formik
                    initialValues={vals}

                    validationSchema={validationSchema}

                    onSubmit={async (values, { resetForm }) => {
                        await requestAuth(
                            'put',
                            'datosGenerales',
                            values
                        )
                            .then((response) => {
                                setLoad(x => !x)
                                resetForm({ values })
                                dispatch(toastOn({ type: 'success', message: response.data.message ?? 'Editado!' }))
                            }
                            )
                            .catch(error => {
                                dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message ?? 'Error al enviar el formulario!' }))
                            })
                    }}
                >
                    {({ errors, isSubmitting, touched, values, submitForm, handleChange, setFieldValue, dirty, resetForm }) => (
                        <Form className='flex flex-col gap-6 mt-6'>
                            <div className='grid grid-cols-12 gap-6'>
                                {
                                    data?.map((item, index) => (
                                        <div
                                            className={`col-span-12`}
                                            key={index}
                                        >
                                            <label htmlFor={item.nombre} className='block text-md font-bold text-indigo-600 dark:text-indigo-500'>
                                                {item.label}
                                                {
                                                    item.type === 'link' &&
                                                    <a href={item.valor} target="_blank" rel="noopener noreferrer">
                                                        {' '} <i className='fa fa-arrow-up-right-from-square text-gray-500 hover:text-green-600' />
                                                    </a>
                                                }
                                            </label>
                                            {
                                                ['link', 'time', 'text', 'amount'].includes(item.type) ?
                                                    <Field
                                                        name={item.nombre}
                                                        id={item.nombre}
                                                        className={`shadow-none outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none text-gray-900 text-md rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-.5 dark:placeholder-gray-400 dark:text-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 font-semibold disabled:opacity-70 disabled:text-gray-500 dark:disabled:text-gray-50 bg-transparent`}
                                                        placeholder={item.label}
                                                        value={values[item.nombre]}
                                                        disabled={item.lock}
                                                    />
                                                    :
                                                    ['select'].includes(item.type) ?
                                                        <Field
                                                            id={item.nombre}
                                                            as='select'
                                                            name={item.nombre}
                                                            disabled={item.lock}
                                                            className='bg-transparent font-semibold outline-none border border-none text-gray-900 text-md rounded-lg focus:ring-transparent focus:border-blue-500 block w-auto p-0 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white'
                                                        >
                                                            <option value=''>...</option>
                                                            {
                                                                item?.options.split(',')?.map((option, index) => (
                                                                    <option value={option?.value} key={index}>{option}</option>
                                                                ))
                                                            }
                                                        </Field>
                                                        :
                                                        null
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                            <div className='flex gap-x-2'>
                                <button
                                    type='input'
                                    className='
                                    text-gray-100
                                    focus:ring-4
                                    focus:outline-none
                                    focus:ring-transparent
                                    font-bold
                                    rounded-md
                                    text-sm
                                    px-2.5 py-2
                                    text-center
                                    bg-red-600 hover:bg-red-500
                                    dark:bg-red-700 dark:hover:bg-red-600 disabled:bg-gray-600 dark:disabled:bg-gray-600 disabled:opacity-50
                                    dark:focus:ring-red-800'
                                    disabled={!dirty || isSubmitting}
                                >
                                    GUARDAR CAMBIOS
                                </button>
                                <button
                                    type='button'
                                    className='
                                    text-gray-100
                                    focus:ring-4
                                    focus:outline-none
                                    focus:ring-transparent
                                    font-bold
                                    rounded-md
                                    text-sm
                                    px-2.5 py-2
                                    text-center
                                    bg-gray-600 hover:bg-gray-500
                                    dark:bg-gray-700 dark:hover:bg-gray-600 disabled:bg-gray-600 dark:disabled:bg-gray-600 disabled:opacity-50
                                    dark:focus:ring-gray-800'
                                    onClick={() => resetForm()}
                                    disabled={!dirty || isSubmitting}
                                >
                                    CANCELAR
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            }
        </Section>
    )
}