import React, { useEffect } from 'react'
import { MinimalActionButton } from '../../../../../components/form/minimalActionButton'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { DeleteValues } from '../../../../../FormSchemes/CuadernoHistorialScheme'
import { formatDateWithTime } from '../../../../../utils/dateFormat'
import { DefaultParamsForRecall } from '../../../../../utils/defaulStates'
import { Callers } from '../../../../../hooks/Callers'
import { useSelector } from 'react-redux'

export const ListCuadernoCobranzaHistorialTable = ({
    params,
    setSelectAllChecked,
    setIsChecked,
    selecteds,
    setSelecteds,
    selectAllChecked,
    isChecked,
}) => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        stateData, setStateData,
        currentData, setCurrentData,
        deleteModal, setDeleteModal,
        requestAuthPaginate,
        TableContainer,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        RangeDate,
        TableSection,
        ActionSection,
        ModalForm,
        UpdateValuesModal,
    } = useGeneralParams('nombre')
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)
    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/cuaderno/${params.cuaderno_id}/cobros/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'nombre' }
        })
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, params.efectivo_id, reloadSubTable]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, reloadSubTable]);

    const reloadTable = () => {
        Callers(dispatch)
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
    }

    return (
        <>
            <ActionSection>
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Nombre',
                            value: 'nombre'
                        },
                        {
                            label: 'Estado',
                            value: 'estado'
                        }
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: 'Estado',
                                value: ''
                            },
                            {
                                label: 'Activo',
                                value: 'activo'
                            },
                            {
                                label: 'Inactivo',
                                value: 'inactivo'
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Deudor',
                            columns: ['deudorCodigo', 'deudorDetalles']
                        },
                        {
                            label: 'Producto',
                            columns: ['producto_nombre']
                        },
                        {
                            label: 'Monto Bs.',
                            columns: ['monto'],
                            numberValue: true,
                        },
                        {
                            label: 'Método de pago',
                            columns: ['metodo_pago'],
                            // tag: true
                        },
                        {
                            label: 'Fecha de deuda',
                            columns: ['fecha_deuda'],
                            transform: true,
                            func: formatDateWithTime
                        },
                        {
                            label: 'Estado',
                            columns: ['estado'],
                            boolean: true,
                            booleanOptions: ['Completado', 'Pendiente'],
                            booleanIconTrue: 'fa-check',
                            booleanIconFalse: 'fa-circle'
                        },
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'delete',
                                    icon: 'fa-trash',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                    reference: 'id'
                                },
                            ],
                        },
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
                {
                    deleteModal &&
                    <ModalForm
                        setModal={setDeleteModal}
                        label='Eliminar cobro'
                        dataValues={DeleteValues(currentData)}
                        urlApi={`/deudaHistorial/${currentData.id}`}
                        method={'delete'}
                        call={reloadTable}
                        buttonLabel='Eliminar'
                    />
                }
            </TableSection>
        </>
    )
}