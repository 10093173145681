import React from 'react'
import { DescriptionTargetInfo, ItemCardTargetInfo, LogoLabelTargetInfo, MainTargetInfo, TitleTargetInfo } from '../../../../../components/card/MainTargetInfo';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CardInfo = ({ reload, data, setData }) => {
    const params = useParams()
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)
    return (
        <MainTargetInfo
            extraClassName='grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:md:grid-cols-4 gap-1'
            urlApi={`/estado/${params.estado_id}`}
            setData={setData}
            dataRef={[params.estado_id, reloadSubTable]}
        >
            <ItemCardTargetInfo
                logoLabel={<LogoLabelTargetInfo logo={<i className='fa fa-list' />} logolg='text-5xl' />}
                aditionalClassName='col-span-4'
            >
                <TitleTargetInfo label='' data={data?.nombre} />
                <DescriptionTargetInfo label='' data={data?.descripcion} />
            </ItemCardTargetInfo>
        </MainTargetInfo>
    )
}

export default CardInfo