import React, { useEffect, useState } from 'react'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { MinimalActionButton } from '../../../../../components/form/minimalActionButton'
import { formatDateWithTime } from '../../../../../utils/dateFormat'
import { DefaultParamsForRecall } from '../../../../../utils/defaulStates'
import { Actions } from '../../../../../components/form/actions'
import { ModalForm } from '../../../../../components/modals/ModalForm'
import { RegistroDeudaHistorialValues } from '../../../../../FormSchemes/DeudaHistorialScheme'
import { UpdateValuesModal } from '../../../../../FormSchemes/GeneralFunctions'
import { DeleteValues } from '../../../../../FormSchemes/RolScheme'
import { useSelector } from 'react-redux'
import { Callers } from '../../../../../hooks/Callers'

export const ListDeudaHistorialTable = ({
    reload,
    setReload,
}) => {
    const {
        dispatch,
        params,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        requestAuthPaginate,
        TableContainer,
        Paginator,
        Searcher,
        TableSection,
        ActionSection,
        Section,
    } = useGeneralParams()
    const [RegistrarCobroModal, setRegistrarCobroModal] = useState(false)
    const [DeleteModal, setDeleteModal] = useState(false)
    const [CurrentData, setCurrentData] = useState(false)
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)
    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/deudas/${params.deuda_id}/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'producto' }
        })
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), params.deuda_id, reloadSubTable]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), params.deuda_id, reloadSubTable]);

    const reloadTable = () => {
        Callers(dispatch)
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
    }

    return (
        <Section>
            <ActionSection>
                <Actions
                    buttons={[
                        {
                            icon: 'plus',
                            label: `Registrar cobro`,
                            action: () => setRegistrarCobroModal(true),
                            className: 'dark:bg-blue-700 bg-blue-500 text-white'
                        },
                    ]}
                />
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    // options={[
                    //     {
                    //         label: 'Producto',
                    //         value: 'producto'
                    //     },
                    // ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>

                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[

                        {
                            label: 'Cuaderno',
                            columns: ['cuaderno']
                        },
                        {
                            label: 'Monto',
                            columns: ['monto'],
                            numberValue: true,
                        },
                        {
                            label: 'Saldo',
                            columns: ['saldo'],
                            numberValue: true,
                        },
                        {
                            label: 'Metodo de pago',
                            columns: ['metodo_pago']
                        }, {
                            label: 'Obs',
                            columns: ['obs']
                        },
                        {
                            label: 'Fecha de pago',
                            columns: ['createdAt'],
                            transform: true,
                            func: formatDateWithTime

                        },
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'delete',
                                    icon: 'fa-trash',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                    reference: 'id'
                                },
                            ],
                        },
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
                {
                    RegistrarCobroModal &&
                    <ModalForm
                        setModal={setRegistrarCobroModal}
                        label={`Registrar cobro`}
                        dataValues={RegistroDeudaHistorialValues(params.deuda_id)}
                        urlApi={`/deudaHistorialDirecto`}
                        method={'post'}
                        call={() => {
                            reloadTable()
                            // dispatch(setReloadSubTable())
                            // dispatch(setReloadMainTable())
                        }}
                        buttonLabel='Registrar'
                    />
                }
                {
                    DeleteModal &&
                    <ModalForm
                        setModal={setDeleteModal}
                        label='Eliminar deuda'
                        dataValues={DeleteValues()}
                        urlApi={`/deudaHistorialDirecto/${CurrentData.id}`}
                        method={'delete'}
                        call={reloadTable}
                        buttonLabel='Eliminar'
                    />
                }
            </TableSection>
        </Section>
    )
}