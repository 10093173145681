import { useEffect } from 'react';
import { AppRouter } from './router/AppRouter';
import { requestAuth } from './http/httpRequest';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginOn, loginOut, removeToken, saveUserDetail } from './store/slices/auth';
import { ToastContainer } from 'react-toastify';
import { dropdownOff } from './store/slices/dropdown';
import { ScrollButton } from './components/scroll/ScrollButton';
// import { Info } from './components/info/Info';

function App() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const dropdown = useSelector(state => state.dropdown.status)
  const element = document.documentElement

  const auth = async () => {
    if (localStorage?.getItem('token')) {

      await requestAuth(
        'get',
        `/user/Detail`,
        null
      )
        .then((response) => {
          dispatch(loginOn())
          navigate(window.localStorage.setItem('id', response?.data?.id))
          dispatch(saveUserDetail(response.data))
        }
        )
        .catch(() => {
          dispatch(loginOut())
          dispatch(removeToken())
          navigate('/login')
          dispatch(saveUserDetail(null))
          localStorage.removeItem('token')
        })
    }
  }

  useEffect(() => {
    auth()

    if (localStorage?.getItem('theme') !== 'dark') {
      element.classList.remove('dark')
      localStorage.removeItem('theme')
    }
    else {
      element.classList.add('dark')
      localStorage.setItem('theme', 'dark')
    }
    switch (localStorage.getItem('theme')) {
      case 'dark':
        element.classList.add('dark')
        localStorage.setItem('theme', 'dark')
        break;
      case 'light':
        element.classList.remove('dark')
        localStorage.removeItem('theme')
        break;
      default:
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      onClick={() => dropdown && dispatch(dropdownOff())}
    >
      <AppRouter />
      <ToastContainer />
      <ScrollButton />
      {/* <Info /> */}
    </div>
  );
}

export default App;