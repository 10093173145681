import React, { useEffect, useState } from 'react'
import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { CerrarCuadernosValues, CreateValues, DeleteValues } from '../../../../FormSchemes/CuadernoScheme'
import { formatDateWithTime } from '../../../../utils/dateFormat'
import { MinimalActionButton } from '../../../../components/form/minimalActionButton'
import { useSelector } from 'react-redux'
import { ModalPdf } from '../../../../components/modals/ModalPdf'
import RegistroDiarioPdf from './RegistroDiarioPdf'
import { DefaultParamsForRecall } from '../../../../utils/defaulStates'
import { Callers } from '../../../../hooks/Callers'

export const ListCuadernoTable = ({
    mainReloadTable,
    setMainReloadTable
}) => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        createModal, setCreateModal,
        deleteModal, setDeleteModal,
        currentData, setCurrentData,
        // updateModal, setUpdateModal,
        // deleteModal, setDeleteModal,
        // currentData, setCurrentData,
        // imports
        requestAuthPaginate,
        TableContainer,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        RangeDate,
        Actions,
        TableSection,
        ActionSection,
        Section,
        ModalForm,
        UpdateValuesModal,
        redirect

    } = useGeneralParams()
    const [cerrarCuadernosModal, setCerrarCuadernosModal] = useState(false)
    const [registroDiarioModal, setRegistroDiarioModal] = useState(false)
    const reloadMainTable = useSelector(state => state.login.reloadMainTable)

    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/cuadernos/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'nombre', rolname: '' }
        })
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.abierto, reloadMainTable]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.abierto]);

    const reloadTable = () => {
        Callers(dispatch)
        setSelecteds([])
        setSelectAllChecked(false)
        setIsChecked(false)
    }
    return (
        <Section>
            <ActionSection>
                <Actions
                    buttons={[
                        {
                            icon: 'add',
                            label: 'Aperturar cuadernos',
                            action: () => setCreateModal(true)
                        },
                        {
                            icon: 'add',
                            label: 'Cerrar cuadernos seleccionados',
                            action: () => setCerrarCuadernosModal(true),
                            disabled: selecteds.length <= 0 ? true : false
                        },
                        {
                            icon: 'add',
                            label: 'Registro Diario',
                            action: () => setRegistroDiarioModal(true),
                            disabled: selecteds.length <= 0 ? true : false
                        },
                    ]}
                />
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Nombre',
                            value: 'nombre'
                        },
                        {
                            label: 'Código',
                            value: 'codigo'
                        }
                    ]}
                    reloadTable={() => {
                        reloadTable()
                    }}
                />
                <div className='flex gap-2 flex-wrap'>
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='abierto'
                        options={[
                            {
                                label: 'Abiertos y cerrados',
                                value: ''
                            },
                            {
                                label: 'Abiertos',
                                value: 'true'
                            },
                            {
                                label: 'Cerrados',
                                value: 'false'
                            }
                        ]}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='revisado'
                        options={[
                            {
                                label: 'Revisados y pendientes',
                                value: ''
                            },
                            {
                                label: 'Revisados',
                                value: 'true'
                            },
                            {
                                label: 'Pendientes',
                                value: 'false'
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Número',
                            columns: ['codigo'],
                            icon: 'fa-regular fa-clipboard text-green-600 text-xl',
                            className: 'flex items-center',
                            tag: true
                            // stickyL: true
                        },
                        {
                            label: 'Responsable',
                            columns: ['nombre_completo', 'roles']
                        },
                        {
                            label: 'Efectivo Bs.',
                            columns: ['efectivo_total'],
                            numberValue: true,
                        },
                        {
                            label: 'Garantía Bs.',
                            columns: ['garantias_total'],
                            numberValue: true,
                        },
                        {
                            label: 'Efectivo neto Bs.',
                            columns: ['efectivo_total_neto'],
                            numberValue: true,
                        },
                        {
                            label: 'Abierto / Cerrado',
                            columns: ['abierto'],
                            boolean: true,
                            booleanOptions: ['Abierto', 'Cerrado']
                        },
                        {
                            label: 'Revisado / Pendiente',
                            columns: ['revisado'],
                            boolean: true,
                            booleanOptions: ['Revisado', 'Pendiente']
                        },
                        {
                            label: 'fecha de registro',
                            columns: ['createdAt'],
                            transform: true,
                            func: formatDateWithTime
                        },
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'cyan',
                                    icon: 'fa-share-from-square',
                                    action: (data) => {
                                        redirect(`historial/${data.id}/ventas`)
                                        setMainReloadTable(!mainReloadTable)
                                    },
                                    reference: 'id',
                                    tooltipText: 'Detalles de cuaderno',
                                    redirect: true
                                },
                                // {
                                //     type: 'yellow',
                                //     icon: 'fa-truck-moving',
                                //     action: (data) => redirect(`almacen/${data.id}`),
                                //     reference: 'id'
                                // },
                                {
                                    type: 'red',
                                    icon: 'fa-trash',
                                    // action: (data) => redirect(`almacen/${data.id}`),
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                    tooltipText: 'Eliminar cuaderno',
                                    reference: 'id'
                                },
                                // {
                                //     type: 'delete',
                                //     icon: 'fa-trash',
                                //     action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                //     reference: 'id',
                                //     validate: { value: 'abierto', validator: true }
                                // }
                            ],
                            // stickyR: true
                        },
                        // {
                        //     label: 'Acciones',
                        //     actions: [
                        //         {
                        //             type: 'edit',
                        //             icon: 'fa-edit',
                        //             action: (data) => UpdateValuesModal(data, setCurrentData, setUpdateModal),
                        //         },
                        //         {
                        //             type: 'delete',
                        //             icon: 'fa-trash',
                        //             action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                        //             reference: 'id'
                        //         }
                        //     ],
                        //     // stickyR: true
                        // },
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {
                createModal &&
                <ModalForm
                    setModal={setCreateModal}
                    label='Aperturar cuaderno'
                    // dataValues={CreateValues(globalParams.rolname === 'vendedores' ? '/users/permitirCuaderno/vendedores' : '/users/permitirCuaderno/oficina')}
                    dataValues={CreateValues('/users/permitirCuaderno/all')}
                    urlApi={'/cuadernos'}
                    method={'post'}
                    call={reloadTable}
                    buttonLabel='Registrar'
                />
            }
            {
                cerrarCuadernosModal &&
                <ModalForm
                    setModal={setCerrarCuadernosModal}
                    label='Cerrar cuadernos'
                    dataValues={CerrarCuadernosValues(selecteds)}
                    urlApi={`/cuadernos/close`}
                    method={'put'}
                    call={reloadTable}
                    buttonLabel='Cerrar'
                />
            }

            {
                registroDiarioModal &&
                <ModalPdf
                    setModal={setRegistroDiarioModal}
                >
                    <RegistroDiarioPdf
                        cuadernosids={selecteds}
                    />
                </ModalPdf>
            }

            {/* {
                updateModal &&
                <ModalForm
                    setModal={setUpdateModal}
                    label='Editar cuenta bancaria'
                    dataValues={UpdateValues(currentData)}
                    urlApi={'/cuenta_bancaria'}
                    method={'put'}
                    call={recall}
                    buttonLabel='Editar'
                />
            }
            
            {
                deleteModal &&
                <ModalForm
                    setModal={setDeleteModal}
                    label='Eliminar cuenta bancaria'
                    dataValues={DeleteValues(currentData)}
                    urlApi={`/cuenta_bancaria/${currentData.id}`}
                    method={'delete'}
                    call={recall}
                    buttonLabel='Eliminar'
                />
            } */}
            {
                deleteModal &&
                <ModalForm
                    setModal={setDeleteModal}
                    label='Eliminar cuaderno'
                    dataValues={DeleteValues(currentData)}
                    urlApi={`/cuaderno/${currentData.id}`}
                    method={'delete'}
                    call={reloadTable}
                    buttonLabel='Eliminar'
                />
            }
        </Section>
    )
}