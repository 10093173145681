import React from 'react'
import { useParams } from 'react-router-dom';
import { DescriptionTargetInfo, ItemCardTargetInfo, LogoTargetInfo, MainTargetInfo, TitleTargetInfo } from '../../../../../components/card/MainTargetInfo';
import { useSelector } from 'react-redux';

const CardInfo = ({ reload, data, setData }) => {
    const params = useParams()
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)
    return (
        <MainTargetInfo
            extraClassName='grid-cols-1 gap-1'
            urlApi={`/producto/${params.producto_id}`}
            setData={setData}
            dataRef={[params.producto_id, reloadSubTable]}
        >
            <ItemCardTargetInfo
                logo={<LogoTargetInfo logo='fa-store' />}
            >
                <TitleTargetInfo label='' data={data?.nombre} />
                <DescriptionTargetInfo label='' data={data?.descripcion} />
                <DescriptionTargetInfo label='' data={data?.retornable ? 'Retornable' : 'No retornable'} />
            </ItemCardTargetInfo>
        </MainTargetInfo>
    )
}

export default CardInfo