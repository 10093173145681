import React, { useState } from 'react'
import CardInfo from './CardInfo'
import { ListDeudaHistorialTable } from './ListDeudaHistorialTable';
import { TabTableSection } from '../../../../../components/tab/TabTableSection';

export const HistorialDeudas = () => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <TabTableSection>
            <CardInfo
                reload={reload}
                data={data}
                setData={setData}
            />
            <ListDeudaHistorialTable
                reload={reload}
                setReload={setReload}
                dataCard={data}
            />
        </TabTableSection>
    )
}
