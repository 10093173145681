import React from 'react'
import { useParams } from 'react-router-dom';
import { DescriptionTargetInfo, ItemCardTargetInfo, LogoTargetInfo, MainTargetInfo, SubTitleOneLineTargetInfo } from '../../../../../components/card/MainTargetInfo';
import { formatDate } from '../../../../../utils/dateFormat';
import { useSelector } from 'react-redux';

const CardInfoAlmacenProducto = ({ data, setData }) => {
    const params = useParams()
    const reloadMinorTable = useSelector(state => state.login.reloadMinorTable)
    return (
        <MainTargetInfo
            extraClassName='grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1'
            urlApi={`/almacenProducto/${params.almacen_producto_id}`}
            setData={setData}
            dataRef={[params.almacen_producto_id, reloadMinorTable]}
        >
            <ItemCardTargetInfo
                logo={<LogoTargetInfo logo='fa-boxes-packing' />}
                // logoLabel={<LogoLabelTargetInfo logo={<NoteIconSvg />} />}
                aditionalClassName='col-span-4'
            >
                <SubTitleOneLineTargetInfo label='' data1={data?.producto_nombre} data2={data?.estado_nombre} />
                {/* <TitleTargetInfo label='' data={data?.producto_nombre} />
                <SubTitleTargetInfo label={null} data={data?.estado_nombre} /> */}
                <DescriptionTargetInfo label={data?.estado_descripcion} data={null} />
                <DescriptionTargetInfo label='' data={formatDate(data?.createdAt)} />
                <DescriptionTargetInfo label='Cantidad existente:' data={data?.cantidad} />
            </ItemCardTargetInfo>
            {/* <ItemCardTargetInfo>
                <NumberTargetInfo label='Total ingreso' data={data?.cantidad_ingreso} />
                <NumberTargetInfo label='Total egreso' data={data?.cantidad_egreso} />
                <NumberTargetInfo label='Total' data={data?.cantidad} />
            </ItemCardTargetInfo> */}
            {/* <NumberCalculatorsTargetInfo>
                <NumberGridTargetInfo label='Existente' data={data?.cantidad} borderBotton={false} /> */}
            {/* <NumberGridTargetInfo label='Deudas' data={data?.cantidad_deuda} />
                <NumberGridTargetInfo label='Garantías' data={data?.cantidad_garantia} /> */}
            {/* <NumberGridTargetInfo label='Total' data={data?.cantidad_total} /> */}
            {/* <EmptyGridTargetInfo label='.' data={''} borderBotton={false} />
                <EmptyGridTargetInfo label='.' data={''} borderBotton={false} />
                <EmptyGridTargetInfo label='.' data={''} borderBotton={false} />
            </NumberCalculatorsTargetInfo> */}
            {/* <NumberCalculatorsTargetInfo>
                <NumberGridTargetInfo label='Venta Bs.' data={data?.monto_venta} />
                <NumberGridTargetInfo label='Deuda Bs.' data={data?.monto_deuda} />
                <NumberGridTargetInfo label='Garantía Bs.' data={data?.monto_garantia} />
                <NumberGridTargetInfo label='Efectivo Bs.' data={data?.monto_efectivo} borderBotton={false} />

            </NumberCalculatorsTargetInfo> */}
        </MainTargetInfo>
    )
}

export default CardInfoAlmacenProducto