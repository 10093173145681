import * as Yup from 'yup';
import { DecimalValidation, DecimalValidationMoreThanZero, ToTwoDecimal } from '../utils/dataValidations';

export const RegistrarGastoValues = (efectivo_id, ingreso) => {
    return {
        initialValues: {
            efectivo_id: efectivo_id,
            proveedor: '',
            cantidad: 0,
            almacen_id: '',
            almacen_producto_id: 0,
            monto_total: 0,
            billetes: 0,
            monedas: 0,
            numero_boleto: '',
            gasto: '',
            tipo_id: '',
            empleado_id: '',
            transportista_id: '',
            responsable_vehiculo_id: '',
            responsable_punto_venta_id: '',
            obs: ''
        },
        fieldsValidation: Yup.object().shape({
            tipo_id: Yup.string()
                .required('campo requerido'),
            gasto: Yup.string()
                .required('campo requerido')
        }),
        fields: [
            {
                label: 'Gasto',
                name: 'gasto',
                type: 'selectSearch',
                urlApi: 'nombreEstado/Gastos/subEstados?omitirGastos=true',
                required: true,
                labelDescription: 'nombre',
                labelId: 'nombre',
                placeholder: 'Seleccione...',
                autoFocus: false
            },
            {
                label: 'Tipo de gasto',
                name: 'tipo_id',
                type: 'subSelectDinamicSearch',
                param: 'gasto',
                urlApi: 'nombreEstado/{param}/subEstados',
                required: true,
                labelDescription: 'nombre',
                labelId: 'id',
                placeholder: 'Gasto...',
                autoFocus: false,
                // showValidator: true
            },
            {
                label: 'Empleado',
                name: 'empleado_id',
                type: 'selectSearch',
                urlApi: '/users/empleados',
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'Empleado...',
                autoFocus: false,
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    let list = [
                        'Obligaciones y pagos de personal (Empleados)'
                    ]
                    if (list.includes(values.gasto)) {
                        setAditionalClass('')
                        setValues('empleado_id', '')
                    }
                    else {
                        setAditionalClass('hidden')
                        setValues('empleado_id', '')
                    }
                },
                fieldDependency: 'gasto',
                highlight: 'bg-gray-600 dark:bg-gray-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Transportista',
                name: 'transportista_id',
                type: 'selectSearch',
                urlApi: '/users/rol/transportista',
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'Transportista...',
                autoFocus: false,
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    let list = [
                        'Obligaciones y pagos de personal (Transportistas)'
                    ]
                    if (list.includes(values.gasto)) {
                        setAditionalClass('')
                        setValues('transportista_id', '')
                    }
                    else {
                        setAditionalClass('hidden')
                        setValues('transportista_id', '')
                    }
                },
                fieldDependency: 'gasto',
                highlight: 'bg-gray-600 dark:bg-gray-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Responsable de vehiculo',
                name: 'responsable_vehiculo_id',
                type: 'selectSearch',
                urlApi: '/users/responsableVehiculos',
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'responsable_vehiculo...',
                autoFocus: false,
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    let list = [
                        'Gastos de mantenimiento vehicular'
                    ]
                    if (list.includes(values.gasto)) {
                        setAditionalClass('')
                        setValues('responsable_vehiculo_id', '')
                    }
                    else {
                        setAditionalClass('hidden')
                        setValues('responsable_vehiculo_id', '')
                    }
                },
                fieldDependency: 'gasto',
                highlight: 'bg-gray-600 dark:bg-gray-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Responsable de punto de venta',
                name: 'responsable_punto_venta_id',
                type: 'selectSearch',
                urlApi: '/users/rol/punto-de-venta',
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'Responsable...',
                autoFocus: false,
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    let list = [
                        'Pagos de servicios técnicos puntos de venta'
                    ]
                    if (list.includes(values.gasto)) {
                        setAditionalClass('')
                        setValues('responsable_punto_venta_id', '')
                    }
                    else {
                        setAditionalClass('hidden')
                        setValues('responsable_punto_venta_id', '')
                    }
                },
                fieldDependency: 'gasto',
                highlight: 'bg-gray-600 dark:bg-gray-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                type: 'divider',
            },
            {
                label: 'Monto',
                name: 'monto_total',
                type: 'groupnumber',
                subData: 'Bs.',
                required: true,
                disabled: true,
                highlight: 'bg-blue-600 dark:bg-blue-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Billetes',
                name: 'billetes',
                type: 'groupnumberCalculator',
                subData: 'Sus.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const billetes = e.target.valueAsNumber || 0
                    const monedas = values.monedas || 0
                    const monto_total = ToTwoDecimal(billetes + monedas)
                    setValues('monto_total', monto_total)
                },
            },
            {
                label: 'Monedas',
                name: 'monedas',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const monedas = e.target.valueAsNumber || 0
                    const billetes = values.billetes || 0
                    const monto_total = ToTwoDecimal(billetes + monedas)
                    setValues('monto_total', monto_total)
                },
            },
            {
                label: 'Numero de Boleta',
                name: 'numero_boleto',
                type: 'text',
                required: false,
                placeholder: '####',
                autoFocus: false
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ],
    }
}
export const CompraDeProductoValues = (efectivo_id) => {
    return {
        initialValues: {
            efectivo_id: efectivo_id,
            tipo: 'Compra de producto',
            proveedor: '',
            cantidad: 0,
            precio_unitario: 0,
            almacen_id: '',
            almacen_producto_id: 0,
            monto_total: 0,
            billetes: 0,
            monedas: 0,
            dolares_en_bs: 0,
            numero_boleto: '',
            obs: ''

        },
        fieldsValidation: Yup.object().shape({
            monto_total: DecimalValidationMoreThanZero,
            billetes: DecimalValidation,
            monedas: DecimalValidation,
            dolares_en_bs: DecimalValidation
        }),
        fields: [
            {
                label: 'Proveedor',
                name: 'proveedor_id',
                type: 'selectSearch',
                urlApi: '/proveedores',
                required: true,
                labelDescription: 'nombre',
                placeholder: 'Proveedor...',
                autoFocus: false,

            },
            {
                label: 'Seleccionar un almacén',
                name: 'almacen_id',
                type: 'selectSearch',
                urlApi: '/almacenes/general',
                required: true,
                labelDescription: 'nombre',
                placeholder: 'Almacén...',
                autoFocus: false,
                // defaultValue: { value: '2', label: 'Almacén' }
            },
            {
                label: 'Seleccionar producto',
                name: 'almacen_producto_id',
                type: 'subSelectSearch',
                param: 'almacen_id',
                urlApi: '/almacen/{param}/productos',
                required: true,
                labelDescription: 'producto_nombre',
                placeholder: 'Producto...',
                autoFocus: false
            },
            {
                label: 'Cantidad',
                name: 'cantidad',
                type: 'numberCalculator',
                subData: null,
                required: true,
                locked: false,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad = e.target.valueAsNumber || 0;
                    const precio_unitario = values.precio_unitario || 0;
                    const monto_total = ToTwoDecimal(precio_unitario * cantidad);
                    setValues('monto_total', monto_total)
                }
            },
            {
                label: 'Precio unitario',
                name: 'precio_unitario',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const precio_unitario = e.target.valueAsNumber || 0;
                    const cantidad = values.cantidad || 0;
                    const monto_total = ToTwoDecimal(precio_unitario * cantidad);
                    setValues('monto_total', monto_total)
                }
            },
            {
                label: 'Total compra',
                name: 'monto_total',
                type: 'groupnumber',
                subData: 'Bs.',
                required: true,
                disabled: true,
                highlight: 'bg-blue-600 dark:bg-blue-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Billetes',
                name: 'billetes',
                type: 'groupnumberCalculator',
                subData: 'Sus.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    // const billetes = e.target.valueAsNumber || 0
                    // const monedas = values.monedas || 0
                    // const dolares_en_bs_total = values.dolares_en_bs_total || 0
                    // const efectivo_total_neto = values.efectivo_total_neto || 0
                    // const efectivo_entregado = ToTwoDecimal(billetes + monedas + dolares_en_bs_total)
                    // const sobrante_o_faltante = ToTwoDecimal(efectivo_total_neto - efectivo_entregado)
                    // if (sobrante_o_faltante >= 0) {
                    //     setValues('efectivo_faltante', sobrante_o_faltante)
                    //     setValues('efectivo_sobrante', 0)
                    // }
                    // else {
                    //     setValues('efectivo_sobrante', sobrante_o_faltante * -1)
                    //     setValues('efectivo_faltante', 0)
                    // }
                    // setValues('efectivo_entregado', efectivo_entregado)
                },
            },
            {
                label: 'Monedas',
                name: 'monedas',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    // const monedas = e.target.valueAsNumber || 0
                    // const billetes = values.billetes || 0
                    // const dolares_en_bs_total = values.dolares_en_bs_total || 0
                    // const efectivo_total_neto = values.efectivo_total_neto || 0
                    // const efectivo_entregado = ToTwoDecimal(billetes + monedas + dolares_en_bs_total)
                    // const sobrante_o_faltante = ToTwoDecimal(efectivo_total_neto - efectivo_entregado)
                    // if (sobrante_o_faltante >= 0) {
                    //     setValues('efectivo_faltante', sobrante_o_faltante)
                    //     setValues('efectivo_sobrante', 0)
                    // }
                    // else {
                    //     setValues('efectivo_sobrante', sobrante_o_faltante * -1)
                    //     setValues('efectivo_faltante', 0)
                    // }
                    // setValues('efectivo_entregado', efectivo_entregado)
                },
            },
            {
                label: 'Numero de Boleta',
                name: 'numero_boleto',
                type: 'text',
                required: false,
                placeholder: '####',
                autoFocus: false
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ],
    }
}

export const CompraDeEnvaseValues = (efectivo_id) => {
    return {
        initialValues: {
            efectivo_id: efectivo_id,
            tipo: 'Compra de envase',
            proveedor: '',
            cantidad: 0,
            precio_unitario: 0,
            almacen_id: '',
            almacen_producto_id: 0,
            monto_total: 0,
            billetes: 0,
            monedas: 0,
            dolares_en_bs: 0,
            numero_boleto: '',
            obs: ''

        },
        fieldsValidation: Yup.object().shape({
            monto_total: DecimalValidationMoreThanZero,
            billetes: DecimalValidation,
            monedas: DecimalValidation,
            dolares_en_bs: DecimalValidation
        }),
        fields: [
            {
                label: 'Proveedor',
                name: 'proveedor_id',
                type: 'selectSearch',
                urlApi: '/proveedores',
                required: true,
                labelDescription: 'nombre',
                placeholder: 'Proveedor...',
                autoFocus: false,

            },
            {
                label: 'Seleccionar un almacén',
                name: 'almacen_id',
                type: 'selectSearch',
                urlApi: '/almacenes/general',
                required: true,
                labelDescription: 'nombre',
                placeholder: 'Almacén...',
                autoFocus: false,
                // defaultValue: { value: '2', label: 'Almacén' }
            },
            {
                label: 'Seleccionar envase',
                name: 'almacen_producto_id',
                type: 'subSelectSearch',
                param: 'almacen_id',
                urlApi: '/almacen/{param}/envases',
                required: true,
                labelDescription: 'producto_nombre',
                placeholder: 'Producto...',
                autoFocus: false
            },
            {
                label: 'Cantidad',
                name: 'cantidad',
                type: 'numberCalculator',
                subData: null,
                required: true,
                locked: false,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad = e.target.valueAsNumber || 0;
                    const precio_unitario = values.precio_unitario || 0;
                    const monto_total = ToTwoDecimal(precio_unitario * cantidad);
                    setValues('monto_total', monto_total)
                }
            },
            {
                label: 'Precio unitario',
                name: 'precio_unitario',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const precio_unitario = e.target.valueAsNumber || 0;
                    const cantidad = values.cantidad || 0;
                    const monto_total = ToTwoDecimal(precio_unitario * cantidad);
                    setValues('monto_total', monto_total)
                }
            },
            {
                label: 'Total compra',
                name: 'monto_total',
                type: 'groupnumber',
                subData: 'Bs.',
                required: true,
                disabled: true,
                highlight: 'bg-blue-600 dark:bg-blue-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Billetes',
                name: 'billetes',
                type: 'groupnumberCalculator',
                subData: 'Sus.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    // const billetes = e.target.valueAsNumber || 0
                    // const monedas = values.monedas || 0
                    // const dolares_en_bs_total = values.dolares_en_bs_total || 0
                    // const efectivo_total_neto = values.efectivo_total_neto || 0
                    // const efectivo_entregado = ToTwoDecimal(billetes + monedas + dolares_en_bs_total)
                    // const sobrante_o_faltante = ToTwoDecimal(efectivo_total_neto - efectivo_entregado)
                    // if (sobrante_o_faltante >= 0) {
                    //     setValues('efectivo_faltante', sobrante_o_faltante)
                    //     setValues('efectivo_sobrante', 0)
                    // }
                    // else {
                    //     setValues('efectivo_sobrante', sobrante_o_faltante * -1)
                    //     setValues('efectivo_faltante', 0)
                    // }
                    // setValues('efectivo_entregado', efectivo_entregado)
                },
            },
            {
                label: 'Monedas',
                name: 'monedas',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    // const monedas = e.target.valueAsNumber || 0
                    // const billetes = values.billetes || 0
                    // const dolares_en_bs_total = values.dolares_en_bs_total || 0
                    // const efectivo_total_neto = values.efectivo_total_neto || 0
                    // const efectivo_entregado = ToTwoDecimal(billetes + monedas + dolares_en_bs_total)
                    // const sobrante_o_faltante = ToTwoDecimal(efectivo_total_neto - efectivo_entregado)
                    // if (sobrante_o_faltante >= 0) {
                    //     setValues('efectivo_faltante', sobrante_o_faltante)
                    //     setValues('efectivo_sobrante', 0)
                    // }
                    // else {
                    //     setValues('efectivo_sobrante', sobrante_o_faltante * -1)
                    //     setValues('efectivo_faltante', 0)
                    // }
                    // setValues('efectivo_entregado', efectivo_entregado)
                },
            },
            {
                label: 'Numero de Boleta',
                name: 'numero_boleto',
                type: 'text',
                required: false,
                placeholder: '####',
                autoFocus: false
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ],
    }
}