import React, { useEffect, useState } from 'react'
import { ButtonButton, ButtonSubmit, Divisor, InputDataList, Loader, SimpleAlert, TitleFormData } from './aditionalComponents'
import { requestAuth } from '../../../http/httpRequest'
import { toastOn } from '../../../store/slices/toast'
import { useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import { TagsStepCompraDictionary } from '../../../utils/dictionary'

export const Step5 = ({ generalData, setMainRecall, setReload, saveUrlApi, stepNumber, stepsPermissions }) => {
  const dispatch = useDispatch()
  const [currentData, setCurrentData] = useState(null)
  const [retorno, setRetorno] = useState([])
  const [itemsProv, setItemsProv] = useState([])
  // const [data, setData] = useState([])
  // const [dataItemsProv, setDataItemsProv] = useState([])
  const [loading, setLoading] = useState(true)

  const initialValues = {
    productos: retorno,
  };

  const getData = async () => {
    await requestAuth(
      'get',
      `compra/${generalData?.id}/paso/5`,
      null
    )
      .then((response) => {
        setCurrentData(response.data)
        setRetorno(response.data.retornoMap)
        setItemsProv(response.data.itemsProvMap)

        // setData(response.data.retornoMap.map(s => ({ 'almacen_producto_id': s.id + '', 'cantidad': 0 })))
        // setData(response.data.itemsProvMap.map(s => ({ 'almacen_producto_id': s.id + '', 'cantidad': 0 })))

        setLoading(false)
      }
      )
      .catch(error => {
        dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las casillas.' }))
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const addData = (value, name) => {
  //   console.log(name)
  //   const newData = data.map(item => {
  //     if (item.compra_historial_id === name) {
  //       return { ...item, cantidad: parseFloat(value) };
  //     }
  //     return item;
  //   })
  //   setData(newData)
  // }

  // const addDataItemsProv = (value, name) => {
  //   console.log(dataItemsProv)
  //   const newData = dataItemsProv.map(item => {
  //     if (item.compra_historial_id === name) {
  //       return { ...item, cantidad: parseFloat(value) };
  //     }
  //     return item;
  //   })
  //   setDataItemsProv(newData)
  // }
  const onSubmit = async (values) => {
    await requestAuth(
      'put',
      saveUrlApi,
      values
    )
      .then((response) => {
        dispatch(toastOn({ type: 'success', message: response.data.message }))
        setMainRecall(a => !a)
        setReload()
      }
      )
      .catch(error => {
        dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message ?? 'Error al enviar el formulario!' }))
      })
  }
  const sumarCantidades = (listObj) => {
    if (listObj == null || listObj.lenght <= 0)
      return false

    for (const objeto of listObj) {
      if (objeto.cantidad > 0)
        return true
    }
    return false
  }
  return (
    <>
      <TitleFormData
        title={TagsStepCompraDictionary[stepNumber]}
        subtitle={currentData?.fecha_fin}
        aditionaData={'ESTABLEZCA LA CANTIDAD DE RETORNO DE CADA ITEM'}
      />
      {
        loading ?
          <Loader />
          :
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ errors, isSubmitting, touched, values, submitForm, handleChange, setFieldValue }) => (
              <>
                <Divisor />
                <InputDataList
                  list={itemsProv}
                  // addData={addData}
                  field={{ indexLabel: 'etiqueta', indexId: 'id', indexRight: 'cantidadAlmacen' }}
                  setFieldValue={setFieldValue}
                  setDataList={setItemsProv}
                  title={<span>CANTIDAD DE EGRESO PARA EL PROVEEDOR <b>{currentData?.almacen_proveedor_nombre ?? ''}</b> E INGRESO PARA EL ALMACÉN <b>{currentData?.almacen_nombre ?? ''}</b></span>}
                />
                {/* <Divisor />
                <InputDataList
                  list={retorno}
                  addData={addData}
                  field={{ indexLabel: 'etiqueta', indexId: 'id', indexRight: 'cantidadAlmacen' }}
                  setFieldValue={setFieldValue}
                  setDataList={setRetorno}
                  title={`CANTIDAD DE INGRESO AL ALMACÉN ${currentData?.almacen_nombre ?? ''}`}
                /> */}
                <Form className='col-span-12 grid grid-cols-12 bg-white rounded-lg dark:bg-gray-700 gap-3'>
                  <div className='col-span-12 text-right mt-3'>
                    {
                      sumarCantidades(values.productos) &&
                      <SimpleAlert
                        data={<>Se creará un movimiento en egreso para el proveedor <span className='font-semibold'>{currentData?.almacen_proveedor_nombre ?? ''}</span> y un movimiento de ingreso para el almacén <span className='font-semibold'>{currentData?.almacen_nombre ?? ''}</span>.</>}
                      />
                    }
                    {
                      currentData.paso === 5 ?
                        <ButtonSubmit
                          isSubmitting={isSubmitting}
                          disabled={generalData?.paso === stepNumber ? (generalData.concluido ? true : false) : true}
                          textButton='Finalizar compra'
                        />
                        :
                        <ButtonButton
                          isSubmitting={isSubmitting}
                          disabled={generalData?.paso === stepNumber && stepsPermissions?.buttonsActive?.includes(5) ? (generalData.concluido ? true : false) : true}
                          textButton='Ver planilla'
                          onClick={() => {
                            setMainRecall(a => !a)
                            setReload()
                          }}
                        />
                    }
                  </div>
                </Form>
              </>
            )}
          </Formik>
      }
    </>
  )
}