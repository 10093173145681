import React from 'react'

export const DefaultCard = ({ children }) => {
    return (
        <div className='w-full bg-white rounded-r-lg rounded-l-lg rounded-b-lg rounded-t-none sm:rounded-r-lg sm:rounded-l-none dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700'>
            <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
                {children}
            </div>
        </div>
    )
}
