import React, { useEffect, useState } from 'react';

export const ScrollButton = () => {

    const [visible, setVisible] = useState(false)
    const [viewButtons, setViewButtons] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop
        if (scrolled > 10) {
            setVisible(true)
        }
        else if (scrolled <= 10) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const scrollToEnd = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);
    useEffect(() => {
        window.addEventListener('scroll', toggleVisible);
        return () => {
            window.removeEventListener('scroll', toggleVisible);
        };
    }, []);

    useEffect(() => {
        const scroll = document.documentElement.scrollHeight
        const height = document.documentElement.clientHeight

        if (scroll > height) {
            setViewButtons(true)
        }
        else {
            setViewButtons(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [document.documentElement.scrollHeight])


    return (
        viewButtons &&
        <div>
            <div className='fa fa-angle-up fixed right-0 bottom-0 px-2.5 py-1 rounded-full m-2 text-lg cursor-pointer bg-yellow-500 opacity-70 dark:opacity-50' onClick={scrollToTop} style={{ display: visible ? '' : 'none' }}>
            </div>
            <div className='fa fa-angle-down fixed right-0 bottom-0 px-2.5 py-1 rounded-full m-2 text-lg cursor-pointer bg-yellow-500 opacity-70 dark:opacity-50' onClick={scrollToEnd} style={{ display: visible ? 'none' : '' }}>
            </div>
        </div>
    );
}