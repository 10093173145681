import React, { useEffect } from 'react'
import { MinimalActionButton } from '../../../../../components/form/minimalActionButton'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { formatDateWithTime } from '../../../../../utils/dateFormat'
import { useSelector } from 'react-redux'
import { DefaultParamsForRecall } from '../../../../../utils/defaulStates'
import { Callers } from '../../../../../hooks/Callers'

export const ListClientesTable = ({
    reload,
    setReload,
    //
    params,
    setSelectAllChecked,
    setIsChecked,
    selecteds,
    setSelecteds,
    selectAllChecked,
    isChecked,
    recallState,
}) => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        stateData, setStateData,
        requestAuthPaginate,
        TableContainer,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        // RangeDate,
        TableSection,
        ActionSection,
    } = useGeneralParams()
    const reloadSubTable = useSelector(s => s.login.reloadSubTable)
    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/vendedor/${params.user_vend_id}/clientes/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'nombres' }
        })
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, paginate.tipo_negocio, params.user_vend_id, reloadSubTable]);


    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, paginate.tipo_negocio, params.user_vend_id, reloadSubTable]);

    const reloadTable = () => {
        Callers(dispatch)
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
    }

    return (
        <>
            <ActionSection>
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Nombre',
                            value: 'nombres'
                        },
                        {
                            label: 'Código',
                            value: 'codigo'
                        },
                        // {
                        //     label: 'Vendedor',
                        //     value: 'vendedor'
                        // },
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    {/* <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    /> */}
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: 'Estado',
                                value: ''
                            },
                            {
                                label: 'Activo',
                                value: 'activo'
                            },
                            {
                                label: 'Nuevo',
                                value: 'nuevo'
                            },
                            {
                                label: 'Inactivo',
                                value: 'inactivo'
                            },
                        ]}
                    />
                    {/* <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='rol'
                        options={[
                            {
                                label: 'Roles',
                                value: ''
                            },
                            {
                                label: 'Empresas',
                                value: 'empresa'
                            },
                            {
                                label: 'Mayoristas',
                                value: 'mayorista'
                            },
                        ]}
                    /> */}
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='tipo_negocio'
                        options={[
                            {
                                label: 'TIpo de negocio',
                                value: ''
                            },
                            {
                                value: 'Abarrotes',
                                label: 'Abarrotes'
                            },
                            {
                                value: 'Restaurante',
                                label: 'Restaurante'
                            },
                            {
                                value: 'Carniceria',
                                label: 'Carniceria'
                            },
                            {
                                value: 'Tienda',
                                label: 'Tienda'
                            },
                            {
                                value: 'Puesto',
                                label: 'Puesto'
                            },
                            {
                                value: 'Kiosco',
                                label: 'Kiosco'
                            },
                            {
                                value: 'Feria',
                                label: 'Feria'
                            },
                            {
                                value: 'Empresa',
                                label: 'Empresa'
                            },
                            {
                                value: 'Mayorista',
                                label: 'Mayorista'
                            },
                            {
                                value: 'Fabrica',
                                label: 'Fabrica'
                            },
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Negocio',
                            // columns: ['tipo_negocio'],
                            type: 'iconImage',
                            image: 'tipo_negocio'
                            // type: 'avatarName'
                        },
                        {
                            label: 'Nombre',
                            columns: ['codigo', 'nombres', 'apellido_paterno:apellido_materno'],
                            // icon: 'fa-solid fa-user-circle text-[30px] text-red-500',
                            // className: 'flex items-center',
                            // tag: true,
                        },
                        {
                            label: 'Telefonos',
                            columns: ['telefono', 'telefono2', 'telefono3'],
                            // numberValue: true,
                            // icon: 'fa-solid fa-user-circle text-[30px] text-red-500',
                            // className: 'flex items-center',
                            // tag: true,
                        },
                        {
                            label: 'Zona',
                            columns: ['zona'],
                            // numberValue: true,
                            // icon: 'fa-solid fa-user-circle text-[30px] text-red-500',
                            // className: 'flex items-center',
                            // tag: true,
                        },
                        // {
                        //     label: 'Vendedores a cargo',
                        //     type: 'tagList',
                        //     tagList: 'vendedores',
                        //     tagLabel: 'nombres'
                        // },
                        // {
                        //     label: 'Encargados',
                        //     type: 'listData',
                        //     tagList: 'vendedores',
                        //     tagLabel: 'nombres'
                        // },
                        {
                            label: 'Roles',
                            type: 'listData',
                            tagList: 'rols',
                            tagLabel: 'etiqueta',
                            iconFa: 'check',
                            aditionalClass: 'text-green-600 dark:text-green-300 pt-0.5'
                        },
                        // {
                        //     label: 'Roles',
                        //     type: 'tagList',
                        //     tagList: 'rols',
                        //     tagLabel: 'etiqueta'
                        // },
                        {
                            label: 'Estado',
                            columns: ['estado'],
                            tag: true
                        },
                        {
                            label: 'Fecha de registro',
                            columns: ['createdAt'],
                            transform: true,
                            func: formatDateWithTime
                        }
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />

            </TableSection>
        </>
    )
}