import React from 'react'
import logo from '../../assets/logoImage.png'

const DefaultBanner = () => {
    return (
        <div className='bg-white dark:bg-gray-800 flex justify-center rounded-md py-20 items-center'>
            <img src={logo} className='self-center mr-1 w-28 sm:w-32 md:w-auto' alt='widev' />
            <div className=' leading-7 md:leading-tight font-sans select-none'>
                <div className='md:font-semibold text-md sm:text-xl md:text-[38px] whitespace-nowrap text-black dark:text-gray-300 p-0 text-center' style={{ fontFamily: 'handel-gothic, sans-serif' }}>Distribuidora</div>
                <div className='font-extrabold text-[35px] sm:text-[40px] md:text-[58px] whitespace-nowrap text-red-600 dark:text-red-500 p-0 tracking-tighter' style={{ WebkitTextStroke: '2px black' }}>SOYA-SARI</div>
            </div>
        </div>
    )
}

export default DefaultBanner