import React, { useEffect } from 'react'
import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { TabContainer } from '../../../../components/tab/TabContainer'
import { TabItem } from '../../../../components/tab/TabItem'
import { VerificationPathByString } from '../../../../utils'
import { ErrorPage } from '../../../error/errorPage'
import { EstadoDeCuentas } from './EstadoDeCuentas'
import { useNavigate } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { HistorialTransacciones } from './HistorialTransacciones'

export const InformeGeneralDeTesoreriaTab = () => {
    const {
        Section,
    } = useGeneralParams('')

    const navigate = useNavigate()
    useEffect(() => {
        navigate('estado-de-cuentas')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Section>
            <TabContainer>
                <TabItem
                    to='estado-de-cuentas'
                    label='ESTADO DE CUENTAS'
                    active={VerificationPathByString('/estado-de-cuentas')}
                />
                <TabItem
                    to='transacciones'
                    label='HISTORIAL DE TRANSACCIONES'
                    active={VerificationPathByString('/transacciones')}
                />
            </TabContainer>
            <Routes>
                <Route
                    index
                    element={<Navigate to='estado-de-cuentas' />}
                />
                <Route
                    path='estado-de-cuentas'
                    element={
                        <TabBodyContainer>
                            <EstadoDeCuentas
                                title='ESTADO DE CUENTAS Y SUB-CUENTAS'
                                subtitle=''
                                urlApi='informe/tesoreria/estadoDeCuentas/pag'
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='transacciones'
                    element={
                        <TabBodyContainer>
                            <HistorialTransacciones
                                title='HISTORIAL DE TRANSACCIONES'
                                // subtitle='Ventas en Unidades de Turriles de 200L'
                                urlApi='informe/tesoreria/transacciones/pag'
                            />
                        </TabBodyContainer>
                    } />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </Section>
    )
}

const TabBodyContainer = ({ children }) => {
    return (
        <div className='grid gap-5'>
            {children}
        </div>
    )
}