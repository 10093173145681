import React, { useState } from 'react'
import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';

import { ErrorPage } from '../../../error/errorPage';
import { ListEfectivoTable } from './ListEfectivoTable';
import { EfectivoCierresHistorial } from './efectivo_cierres';
import { EfectivoHistorial } from './efectivo_historial';
import CardInfoEfectivo from './elementosGenerales/CardInfo';
import { useSelector } from 'react-redux';
import { TabTableSection } from '../../../../components/tab/TabTableSection';

export const SubEfectivoIndex = ({ globalParams, efectivoParams, reloaders, dispatchs }) => {
    const [data, setData] = useState(null);
    const reloadMainTable = useSelector(state => state.login.reloadMainTable)
    return (
        <div className='grid grid-cols-1 gap-2'>
            <TabTableSection>
                <CardInfoEfectivo
                    reload={reloadMainTable}
                    data={data}
                    setData={setData}
                    nameLabel='SUB CUENTAS'
                />
                <ListEfectivoTable
                    globalParams={globalParams}
                    dataCard={data}
                    efectivoParams={{
                        type: 'subEfectivo'
                    }}
                    reloaders={reloaders}
                    dispatchs={dispatchs}
                />
            </TabTableSection>
            <Routes>
                <Route index element={
                    null
                } />
                <Route
                    path={`/historial/:sub_efectivo_id/*`}
                    element={<EfectivoHistorial
                        efectivoParams={{
                            type: 'subEfectivo'
                        }}
                    />}
                />
                <Route
                    path={`/cierres/:sub_efectivo_id/*`}
                    element={<EfectivoCierresHistorial
                        efectivoParams={{
                            type: 'subEfectivo'
                        }}
                        reloaders={reloaders}
                    />}
                />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </div>
    )
}