import axios from 'axios'
import config from './config.json'
import { toastOn } from '../store/slices/toast'
// const hostname = window.location.hostname
// axios.defaults.baseURL = `${config?.hostname2 === hostname
//     ? config.requestURL2
//     : config?.hostname === hostname
//       ? config.requestURL
//       : config.requestURL
//   }`

axios.defaults.baseURL = config.urlApi
// const instance = axios.create()

// export function request(method, url, data) {
//   // return instance({ method, url, data })
//   return axios({
//     method,
//     headers: {
//       Authorization: 'Bearer ' + localStorage.getItem('token_seguridad'),
//     },
//     url,
//     data,
//   })
// }
export function requestAuth(
  method,
  url,
  data
) {
  return axios({
    method,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    url,
    data,
  })
}

export async function requestDefaulAuth(
  method,
  url,
  data,
  setData,
  dispatch,
  queries
) {
  return axios({
    method,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    url,
    data,
    params: queries
  })
    .then((response) => {
      setData(response.data)
    }
    )
    .catch(error => {
      dispatch(toastOn({ type: 'danger', message: (`Error: ${error?.response?.data?.message || 'error desconocido!'}`) }))
    })
}

export async function requestAuthPaginate({
  method,
  url,
  data,
  queries,
  setData,
  setStateData,
  setQueries,
  dispatch,
  aditionalParams
}) {
  setStateData('loading')
  let { initial, final, totalItems, totalPages, ...query } = queries
  if (queries.final !== '' || queries.initial !== '') {
    query = {
      ...query,
      initial: queries.initial,
      final: queries.final
    }
  }
  if (aditionalParams != null && aditionalParams !== undefined)
    query = {
      ...query,
      ...aditionalParams
    }
  // const urlpaginate = url
  // const urlpaginate = `${url}?pageSize=${paginate.pageSize}&currentPage=${paginate.currentPage}&filterBy=${paginate.filterBy}&filterParam=${paginate.filterParam}${dateFilter}${paginate.filters}`
  return axios({
    method,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    url: url,
    data,
    params: query
  })
    .then((response) => {
      setData(response.data)
      if (response.data.data.length > 0) {
        setStateData('')
      }
      else {
        setStateData('empty')

      }
      setQueries({
        ...queries,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages,
        currentPage: response.data.currentPage,
        pageSize: response.data.pageSize
      })
    }
    )
    .catch(error => {
      dispatch(toastOn({ type: 'danger', message: (`Error: ${error?.response?.data?.message || 'error desconocido!'}`) }))
      setStateData('error')
    })
}

export async function requestSimpleAuthPaginate(
  method,
  url,
  data,
  setFullData,
  dispatch,
  setPaginate
) {
  setFullData(x => ({ ...x, stateData: 'loading' }))
  // let dateFilter = ''
  // if (paginate.final !== '' || paginate.initial !== '') {
  //   dateFilter = `&initial=${paginate.initial}&final=${paginate.final}`
  // }
  // const urlpaginate = `${url}?pageSize=${paginate.pageSize}&currentPage=${paginate.currentPage}&filterBy=${paginate.filterBy}&filterParam=${paginate.filterParam}${dateFilter}${paginate.filters}`
  return axios({
    method,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    url: url,
    data,
  })
    .then((response) => {
      setFullData(x => ({ ...x, data: response.data.data }))
      if (response.data.data.length > 0) {
        setFullData(x => ({ ...x, stateData: '' }))
      }
      else {
        setFullData(x => ({ ...x, stateData: 'empty' }))

      }
      setPaginate(x => ({
        ...x,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages,
        currentPage: response.data.currentPage,
        pageSize: response.data.pageSize
      }))
    }
    )
    .catch(error => {
      dispatch(toastOn({ type: 'danger', message: (`Error: ${error?.response?.data?.message || 'error desconocido!'}`) }))
      setFullData(x => ({ ...x, stateData: 'error' }))
    })
}