import * as Yup from 'yup';

export const UpdateValues = (data) => {
    return {
        initialValues: {
            id: data?.id || '',
            nombre: data?.nombre || '',
            descripcion: data?.descripcion || '',
            unique: data?.unique || false,
            main_estado_id: data?.main_estado_id ? (data?.main_estado_id + '') : ''
        },
        fieldsValidation: Yup.object().shape({
            nombre: Yup.string()
                .min(2, 'Mínimo 2 caracteres!')
                .max(50, 'Máximo 40 caracteres!')
                .required('Campo requerido'),
        }),
        fields: [
            {
                label: 'Nombre',
                name: 'nombre',
                type: 'text',
                required: true,
                placeholder: 'Nombre...',
                autoFocus: true
            },
            {
                label: 'Descripción',
                name: 'descripcion',
                type: 'text',
                required: false,
                placeholder: 'Descripción...',
                autoFocus: true
            },
            {
                label: 'Nombre único',
                name: 'unique',
                type: 'checkbox',
            },
            // {
            //     label: 'Pertenece a:',
            //     name: 'main_estado_id',
            //     type: 'radio',
            //     required: true,
            //     urlApi: `/estados/${data?.id}`,
            // },
        ]
    }
}

export const CreateValues = (main_estado_id = '') => {
    return {
        initialValues: {
            id: '',
            nombre: '',
            descripcion: '',
            unique: false,
            main_estado_id: main_estado_id
        },
        fieldsValidation: Yup.object().shape({
            nombre: Yup.string()
                .min(3, 'Mínimo 3 caracteres!')
                .max(50, 'Máximo 40 caracteres!')
                .required('Campo requerido'),
        }),
        fields: [
            {
                label: 'Nombre',
                name: 'nombre',
                type: 'text',
                required: true,
                placeholder: 'Nombre...',
                autoFocus: true
            },
            {
                label: 'Descripción',
                name: 'descripcion',
                type: 'text',
                required: false,
                placeholder: 'Descripción...',
                // autoFocus: true
            },
            {
                label: 'Nombre único',
                name: 'unique',
                type: 'checkbox',
            },
            // ...(main_estado_id === '' || main_estado_id === null ?
            //     [
            //         {
            //             label: 'Pertenece a:',
            //             name: 'main_estado_id',
            //             type: 'radio',
            //             required: true,
            //             urlApi: '/estados/null',
            //         }
            //     ] : []
            // )
        ]
    }
}

export const DeleteValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}