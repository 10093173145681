import * as Yup from 'yup';

export const TraspasoDeProductos = (cuaderno) => {
    return {
        initialValues: {
            cuaderno_id: cuaderno?.id,
            almacen_producto_origen_id: '',
            almacen_producto_destino_id: '',
            cantidadAlmacen: 0,
            cantidadTotal: 0,
            cantidad: 0,
            obs: ''
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: [
            {
                label: 'Mis productos/envases',
                name: 'almacen_producto_origen_id',
                type: 'selectSearchDetailCard',
                // param: 'user_id',
                urlApi: `/user/${cuaderno?.user_id}/almacenes`,
                required: true,
                disabled: false,
                labelDescription: 'etiqueta',
                placeholder: 'Producto...',
                autoFocus: true,
                nameNumber: 'cantidadAlmacen',
                labelNumberRestante: 'Restante',
                nameNumberRestante: 'cantidad_total_restante',
                alterField: 'cantidadTotal',
                exclamationDefault: 'Seleccione un producto!',
                exclamationRestante: 'Stock insuficiente!',
                infoTags: [
                    {
                        label: 'Producto',
                        data: 'producto_nombre'
                    },
                    {
                        label: 'Estado',
                        data: 'estado_nombre'
                    },
                    {
                        label: 'En almacén',
                        data: 'cantidadAlmacen',
                        mark: true
                    },
                ]
            },
            {
                label: 'Traspasar a',
                name: 'almacen_producto_destino_id',
                type: 'subSelectDinamicSearch',
                param: 'almacen_producto_origen_id',
                urlApi: '/user/{param}/almacenProductos',
                required: true,
                labelDescription: 'etiqueta',
                labelId: 'id',
                placeholder: 'Vendedor...',
                autoFocus: false,
                // showValidator: true
            },
            {
                label: 'Cantidad',
                name: 'cantidad',
                type: 'numberCalculator',
                subData: null,
                required: true,
                disabled: false,
                locked: false,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad = e.target.valueAsNumber || 0;
                    const cantidad_total = values.cantidadAlmacen || 0
                    const total = cantidad_total - cantidad
                    setValues('cantidad_total_restante', total < 0 ? 0 : total)
                }
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}