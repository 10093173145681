import * as Yup from 'yup';

export const CreateValues = (isPersonal) => {
    return {
        initialValues: {
            nombre: '',
            encargado_id: '',
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: [
            !isPersonal ?
                {
                    label: 'Cuenta',
                    name: 'nombre',
                    type: 'text',
                    // required: true,
                    placeholder: 'Nombre...',
                    autoFocus: true
                } :
                {
                    label: 'Vendedor responsable',
                    name: 'encargado_id',
                    type: 'selectSearch',
                    urlApi: `/vendedores`,
                    required: false,
                    labelDescription: 'nombre',
                    placeholder: 'Vendedor...',
                    autoFocus: false,
                    // defaultValue: { value: '2', label: 'Clientes' }
                },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ],
    }
}

export const CreateSubCuentaValues = (efectivo_id, isProd) => {
    return {
        initialValues: {
            nombre: '',
            // encargado_id: '',
            efectivo_id: efectivo_id,
            productos: []
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: [
            isProd ?
                {
                    label: 'Seleccionar producto',
                    name: 'productos',
                    type: 'checkboxes',
                    required: false,
                    urlApi: `/producto/efectivo/${efectivo_id}/no-existentes`,
                    indexLabel: 'nombre'
                }
                :
                {
                    label: 'Cuenta',
                    name: 'nombre',
                    type: 'text',
                    // required: true,
                    placeholder: 'Nombre...',
                    autoFocus: true
                },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ],
    }
}

export const DeleteValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}