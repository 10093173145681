export function iconByNameMenu(name) {
    switch (name) {
        case 'circle':
            return 'fa-solid fa-circle dark:text-gray-400 text-gray-700 text-[6px] pt-[1px]'

        default:
            return 'fa-solid fa-list-check dark:text-yellow-500 text-yellow-700'
    }
}

export function iconSelectByNameMenu(name) {
    switch (name) {
        default:
            return 'fa-solid fa-angle-right pt-[5px]'
    }
}