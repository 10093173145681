import React, { useEffect } from 'react'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { formatDateWithTime } from '../../../../../utils/dateFormat'
import { CreateValues, DeleteValues } from '../../../../../FormSchemes/EfectivoCierreScheme'
import { MinimalActionButton } from '../../../../../components/form/minimalActionButton'
import { DefaultParamsForRecall } from '../../../../../utils/defaulStates'
import { setReloadMainTable, setReloadMinorTable, setReloadSubTable } from '../../../../../store/slices/auth/authSlice'

export const ListEfectivoCierreTable = ({
    reload,
    setReload,
    efectivoParams,
    reloaders
}) => {
    const {
        dispatch,
        params,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        createModal, setCreateModal,
        deleteModal, setDeleteModal,
        currentData, setCurrentData,
        // imports
        requestAuthPaginate,
        TableContainer,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        RangeDate,
        Actions,
        TableSection,
        ActionSection,
        Section,
        ModalForm,
        UpdateValuesModal,
        redirect
    } = useGeneralParams()

    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/efectivo/${efectivoParams?.type === 'subEfectivo' ? params.sub_efectivo_id : params.efectivo_id}/cierres/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'nombre' }
        })
    }
    useEffect(() => {
        getDataPaginate()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, params.efectivo_id, ...(reloaders ? reloaders : [])])

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, ...reloaders])

    const recall = () => {
        dispatch(setReloadMainTable())
        dispatch(setReloadSubTable())
        dispatch(setReloadMinorTable())
    }

    return (
        <Section>
            <ActionSection>
                <Actions
                    buttons={[
                        {
                            icon: 'shop-lock',
                            label: 'Crear cierre',
                            action: () => setCreateModal(true)
                        },
                    ]}
                />
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Nombre',
                            value: 'nombre'
                        },
                        {
                            label: 'Código',
                            value: 'codigo'
                        }
                    ]}
                />
                <div className='flex gap-2 flex-wrap'>
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: 'Estado',
                                value: ''
                            },
                            {
                                label: 'Activo',
                                value: 'activo'
                            },
                            {
                                label: 'Inactivo',
                                value: 'inactivo'
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={recall}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Nombre',
                            columns: ['nombre']
                        },
                        {
                            label: 'Monto inicial',
                            columns: ['monto_inicial'],
                            numberValue: true,
                        },
                        {
                            label: 'Monto final',
                            columns: ['monto_final'],
                            numberValue: true,
                        },
                        {
                            label: 'Monto ingreso',
                            columns: ['monto_total_ingreso'],
                            numberValue: true,
                        },
                        {
                            label: 'Monto egreso',
                            columns: ['monto_total_egreso'],
                            numberValue: true,
                        },
                        {
                            label: 'Fecha de inicio',
                            columns: ['fecha_inicio'],
                            transform: true,
                            func: formatDateWithTime
                        },
                        {
                            label: 'Fecha de fin',
                            columns: ['fecha_fin'],
                            transform: true,
                            func: formatDateWithTime
                        },
                        {
                            label: 'Acciones',
                            actions: [

                                {
                                    type: 'yellow',
                                    icon: 'fa-share-from-square',
                                    action: (i) => redirect(`historial/${i.id}`),
                                    reference: 'id'
                                },
                                {
                                    type: 'delete',
                                    icon: 'fa-trash',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                    reference: 'id',
                                    tooltipText: 'Eliminar'
                                },
                            ],
                            // stickyR: true
                        },
                    ]}
                    data={data.data}
                    checkList={false}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {
                createModal &&
                <ModalForm
                    setModal={setCreateModal}
                    label='Crear cierre'
                    dataValues={CreateValues(efectivoParams?.type === 'subEfectivo' ? params.sub_efectivo_id : params.efectivo_id)}
                    urlApi={`/efectivo_cierre`}
                    method={'post'}
                    call={recall}
                    buttonLabel='Crear cierre'
                />
            }
            {
                deleteModal &&
                <ModalForm
                    setModal={setDeleteModal}
                    label='Eliminar cierre'
                    dataValues={DeleteValues(currentData)}
                    urlApi={`/efectivo_cierre/${currentData.id}`}
                    method={'delete'}
                    call={recall}
                    buttonLabel='Eliminar'
                />
            }
        </Section>
    )
}