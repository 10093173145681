import * as Yup from 'yup';

export const RegistroDeudaEnvaseHistorialValues = (deuda_envase_id, metodo_intercambio) => {
    return {
        initialValues: {
            deuda_envase_id: deuda_envase_id,
            metodo_pago_garantia: 'efectivo',
            monto_garantia: 0,
            cantidad: 0,
            obs: '',
        },
        fieldsValidation: Yup.object().shape({
            // cantidad: NumberIntegerRequired,            
        }),
        fields: [
            {
                label: 'Método de pago',
                name: 'metodo_pago_garantia',
                type: 'select',
                required: true,
                options: [
                    {
                        label: 'Efectivo',
                        value: 'efectivo'
                    },
                    {
                        label: 'Transferencia',
                        value: 'transferencia'
                    },
                    {
                        label: 'Cheque',
                        value: 'cheque'
                    },
                ],
            },
            ...(metodo_intercambio === 'garantía' ?
                [
                    {
                        label: 'Monto de garantía',
                        name: 'monto_garantia',
                        type: 'groupnumber',
                        subData: 'Bs.',
                        disabled: false,
                        required: true,
                        highlight: 'bg-green-600 dark:bg-green-500 bg-opacity-25 dark:bg-opacity-20'
                    },
                ]
                :
                []
            ),
            {
                label: 'Envases recogidos',
                name: 'cantidad',
                type: 'number',
                subData: null,
                required: true,
            },
            {
                type: 'divider'
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const DeleteValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}