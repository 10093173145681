import React, { useEffect, useState } from 'react'

export const CuadernoEfectivoPersonalForm = ({
    field,
    errors,
    touched,
    values,
    setValues,
    // data,
    handleChange
}) => {
    // const [options, setOptions] = useState([])
    const data = values.cuadernoData
    const [item, setItem] = useState(null)
    // const [fullData, setFullData] = useState([])
    // const [extraData, setExtraData] = useState(null)
    const [caja, setCaja] = useState(null)
    const [garantias, setGarantias] = useState(null)
    const [bancos, setBancos] = useState(null)
    const [faltante, setFaltante] = useState(null)
    const [gasto, setGasto] = useState(null)
    const [sobrante, setSobrante] = useState(null)
    const [garantiasBancos, setGarantiasBancos] = useState(null)

    useEffect(() => {
        setFormCuadernoData(data, setItem, setCaja, setGarantias, setBancos, setFaltante, setGasto, setSobrante, setGarantiasBancos, setValues)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        item &&
        <div className='col-start-1 col-end-13 dark:text-gray-100 bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-500 rounded p-2 sm:max-h-[200px] lg:max-h-[600px] overflow-scroll'>
            <center className='font-semibold mb-2 tracking-widest'>CUADERNO {item.codigo}</center>
            <TableItem
                data={caja}
                titleData={'CAJA CENTRAL'}
                faltante={faltante}
                gasto={gasto}
                sobrante={sobrante}
            />
            <TableItem
                data={garantias}
                titleData={'GARANTÍAS CENTRAL'}
            />
            <TableItem
                data={bancos}
                titleData={'BANCOS CENTRAL'}
            />
            <TableItem
                data={garantiasBancos}
                titleData={'GARANTÍAS EN BANCOS CENTRAL'}
            />
            {
                faltante?.monto_total > 0 &&
                <div>
                    <AdvertenciaDeuda
                        user={item}
                        faltante={faltante}
                    />
                </div>
            }
        </div>
    )
}

const HeaderRow = () => (
    <div className='grid grid-cols-5 pb-.5'>
        {['CUENTA', 'BILLETES', 'MONEDAS', 'DOLARES EN BS', 'TOTAL'].map((header, idx) => (
            <div className={`${idx > 0 ? 'text-right' : ''}`} key={header}>
                <span className='text-blue-600 dark:text-blue-400'>{header}</span>
            </div>
        ))}
    </div>
);

const DataRow = ({ producto, billetes, monedas, dolares_en_bs, monto_total, isNegative, isTotal }) => (
    <div className={`grid grid-cols-5 ${isNegative ? 'text-red-400' : (isTotal ? 'text-green-600 dark:text-green-500' : '')}`}>
        <div className='border-b-[1px] border-neutral-500 border-dashed'>
            <span>{producto?.toUpperCase()}</span>
        </div>
        {[billetes, monedas, dolares_en_bs, monto_total].map((value, index) => (
            <div className='text-right border-b-[1px] border-neutral-500 border-dashed' key={index}>
                <span>{isNegative ? '-' : ''}{value.toLocaleString('es-BO', { minimumFractionDigits: 2 })}</span>
            </div>
        ))}
    </div>
);

const SummaryRow = ({ label, data, isNegative }) => (
    data?.monto_total > 0 && (
        <div className='flex flex-col text-sm px-2 font-semibold dark:text-gray-300 text-gray-700'>
            <DataRow
                producto={label}
                billetes={data?.billetes}
                monedas={data?.monedas}
                dolares_en_bs={data?.dolares_en_bs}
                monto_total={data?.monto_total}
                isNegative={isNegative}
            />
        </div>
    )
);

export const TableItem = ({ data, titleData, faltante, gasto, sobrante }) => (
    <div className='min-w-[400px] max-w-[600px] bg-neutral-100 dark:bg-gray-800 mb-2 m-auto rounded-sm shadow-sm shadow-gray-500 dark:shadow-none'>
        <div className='flex flex-col text-sm bg-slate-300 dark:bg-gray-800 px-2 font-semibold dark:text-gray-300 text-gray-700 rounded-t-sm'>
            <div className=''>
                <center><span className='dark:text-green-400 font-bold tracking-widest'>{titleData}</span></center>
            </div>
            <HeaderRow />
        </div>
        {data?.dataList?.map((cef, index) => (
            <div
                className='flex flex-col text-sm px-2 font-semibold dark:text-gray-300 text-gray-700'
                key={index}
            >
                <DataRow
                    producto={cef?.Producto?.nombre}
                    billetes={cef?.billetes}
                    monedas={cef?.monedas}
                    dolares_en_bs={cef?.dolares_en_bs}
                    monto_total={cef?.monto_total}
                />
            </div>
        ))}
        <SummaryRow label="FALTANTE" data={faltante} isNegative />
        <SummaryRow label="GASTO" data={gasto} isNegative />
        <SummaryRow label="SOBRANTE" data={sobrante} />

        <div className='flex flex-col mb-1 text-sm px-2 pb-1 font-semibold dark:text-gray-300 text-gray-700'>
            <DataRow
                producto="TOTAL"
                billetes={data?.billetes}
                monedas={data?.monedas}
                dolares_en_bs={data?.dolares_en_bs}
                monto_total={data?.monto_total}
                isTotal
            />
        </div>
    </div>
);

export const setFormCuadernoData = (data, setItem, setCaja, setGarantias, setBancos, setFaltante, setGasto, setSobrante, setGarantiasBancos, setValues) => {
    var recibo = data?.recibo ?? ''
    var obs = data?.obs ?? ''

    setItem(data)

    // faltante
    let faltanteProducto = data?.Cuaderno_efectivos?.find(a => a.tipo === 'faltante')?.Producto?.nombre
    let faltanteBilletes = data?.Cuaderno_efectivos?.find(a => a.tipo === 'faltante')?.billetes || 0
    let faltanteMonedas = data?.Cuaderno_efectivos?.find(a => a.tipo === 'faltante')?.monedas || 0
    let faltanteDolares_en_bs = data?.Cuaderno_efectivos?.find(a => a.tipo === 'faltante')?.dolares_en_bs || 0
    let faltanteMonto_total = data?.Cuaderno_efectivos?.find(a => a.tipo === 'faltante')?.monto_total || 0
    // gasto
    let gastoBilletes = data?.Cuaderno_efectivos?.find(a => a.tipo === 'gasto')?.billetes || 0
    let gastoMonedas = data?.Cuaderno_efectivos?.find(a => a.tipo === 'gasto')?.monedas || 0
    let gastoDolares_en_bs = data?.Cuaderno_efectivos?.find(a => a.tipo === 'gasto')?.dolares_en_bs || 0
    let gastoMonto_total = data?.Cuaderno_efectivos?.find(a => a.tipo === 'gasto')?.monto_total || 0
    // sobrante
    let sobranteBilletes = data?.Cuaderno_efectivos?.find(a => a.tipo === 'sobrante')?.billetes || 0
    let sobranteMonedas = data?.Cuaderno_efectivos?.find(a => a.tipo === 'sobrante')?.monedas || 0
    let sobranteDolares_en_bs = data?.Cuaderno_efectivos?.find(a => a.tipo === 'sobrante')?.dolares_en_bs || 0
    let sobranteMonto_total = data?.Cuaderno_efectivos?.find(a => a.tipo === 'sobrante')?.monto_total || 0

    setCaja({
        dataList: data?.Cuaderno_efectivos?.filter(a => a.tipo === 'caja'),
        billetes: data?.Cuaderno_efectivos?.filter(a => a.tipo === 'caja').reduce((acumulador, i) => acumulador + i.billetes, 0) - faltanteBilletes - gastoBilletes + sobranteBilletes,
        monedas: data?.Cuaderno_efectivos?.filter(a => a.tipo === 'caja').reduce((acumulador, i) => acumulador + i.monedas, 0) - faltanteMonedas - gastoMonedas + sobranteMonedas,
        dolares_en_bs: data?.Cuaderno_efectivos?.filter(a => a.tipo === 'caja').reduce((acumulador, i) => acumulador + i.dolares_en_bs, 0) - faltanteDolares_en_bs - gastoDolares_en_bs + sobranteDolares_en_bs,
        monto_total: data?.Cuaderno_efectivos?.filter(a => a.tipo === 'caja').reduce((acumulador, i) => acumulador + i.monto_total, 0) - faltanteMonto_total - gastoMonto_total + sobranteMonto_total,
    })
    setGarantias({
        dataList: data?.Cuaderno_efectivos?.filter(a => a.tipo === 'garantía'),
        billetes: data?.Cuaderno_efectivos?.filter(a => a.tipo === 'garantía').reduce((acumulador, i) => acumulador + i.billetes, 0),
        monedas: data?.Cuaderno_efectivos?.filter(a => a.tipo === 'garantía').reduce((acumulador, i) => acumulador + i.monedas, 0),
        dolares_en_bs: data?.Cuaderno_efectivos?.filter(a => a.tipo === 'garantía').reduce((acumulador, i) => acumulador + i.dolares_en_bs, 0),
        monto_total: data?.Cuaderno_efectivos?.filter(a => a.tipo === 'garantía').reduce((acumulador, i) => acumulador + i.monto_total, 0),
    })
    setGarantiasBancos({
        dataList: data?.Cuaderno_efectivos?.filter(a => a.tipo === 'garantía en banco'),
        billetes: data?.Cuaderno_efectivos?.filter(a => a.tipo === 'garantía en banco').reduce((acumulador, i) => acumulador + i.billetes, 0),
        monedas: data?.Cuaderno_efectivos?.filter(a => a.tipo === 'garantía en banco').reduce((acumulador, i) => acumulador + i.monedas, 0),
        dolares_en_bs: data?.Cuaderno_efectivos?.filter(a => a.tipo === 'garantía en banco').reduce((acumulador, i) => acumulador + i.dolares_en_bs, 0),
        monto_total: data?.Cuaderno_efectivos?.filter(a => a.tipo === 'garantía en banco').reduce((acumulador, i) => acumulador + i.monto_total, 0),
    })
    setBancos({
        dataList: data?.Cuaderno_efectivos?.filter(a => a.tipo === 'banco'),
        billetes: data?.Cuaderno_efectivos?.filter(a => a.tipo === 'banco').reduce((acumulador, i) => acumulador + i.billetes, 0),
        monedas: data?.Cuaderno_efectivos?.filter(a => a.tipo === 'banco').reduce((acumulador, i) => acumulador + i.monedas, 0),
        dolares_en_bs: data?.Cuaderno_efectivos?.filter(a => a.tipo === 'banco').reduce((acumulador, i) => acumulador + i.dolares_en_bs, 0),
        monto_total: data?.Cuaderno_efectivos?.filter(a => a.tipo === 'banco').reduce((acumulador, i) => acumulador + i.monto_total, 0),
    })
    setFaltante({
        producto: faltanteProducto,
        billetes: faltanteBilletes,
        monedas: faltanteMonedas,
        dolares_en_bs: faltanteDolares_en_bs,
        monto_total: faltanteMonto_total,
    })
    setGasto({
        billetes: gastoBilletes,
        monedas: gastoMonedas,
        dolares_en_bs: gastoDolares_en_bs,
        monto_total: gastoMonto_total,
    })
    setSobrante({
        billetes: sobranteBilletes,
        monedas: sobranteMonedas,
        dolares_en_bs: sobranteDolares_en_bs,
        monto_total: sobranteMonto_total,
    })
    setValues('recibo', recibo)
    setValues('obs', obs)
}

export const AdvertenciaDeuda = ({ faltante, user }) => {
    return (
        <div id='alert-border-4' className='flex items-center p-2 text-yellow-800 border-b-[1px] border-l-[1px] border-r-[1px] border-t-8 border-yellow-400 bg-yellow-50 dark:text-yellow-300 dark:bg-gray-800 dark:border-yellow-800' role='alert'>
            <svg className='flex-shrink-0 w-4 h-4' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 20 20'>
                <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z' />
            </svg>
            <div className='ms-3 text-sm font-medium'>
                Se creara una deuda por el monto faltante de <b>Bs. {faltante?.monto_total}</b> a nombre de <b>{user?.nombres + ' ' + user.apellido_paterno + ' ' + user.apellido_materno + ''}</b>
            </div>
        </div>
    )
}