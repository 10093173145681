export const ImageOrDefault = ({ image, className = '', size = '120px' }) => {
    return (
        image !== null && image !== '' && image !== undefined ?
            // <img className='h-auto max-w-full' src={imgDefault} alt='' />
            // <img className='h-auto max-w-full' src={`https://via.placeholder.com/${size}`} alt='' />
            <img className='h-auto max-w-full' src={image} alt='' />
            :
            <i className={`fa-solid fa-image text-[${size}] text-gray-500 dark:text-gray-400 ${className}`} />
    )
}

export const ProfileData = ({ tipoNegocio, image, header }) => {
    return (
        <>
            {
                image !== null && image !== undefined && image !== '' ?
                    <>
                        <img className='w-24 h-24 mb-3' src={image} alt='' />
                        <span className='text-xl text-blue-600 dark:text-gray-400 font-semibold'>{tipoNegocio}</span>
                    </>
                    :
                    <>
                        <i className='fa-solid fa-user-circle text-[120px] text-gray-500 dark:text-gray-400 mb-3' />
                        <span className='text-xl text-blue-600 dark:text-gray-400 font-semibold'>{tipoNegocio}</span>
                    </>
            }
            <h5 className='mb-1 text-xl font-medium text-gray-900 dark:text-white'>{header}</h5>
        </>
    )
}

export const GeneralContainerProfileCard = ({ children }) => {
    return (
        <div className='grid grid-cols-2 gap-4'>
            {children}
        </div>
    )
}

export const SectionCard = ({ children }) => {
    return (
        <div className='col-span-2 sm:col-span-1 grid grid-cols-1 gap-4'>
            <div className='flex flex-col gap-4'>
                {children}
            </div>
        </div>
    )
}

export const CenterSmallSectionList = ({ listData }) => {
    return (
        <div className='flex flex-col m-auto w-full md:w-3/4 lg:w-2/4'>
            {
                listData?.map((item, index) => (
                    <div
                        key={index}
                        className='flex py-1 justify-between border-b-[0.1px] border-gray-300 dark:border-gray-500'
                    >
                        <span className='font-semibold dark:text-gray-300'>{item?.label}</span> <span className='dark:text-gray-400'>{item?.value !== null && item?.value !== undefined && item?.value !== '' ? item?.value : '-'}</span>
                    </div>

                ))
            }
        </div>
    )
}

export const LargeSectionList = ({ listData }) => {
    return (
        <div className='w-full p-2'>
            {
                listData?.map((item, index) => (
                    <div
                        key={index}
                        className={`flex flex-col py-1 justify-between ${listData.length === (index + 1) ? '' : 'border-b-[0.1px] border-gray-300 dark:border-gray-500'}`}
                    >
                        {
                            item.type !== 'list' ?
                                <div>
                                    <div className='font-semibold dark:text-gray-300'>{item?.label}</div>
                                    <div className='dark:text-gray-400 text-blue-600 font-semibold'>{item?.value !== null && item?.value !== undefined && item?.value !== '' ? item?.value : '-'}</div>
                                </div>
                                :
                                <div>
                                    <div className='font-semibold dark:text-gray-300'>{item?.label}</div>
                                    <ul
                                        className='list-disc pl-4 text-blue-600 dark:text-gray-400 font-semibold'
                                    >
                                        {
                                            item?.value?.map((i, index) => (
                                                <li
                                                    key={index}
                                                >
                                                    {i.nombres}
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                        }
                    </div>

                ))
            }
        </div>
    )
}