import React from 'react'
import { HistorialGarantia } from './historial_garan'
import { ErrorPage } from '../../../error/errorPage'
import { Route, Routes } from 'react-router-dom'
import { ListDeudaEnvaseTable } from './ListDeudaEnvaseTable'


export const DeudaEnvases = ({ globalParams }) => {
    return (
        <div className='grid grid-cols-1 gap-2'>
            <div className='col-span-1'>
                <ListDeudaEnvaseTable globalParams={globalParams} />
            </div>
            <Routes>
                <Route index element={
                    null
                } />
                <Route
                    path={`deudaEnvases/garantía/:deuda_id/*`}
                    element={<HistorialGarantia globalParams={globalParams}/>}
                />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </div>
    )
}