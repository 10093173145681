import React from 'react'
import { Link, } from 'react-router-dom';
import useMenuHandling from '../../hooks/useMenuHandling';
import DefaultBanner from '../../components/banner/DefaultBanner';
// import { useLocation } from 'react-router-dom';

export const ListMenus = ({ description }) => {

    const { menus, params } = useMenuHandling()
    // const location = useLocation();
    // console.log(location.pathname.slice(1).split('/'))
    return (
        <>
            <DefaultBanner />
            <div>
                <h1 className='dark:text-gray-400 font-semibold text-md my-4'>{description}</h1>
                <div>
                    <div className='grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4 mb-4'>
                        {
                            menus?.slice()
                                .sort((a, b) => a.orden - b.orden)
                                .map((menu, index) => (
                                    <Link
                                        key={index}
                                        className={`flex justify-center transition ease-in-out border-t-8 rounded-lg dark:border-gray-400 border-gray-600 ${menu.nombre === params.menuname ? 'dark:bg-gray-700 bg-gray-300' : 'bg-white dark:bg-gray-800'} dark:hover:bg-gray-700 hover:bg-gray-100 hover:border-red-500 dark:hover:border-red-500`}
                                        to={`menu/${menu.nombre}`}
                                    >
                                        <div className='flex flex-col items-center py-4 px-3 gap-2 text-center'>
                                            <i className='fa-solid fa-list-check text-[40px] text-gray-600 dark:text-gray-200' />
                                            <span className='mb-1 text-md font-semibold text-gray-900 dark:text-white'>{menu.etiqueta}</span>
                                        </div>
                                    </Link>
                                ))
                        }
                    </div>
                </div>
            </div>
        </>

    )
}
