import React, { useEffect } from 'react'
import '../../index.css'

export const ModalContainer = ({ setModal, children, width }) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Escape') {
            setModal(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <div
                className={`fixed inset-0 bg-black bg-opacity-50 z-30 flex items-center justify-center max-h-screen`}
                onClick={() => {
                    setModal(false);
                }}
            >
                <div
                    className={`fixed z-20 inset-0 flex items-center justify-center`}
                >
                    <div
                        className={`w-full ${width} max-h-full overflow-auto rounded-md`}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}
