import React from 'react'
import { ModalContainer } from './ModalContainer';
import { Form, Formik } from 'formik';

import { ModalHeader } from './ModalHeader';
import { ModalFooter } from './ModalFooter';
import { ModalFormBody } from './ModalFormBody';
import { ModalFormField } from './ModalFormField';

export const ModalFunc = ({
    setModal,
    label,
    dataValues,
    buttonLabel,
    disabled,
    func
}) => {
    return (
        <ModalContainer
            setModal={setModal}
            width={dataValues.fields != null && dataValues.fields.length > 0 ? 'md:max-w-5xl' : 'md:max-w-min'}
        >
            <Formik
                initialValues={dataValues.initialValues}

                validationSchema={dataValues.fieldsValidation}

                onSubmit={async (values) => {
                    func(values)
                }}
            >
                {({ errors, isSubmitting, touched, values, submitForm, handleChange, setFieldValue }) => (
                    <Form className='relative bg-white rounded-lg shadow dark:bg-gray-700' autoComplete='off'>
                        <ModalHeader
                            setModal={setModal}
                            label={label}
                        />
                        {dataValues.fields != null && dataValues.fields.length > 0 &&
                            <ModalFormBody>
                                <ModalFormField
                                    fields={dataValues.fields}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    setValues={setFieldValue}
                                    handleChange={handleChange}
                                    calculators={dataValues.calculators}
                                    submitForm={submitForm}
                                />
                            </ModalFormBody>
                        }
                        <ModalFooter
                            buttonAccept={buttonLabel}
                            cancel={setModal}
                            disabled={disabled}
                            isSubmitting={isSubmitting}
                        />
                    </Form>
                )}
            </Formik>
        </ModalContainer>
    )
}
