import React, { useEffect } from 'react'
import '../../index.css'
import { ModalHeader } from './ModalHeader';

export const ModalPdf = ({ setModal, children, width, orientation }) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Escape') {
            setModal(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <div
                className={`fixed inset-0 bg-black bg-opacity-50 z-30 flex items-center justify-center max-h-screen`}
                onClick={() => {
                    setModal(false);
                }}
            >
                <div
                    className={`fixed z-20 inset-0 flex items-center justify-center`}
                >
                    <div
                        className={`w-[${orientation === 'landscape' ? '1200px' : '900px'}] max-h-full overflow-auto dark:bg-gray-700 bg-gray-100 rounded-md`}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <ModalHeader
                            setModal={setModal}
                            label={'Cuaderno diario'}
                        />
                        <div className='flex justify-center p-2'>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
