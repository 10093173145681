import React, { useState } from 'react'
import { TabTableSection } from '../../../../../../components/tab/TabTableSection';
import { AlmacenHistorialData } from '../../AlmacenHistorialData';
import CardInfoAlmacenProducto from '../../elementosGenerales/CardInfoAlmacenProducto';

export const AlmacenHistorials = () => {
    const [data, setData] = useState(null);
    return (
        <TabTableSection>
            {/* <div className='dark:bg-[#4a5c68] bg-[#4a5c6830] p-1 rounded-md  mb-2'> */}
            <CardInfoAlmacenProducto
                data={data}
                setData={setData}
            // setReload={setReload}
            />
            <AlmacenHistorialData
                dataCard={data}
                toList='normal'
            />
            {/* </div> */}
        </TabTableSection>
    )
}
