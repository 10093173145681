import React from 'react'
import { ListIngresosYEgresosTable } from './ListIngresosYEgresosTable'

export const IngresosYEgresos = () => {
    return (
        <div className='grid grid-cols-1 gap-2'>
            <div className='col-span-1 xl:col-span-1'>
                <ListIngresosYEgresosTable
                    globalParams={{}}
                />
            </div>
        </div>
    )
}