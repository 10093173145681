import React from 'react'
import { ListClientesTable } from './ListClientesTable'
import { Route, Routes } from 'react-router-dom'
import { ErrorPage } from '../../../error/errorPage'
import { PerfilCliente } from './perfil_cli'


export const Clientes = () => {
    return (
        <div className='grid grid-cols-1 gap-2'>
            <div className='col-span-1'>
                <ListClientesTable
                />
            </div>
            <Routes>
                <Route index element={
                    null
                } />
                <Route
                    path={'/users_cli/:user_cli_id/*'}
                    element={
                        <PerfilCliente
                        />
                    }
                />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </div>
    )
}