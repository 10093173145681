import React, { useState } from 'react'
import { TabContainer } from '../../../../../components/tab/TabContainer'
import { TabItem } from '../../../../../components/tab/TabItem'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { ListCuadernoHistorialTable } from './ListCuadernoHistorialTable'
import { ErrorPage } from '../../../../error/errorPage'
import { CobrosValues, VentaRetornableValues, DevolucionDeGarantiasValues, DevolucionDePrestamosValues, GastoValues, VentaNoRetornableValues, GarantiaDirectaValues } from '../../../../../FormSchemes/CuadernoHistorialScheme'
import { ModalPdf } from '../../../../../components/modals/ModalPdf'
import CuadernoPdf from './CuadernoPdf'
import { VerificationPathSubByString } from '../../../../../utils'
import { ListCuadernoGastoTable } from './ListCuadernoGastoTable'
import { ListCuadernoEnvasesHistorialTable } from './ListCuadernoEnvasesHistorialTable'
import { ListCuadernoCobranzaHistorialTable } from './ListCuadernoCobranzaHistorialTable'
import { GeneralUpdateValues, RegistrarEfectivoFaltanteValues, UpdateCuadernoEfectivoValues } from '../../../../../FormSchemes/CuadernoScheme'
import { ListCuadernoEfectivoTable } from './ListCuadernoEfectivoTable'
import { ListCuadernoAlmacenTable } from './ListCuadernoAlmacenTable'
import { TraspasoDeProductos } from '../../../../../FormSchemes/CuadernoTraspasoScheme'
import { ListDeudaInmediataTable } from './ListDeudaInmediataTable'
import { Callers } from '../../../../../hooks/Callers'

export const CuadernoTab = ({
    reload,
    setReload,
    dataCard,
    // globalParams
}) => {
    const {
        dispatch,
        params,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        Actions,
        Section,
        ModalForm,
    } = useGeneralParams('')

    const [generalStates, setGeneralStates] = useState({
        retMod: false,
        retOfiMod: false,
        noRetMod: false,
        noRetOfiMod: false,
        trasProdMod: false,
        gastosMod: false,
        devPrestMod: false,
        devPrestModOfi: false,
        devGarMod: false,
        devGarModOfi: false,
        cobrosMod: false,
        opeCuaMod: false,
        closeCuaMod: false,
        garDirMod: false,
        updEfecMod: false,
        faltSobrMod: false,
        cuaPdfMod: false,
    })

    const reloadTable = () => {
        Callers(dispatch)
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
    }

    return (
        <Section>
            <Actions
                buttons={[
                    {
                        icon: 'pen-to-square',
                        label: 'Vender producto retornable',
                        action: () => setGeneralStates(x => ({ ...x, retMod: true })),
                        className: 'dark:bg-blue-700 bg-blue-500 text-white',
                        disabled: !dataCard?.abierto
                    },
                    {
                        icon: 'pen-to-square',
                        label: 'Vender producto retornable (Empresas y mayoristas)',
                        action: () => setGeneralStates(x => ({ ...x, retOfiMod: true })),
                        className: 'dark:bg-blue-700 bg-blue-500 text-white',
                        disabled: !dataCard?.abierto
                    },
                    {
                        icon: 'pen-to-square',
                        label: 'Vender producto no retornable',
                        action: () => setGeneralStates(x => ({ ...x, noRetMod: true })),
                        className: 'dark:bg-cyan-700 bg-cyan-500 text-white',
                        disabled: !dataCard?.abierto
                    },
                    {
                        icon: 'pen-to-square',
                        label: 'Vender de producto no retornable (Empresas y mayoristas)',
                        action: () => setGeneralStates(x => ({ ...x, noRetOfiMod: true })),
                        className: 'dark:bg-cyan-700 bg-cyan-500 text-white',
                        disabled: !dataCard?.abierto
                    },
                    {
                        icon: 'pen-to-square',
                        label: 'Traspaso de productos',
                        action: () => setGeneralStates(x => ({ ...x, trasProdMod: true })),
                        className: 'dark:bg-sky-700 bg-sky-500 text-white',
                        disabled: !dataCard?.abierto
                    },
                    {
                        icon: 'pen-to-square',
                        label: 'Gastos',
                        action: () => setGeneralStates(x => ({ ...x, gastosMod: true })),
                        className: 'dark:bg-yellow-700 bg-yellow-500 text-white',
                        disabled: !dataCard?.abierto
                    },
                    {
                        icon: 'pen-to-square',
                        label: 'Devolución de prestamos',
                        action: () => setGeneralStates(x => ({ ...x, devPrestMod: true })),
                        className: 'dark:bg-indigo-700 bg-indigo-500 text-white',
                        disabled: !dataCard?.abierto
                    },
                    {
                        icon: 'pen-to-square',
                        label: 'Devolución de prestamos (Empresas y mayoristas)',
                        action: () => setGeneralStates(x => ({ ...x, devPrestModOfi: true })),
                        className: 'dark:bg-indigo-700 bg-indigo-500 text-white',
                        disabled: !dataCard?.abierto
                    },
                    {
                        icon: 'pen-to-square',
                        label: 'Devolución de Garantías',
                        action: () => setGeneralStates(x => ({ ...x, devGarMod: true })),
                        className: 'dark:bg-yellow-700 bg-yellow-500 text-white',
                        disabled: !dataCard?.abierto
                    },
                    {
                        icon: 'pen-to-square',
                        label: 'Devolución de Garantías (Empresas y mayoristas)',
                        action: () => setGeneralStates(x => ({ ...x, devGarModOfi: true })),
                        className: 'dark:bg-yellow-700 bg-yellow-500 text-white',
                        disabled: !dataCard?.abierto
                    },
                    {
                        icon: 'pen-to-square',
                        label: 'Cobros',
                        action: () => setGeneralStates(x => ({ ...x, cobrosMod: true })),
                        className: 'dark:bg-fuchsia-700 bg-fuchsia-500 text-white',
                        disabled: !dataCard?.abierto
                    },
                    // {
                    //     icon: 'database',
                    //     label: 'Actualizar fecha',
                    //     action: () => setUpdateAlmacenModal(true),
                    //     className: 'dark:bg-indigo-700 bg-indigo-500 text-white',
                    //     disabled: false
                    // },
                    {
                        icon: 'lock-open',
                        label: 'Abrir cuaderno',
                        action: () => setGeneralStates(x => ({ ...x, opeCuaMod: true })),
                        className: 'dark:bg-orange-700 bg-orange-500 text-white',
                        disabled: dataCard?.abierto
                    },
                    {
                        icon: 'lock',
                        label: 'Cerrar cuaderno',
                        action: () => setGeneralStates(x => ({ ...x, closeCuaMod: true })),
                        className: 'dark:bg-orange-700 bg-orange-500 text-white',
                        disabled: !dataCard?.abierto
                    },
                    // {
                    //     icon: 'folder-open',
                    //     label: 'Revisar cuaderno',
                    //     action: () => setRevisarModal(true),
                    //     className: 'dark:bg-cyan-700 bg-cyan-500 text-white',
                    //     disabled: dataCard?.abierto
                    // },
                    {
                        icon: 'pen-to-square',
                        label: 'Registrar garantía directa',
                        action: () => setGeneralStates(x => ({ ...x, garDirMod: true })),
                        className: 'dark:bg-rose-600 bg-rose-500 text-white',
                        disabled: !dataCard?.abierto
                    },
                    {
                        icon: 'money-bills',
                        label: 'Efectivo',
                        action: () => setGeneralStates(x => ({ ...x, updEfecMod: true })),
                        className: 'dark:bg-green-700 bg-green-500 text-white',
                        // disabled: dataCard?.abierto
                    },
                    {
                        icon: 'money-bill',
                        label: 'Faltante o Sobrante',
                        action: () => setGeneralStates(x => ({ ...x, faltSobrMod: true })),
                        className: 'dark:bg-red-700 bg-red-500 text-white',
                        disabled: dataCard?.abierto
                    },
                    {
                        icon: 'file-pdf',
                        label: 'Cuaderno PDF',
                        action: () => setGeneralStates(x => ({ ...x, cuaPdfMod: true })),
                        className: 'dark:bg-pink-600 bg-pink-500 text-white',
                        disabled: false
                    },
                    // {
                    //     icon: 'file-pdf',
                    //     label: 'Boleta salida PDF',
                    //     action: () => setBoletaSalidaPdfModal(true),
                    //     className: 'dark:bg-fuchsia-600 bg-fuchsia-500 text-white',
                    //     disabled: !VerificationPathSubByString('/almacen-productos')
                    // },
                    // {
                    //     icon: 'minus',
                    //     label: 'Egreso directo',
                    //     action: () => setEgresoDirectoModal(true),
                    //     className: 'dark:bg-red-700 bg-red-500'
                    // },
                ]}
            />
            <TabContainer>
                <TabItem
                    to='ventas'
                    label='Ventas'
                    active={VerificationPathSubByString('ventas')}
                />
                <TabItem
                    to='cobranzas'
                    label='Cobranzas'
                    active={VerificationPathSubByString('cobranzas')}
                />
                <TabItem
                    to='gastos'
                    label='Gastos'
                    active={VerificationPathSubByString('gastos')}
                />
                <TabItem
                    to='turriles-recogidos'
                    label='Turriles recogidos'
                    active={VerificationPathSubByString('turriles-recogidos')}
                />
                <TabItem
                    to='garantias-directas'
                    label='Garantías directas'
                    active={VerificationPathSubByString('garantias-directas')}
                />
                {/* <TabItem
                    to='traspasos'
                    label='Traspasos'
                    active={VerificationPathSubByString('traspasos')}
                />
                <TabItem
                    to='almacen-productos'
                    label='Almacén ingresos'
                    active={VerificationPathSubByString('almacen-productos')}
                /> */}
                <TabItem
                    to='cuaderno-almacen'
                    label='Almacén'
                    active={VerificationPathSubByString('cuaderno-almacen')}
                />
                <TabItem
                    to='efectivo'
                    label='Efectivo'
                    active={VerificationPathSubByString('efectivo')}
                />
            </TabContainer>
            <Routes>
                <Route index element={
                    <ListCuadernoHistorialTable
                        reload={reload}
                        setReload={setReload}
                        //
                        params={params}
                        setSelectAllChecked={setSelectAllChecked}
                        setIsChecked={setIsChecked}
                        selecteds={selecteds}
                        setSelecteds={setSelecteds}
                        selectAllChecked={selectAllChecked}
                        isChecked={isChecked}
                        dataCard={dataCard}
                    />
                } />
                <Route
                    path='ventas'
                    element={
                        <ListCuadernoHistorialTable
                            reload={reload}
                            setReload={setReload}
                            //
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            dataCard={dataCard}
                        />
                    } />


                <Route
                    path='cobranzas'
                    element={
                        <ListCuadernoCobranzaHistorialTable
                            reload={reload}
                            setReload={setReload}
                            //
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                        />
                    } />


                <Route
                    path='gastos'
                    element={
                        <ListCuadernoGastoTable
                            reload={reload}
                            setReload={setReload}
                            //
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                        />
                    } />
                <Route
                    path='turriles-recogidos'
                    element={
                        <ListCuadernoEnvasesHistorialTable
                            reload={reload}
                            setReload={setReload}
                            //
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                        />
                    } />
                <Route
                    path='garantias-directas'
                    element={
                        <ListDeudaInmediataTable
                            reload={reload}
                            setReload={setReload}
                            //
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                        />
                    } />
                <Route
                    path='cuaderno-almacen'
                    element={
                        <ListCuadernoAlmacenTable
                            reload={reload}
                            setReload={setReload}
                            //
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            dataCard={dataCard}
                        />
                    } />
                <Route
                    path='efectivo'
                    element={
                        <ListCuadernoEfectivoTable
                            reload={reload}
                            setReload={setReload}
                            //
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                        />
                    } />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
            {
                generalStates.retMod &&
                <ModalForm
                    setModal={() => setGeneralStates(x => ({ ...x, retMod: false }))}
                    label={<span>Venta de Producto Retornable en <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={VentaRetornableValues(params?.cuaderno_id, dataCard?.user_id, 'venta-retornable')}
                    urlApi={`/cuaderno_historial/venta`}
                    method={'post'}
                    call={reloadTable}
                    buttonLabel='Registrar'
                />
            }
            {
                generalStates.retOfiMod &&
                <ModalForm
                    setModal={() => setGeneralStates(x => ({ ...x, retOfiMod: false }))}
                    label={<span>Venta de Producto Retornable (Empresas y mayoristas) en <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={VentaRetornableValues(params?.cuaderno_id, dataCard?.user_id, 'venta-retornable-oficina')}
                    urlApi={`/cuaderno_historial/venta`}
                    method={'post'}
                    call={reloadTable}
                    buttonLabel='Registrar'
                />
            }
            {
                generalStates.noRetMod &&
                <ModalForm
                    setModal={() => setGeneralStates(x => ({ ...x, noRetMod: false }))}
                    label={<span>Registrar Venta de producto no retornable en <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={VentaNoRetornableValues(params?.cuaderno_id, dataCard?.user_id, 'venta-no-retornable')}
                    urlApi={`/cuaderno_historial/venta`}
                    method={'post'}
                    call={reloadTable}
                    buttonLabel='Registrar'
                />
            }
            {
                generalStates.noRetOfiMod &&
                <ModalForm
                    setModal={() => setGeneralStates(x => ({ ...x, noRetOfiMod: false }))}
                    label={<span>Registrar Entrega de Producto No Retornable (Empresas y mayoristas) en <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={VentaNoRetornableValues(params?.cuaderno_id, dataCard?.user_id, 'venta-no-retornable-oficina')}
                    urlApi={`/cuaderno_historial/venta`}
                    method={'post'}
                    call={reloadTable}
                    buttonLabel='Registrar'
                />
            }
            {
                generalStates.trasProdMod &&
                <ModalForm
                    setModal={() => setGeneralStates(x => ({ ...x, trasProdMod: false }))}
                    label={<span>Registrar Traspaso de producto en <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={TraspasoDeProductos(dataCard)}
                    urlApi={`/cuadernoTraspaso`}
                    method={'post'}
                    call={reloadTable}
                    buttonLabel='Registrar'
                />
            }
            {
                generalStates.gastosMod &&
                <ModalForm
                    setModal={() => setGeneralStates(x => ({ ...x, gastosMod: false }))}
                    label={<span>Registrar Gasto en <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={GastoValues(params?.cuaderno_id)}
                    urlApi={`/cuadernoGasto`}
                    method={'post'}
                    call={reloadTable}
                    buttonLabel='Registrar'
                />
            }
            {
                generalStates.devPrestMod &&
                <ModalForm
                    setModal={() => setGeneralStates(x => ({ ...x, devPrestMod: false }))}
                    label={<span>Devolución de Prestamos en <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={DevolucionDePrestamosValues(params?.cuaderno_id, dataCard?.user_id, '')}
                    urlApi={`/deudaEnvaseGarantiaOPrestamo`}
                    method={'post'}
                    call={reloadTable}
                    buttonLabel='Registrar'
                />
            }
            {
                generalStates.devPrestModOfi &&
                <ModalForm
                    setModal={() => setGeneralStates(x => ({ ...x, devPrestModOfi: false }))}
                    label={<span>Devolución de Prestamos (Empresas y mayoristas) en <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={DevolucionDePrestamosValues(params?.cuaderno_id, dataCard?.user_id, 'oficina')}
                    urlApi={`/deudaEnvaseGarantiaOPrestamo`}
                    method={'post'}
                    call={reloadTable}
                    buttonLabel='Registrar'
                />
            }
            {
                generalStates.devGarMod &&
                <ModalForm
                    setModal={() => setGeneralStates(x => ({ ...x, devGarMod: false }))}
                    label={<span>Devolución de garantías en <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={DevolucionDeGarantiasValues(params?.cuaderno_id, dataCard?.user_id, '')}
                    urlApi={`/deudaEnvaseGarantiaOPrestamo`}
                    method={'post'}
                    call={reloadTable}
                    buttonLabel='Registrar'
                />
            }
            {
                generalStates.devGarModOfi &&
                <ModalForm
                    setModal={() => setGeneralStates(x => ({ ...x, devGarModOfi: false }))}
                    label={<span>Devolución de garantías (Empresas y mayoristas) en <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={DevolucionDeGarantiasValues(params?.cuaderno_id, dataCard?.user_id, 'oficina')}
                    urlApi={`/deudaEnvaseGarantiaOPrestamo`}
                    method={'post'}
                    call={reloadTable}
                    buttonLabel='Registrar'
                />
            }
            {
                generalStates.cobrosMod &&
                <ModalForm
                    setModal={() => setGeneralStates(x => ({ ...x, cobrosMod: false }))}
                    label={<span>Cobros en <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={CobrosValues(params?.cuaderno_id, dataCard?.user_id)}
                    urlApi={`/deuda`}
                    method={'post'}
                    call={reloadTable}
                    buttonLabel='Registrar'
                />
            }
            {
                generalStates.opeCuaMod &&
                <ModalForm
                    setModal={() => setGeneralStates(x => ({ ...x, opeCuaMod: false }))}
                    label={<span>Abrir cuaderno <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={GeneralUpdateValues(params?.cuaderno_id, false)}
                    urlApi={`/cuaderno/${params.cuaderno_id}/re-open`}
                    method={'put'}
                    call={reloadTable}
                    buttonLabel='Abrir'
                />
            }
            {
                generalStates.closeCuaMod &&
                <ModalForm
                    setModal={() => setGeneralStates(x => ({ ...x, closeCuaMod: false }))}
                    label={<span>Cerrar cuaderno <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={GeneralUpdateValues(params?.cuaderno_id, false)}
                    urlApi={`/cuaderno/${params.cuaderno_id}/close`}
                    method={'put'}
                    call={reloadTable}
                    buttonLabel='Cerrar'
                />
            }
            {
                generalStates.updEfecMod &&
                <ModalForm
                    setModal={() => setGeneralStates(x => ({ ...x, updEfecMod: false }))}
                    label={<span>Efectivo del cuaderno <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={UpdateCuadernoEfectivoValues(dataCard)}
                    urlApi={`/cuaderno/efectivo`}
                    method={'post'}
                    call={reloadTable}
                    buttonLabel='Actualizar'
                />
            }
            {
                generalStates.faltSobrMod &&
                <ModalForm
                    setModal={() => setGeneralStates(x => ({ ...x, faltSobrMod: false }))}
                    label={<span>Registrar faltante o sobrante del cuaderno <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={RegistrarEfectivoFaltanteValues(dataCard)}
                    urlApi={`/cuadernoEfectivo/faltanteSobrante`}
                    method={'post'}
                    call={reloadTable}
                    buttonLabel='Actualizar'
                />
            }
            {
                generalStates.cuaPdfMod &&
                <ModalPdf
                    setModal={() => setGeneralStates(x => ({ ...x, cuaPdfMod: false }))}
                    width={'md:max-w-5xl'}
                >
                    <CuadernoPdf />
                </ModalPdf>
            }
            {
                generalStates.garDirMod &&
                <ModalForm
                    setModal={() => setGeneralStates(x => ({ ...x, garDirMod: false }))}
                    label={<span>Registrar Garantía directa en <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={GarantiaDirectaValues(params?.cuaderno_id, dataCard?.user_id)}
                    urlApi={`/cuadernoHistorial/garantiaDirecta`}
                    method={'post'}
                    call={reloadTable}
                    buttonLabel='Registrar'
                />
            }
        </Section>
    )
}