import * as dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import es from 'date-fns/locale/es';
import 'react-day-picker/src/style.css';

const css = `
  .my-selected:not([disabled]) { 
    font-weight: bold; 
    background: #f1c40f;
    color: black;
  }
  .my-today { 
    font-weight: bold;
    font-size: 110%; 
    color: red;
}
.rdp-day:hover {
    color: black;
    background: red;
  }
  .days{
    background: red
  }
`;

export const RangeDate = ({ setPaginate, selectedDay, setSelectedDay }) => {
    const [view, setView] = useState(false);

    useEffect(() => {
        setPaginate((a) => ({
            ...a,
            initial:
                selectedDay !== undefined && selectedDay
                    ? selectedDay.from !== undefined && selectedDay.from
                        ? dayjs(selectedDay.from).format('YYYY-MM-DDTHH:mm:ss')
                        : ''
                    : '',
            final:
                selectedDay !== undefined && selectedDay
                    ? selectedDay.to !== undefined && selectedDay.to
                        ? dayjs(selectedDay.to).format('YYYY-MM-DDTHH:mm:ss')
                        : ''
                    : '',
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDay]);

    const handleReset = () => {
        setSelectedDay(undefined)
        // setView(false)
    }

    return (
        <div className='static'>
            <div
                className={`${selectedDay ? 'bg-white dark:bg-gray-700 dark:text-gray-100' : 'dark:text-gray-400'} border border-gray-300 dark:border-gray-700 rounded-lg hover:text-black selected portrait:w-[100%] portrait:m-0`}
                onClick={() => setView(!view)}
            >
                <div className={`py-1 px-2 text-sm cursor-pointer`}>
                    <i className={`fa fa-calendar-days mr-1 text-gray-500 dark:text-gray-400`} />
                    {selectedDay === undefined && !selectedDay ? (
                        'Desde - Hasta'
                    ) : (
                        <>
                            {selectedDay.from
                                ? dayjs(selectedDay.from).format('DD/MM/YYYY')
                                : ''}
                            {selectedDay.to ? (' - ' + dayjs(selectedDay.to).format('DD/MM/YYYY')) : ''}
                        </>
                    )}
                </div>
            </div>
            {view && (
                <>
                    <div
                        onClick={() => setView(!view)}
                        className='fixed z-10 w-screen h-screen top-0 left-0'
                    ></div>
                    <style>{css}</style>
                    <div className='absolute z-10 bg-white text-xs font-bold dark:bg-gray-700 dark:text-gray-300 shadow-lg w-fit mt-1 p-0 rounded-md'>
                        <div className='px-2 text-right'>
                            <button onClick={handleReset} className='px-4 py-2 mt-2 text-gray-800 bg-gray-300 dark:bg-gray-600 dark:hover:bg-gray-800 hover:bg-yellow-200 dark:text-gray-300 rounded-md'>
                                Borrar selección
                            </button>
                        </div>
                        <DayPicker
                            locale={es}
                            mode='range'
                            selected={selectedDay}
                            onSelect={setSelectedDay}
                            className='custom-day-picker'
                            modifiersClassNames={{

                                selected: 'my-selected',
                                today: 'my-today',
                                outside: 'days'
                            }}
                            modifiersStyles={{
                                disabled: { fontSize: '75%' }
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
