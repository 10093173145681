import React, { useState } from 'react'
import CardInfo from '../../cuadernos/cuaderno_historial/CardInfo';
import { TabTableSection } from '../../../../../components/tab/TabTableSection';
import { CuadernoTab } from './CuadernoTab';


export const CuadernoMovimientos = () => {
    const [reload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <TabTableSection>
            <CardInfo
                reload={reload}
                data={data}
                setData={setData}
                globalParams={{}}
            />
            <CuadernoTab
                dataCard={data}
            />
        </TabTableSection>
    )
}
