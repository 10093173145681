import * as Yup from 'yup';

export const RegistroDeudaDirectaValues = () => {
    return {
        initialValues: {
            saldo: 0,
            user_id: '',
            encargado_id: '',
            producto_id: '',
            tipo: 'Deuda directa',
            fecha_estimada_cobro: '',
            obs: ''
        },
        fieldsValidation: Yup.object().shape({
            // nombre: Yup.string()
            //     .min(4, 'Mínimo 4 caracteres!')
            //     .max(50, 'Máximo 40 caracteres!')
            //     .required('Campo requerido'),
        }),
        fields: [
            // {
            //     label: 'Seleccionar deudor',
            //     name: 'user_id',
            //     type: 'selectTable',
            //     urlApi: 'users/pag',
            //     required: false,
            //     noApi: false,
            //     tableParams: {
            //         filters: [
            //             {
            //                 label: '',
            //                 value: ''
            //             }
            //         ],
            //         headers:
            //             [
            //                 {
            //                     label: 'Código',
            //                     columns: ['codigo']
            //                 },
            //                 {
            //                     label: 'Nombre',
            //                     columns: ['nombres','apellido_paterno','apellido_materno']
            //                 },
            //             ]
            //     }
            // },
            {
                label: 'Responsable',
                name: 'encargado_id',
                type: 'selectSearch',
                urlApi: '/users/empleados',
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'Empleado...',
                autoFocus: false,
                highlight: 'bg-gray-600 dark:bg-gray-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Deudor',
                name: 'user_id',
                type: 'selectSearch',
                urlApi: '/users/clientes',
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'Cliente...',
                autoFocus: false,
                highlight: 'bg-gray-600 dark:bg-gray-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Seleccione producto de almacén',
                name: 'producto_id',
                type: 'subSelectDinamicSearch',
                param: 'encargado_id',
                urlApi: `/user/{param}/almacenes/retornable`,
                required: true,
                labelDescription: 'etiqueta',
                labelId: 'producto_id',
                placeholder: 'Item...',
                autoFocus: false,
                // showValidator: true
            },
            {
                label: 'Saldo',
                name: 'saldo',
                type: 'groupnumber',
                subData: 'Bs.',
                required: true,
                disabled: false,
                highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Fecha estimada de cobro',
                name: 'fecha_estimada_cobro',
                type: 'date',
                required: false,
                disabled: false,
                highlight: 'bg-gray-600 dark:bg-gray-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                type: 'divider'
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const DeleteValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}