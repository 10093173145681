import { useState } from 'react'

const useThemeHandling = () => {
    const [nav, setNav] = useState(localStorage?.getItem('navigation'))

    // useEffect(() => {
    //     if (localStorage?.getItem('navigation') === 'deploy') {
    //         localStorage.removeItem('navigation')
    //     }
    //     else {
    //         localStorage.setItem('navigation', 'deploy')
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const toggleButton = () => {
        switch (nav) {
            case 'deploy':
                setNav('')
                localStorage.removeItem('navigation')
                break;
            default:
                setNav('deploy')
                localStorage.setItem('navigation', 'deploy')
                break;
        }
    }
    return {
        nav,
        toggleButton
    }
}

export default useThemeHandling