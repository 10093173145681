import * as Yup from 'yup'
import { DecimalValidation, DecimalValidationMoreThanZero, ToOneDecimal, ToTwoDecimal } from '../utils/dataValidations'

export const CreateValues = (efectivo_id, ingreso) => {
    return {
        initialValues: {
            efectivo_id: efectivo_id,
            monto: 0,
            billetes: 0,
            monedas: 0,
            dolares_en_bs: 0,
            ingreso: ingreso,

        },
        fieldsValidation: Yup.object().shape({
            monto: DecimalValidationMoreThanZero,
            billetes: DecimalValidation,
            monedas: DecimalValidation,
            dolares_en_bs: DecimalValidation
        }),
        fields: [
            {
                label: 'Monto',
                name: 'monto',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: false,
                disabled: true,
                ChangeCalculator: (values, setValues, e) => {
                    const monto = e.target.valueAsNumber || 0
                    setValues('billetes', monto)
                    setValues('monedas', 0)
                    setValues('dolares_en_bs', 0)
                }
            },
            {
                label: 'Billetes',
                name: 'billetes',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: false,
                ChangeCalculator: (values, setValues, e) => {
                    const billetes = e.target.valueAsNumber || 0
                    const monedas = values.monedas || 0
                    const dolares_en_bs = values.dolares_en_bs || 0
                    const resultado = ToOneDecimal(billetes + monedas + dolares_en_bs)
                    setValues('monto', resultado)
                }
            },
            {
                label: 'Monedas',
                name: 'monedas',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: false,
                ChangeCalculator: (values, setValues, e) => {
                    const monedas = e.target.valueAsNumber || 0
                    const billetes = values.billetes || 0
                    const dolares_en_bs = values.dolares_en_bs || 0
                    const resultado = ToOneDecimal(billetes + monedas + dolares_en_bs)
                    setValues('monto', resultado)
                }
            },
            {
                label: 'Dolares en Bs.',
                name: 'dolares_en_bs',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: false,
                ChangeCalculator: (values, setValues, e) => {
                    const dolares_en_bs = e.target.valueAsNumber || 0
                    const billetes = values.billetes || 0
                    const monedas = values.monedas || 0
                    const resultado = ToOneDecimal(billetes + monedas + dolares_en_bs)
                    setValues('monto', resultado)
                }
            }
        ],
    }
}

export const ReajusteDeEfectivoValues = (dataCard, ingreso) => {
    let billetes = parseFloat(dataCard.billetes_total)
    let monedas = parseFloat(dataCard.monedas_total)
    let dolares = parseFloat(dataCard.dolares_total)
    let totalAmount = billetes + monedas + dolares
    return {
        initialValues: {
            efectivo_id: dataCard.id,
            monto_total: parseFloat(dataCard?.monto_total) || 0,
            billetes: totalAmount === 0 ? parseFloat(dataCard?.monto_total) : parseFloat(dataCard.billetes_total),
            monedas: parseFloat(dataCard?.monedas_total) || 0,
            dolares: parseFloat(dataCard?.dolares_total) || 0,
            tipo_cambio: parseFloat(dataCard?.tipo_cambio_total) || 6.96,
            dolares_en_bs: parseFloat(dataCard?.dolares_en_bs_total) || 0,
            obs: dataCard.obs || '',
            ingreso: ingreso,

        },
        fieldsValidation: Yup.object().shape({
            // monto: DecimalValidationMoreThanZero,
            // billetes: DecimalValidation,
            // monedas: DecimalValidation,
            // dolares_en_bs: DecimalValidation
        }),
        fields: [
            {
                label: 'Monto total',
                name: 'monto_total',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                highlight: 'bg-yellow-600 dark:bg-yellow-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Dolares en Bs.',
                name: 'dolares_en_bs',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                highlight: 'bg-yellow-600 dark:bg-yellow-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Billetes',
                name: 'billetes',
                type: 'groupnumberCalculator',
                subData: 'Sus.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const billetes = e.target.valueAsNumber || 0
                    const monto_total = values.monto_total || 0
                    const monedas = ToTwoDecimal(monto_total - billetes)
                    setValues('dolares', 0)
                    setValues('dolares_en_bs', 0)
                    setValues('monedas', monedas)
                },
            },
            {
                label: 'Monedas',
                name: 'monedas',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const monedas = e.target.valueAsNumber || 0
                    const monto_total = values.monto_total || 0
                    const billetes = ToTwoDecimal(monto_total - monedas)
                    setValues('dolares', 0)
                    setValues('dolares_en_bs', 0)
                    setValues('billetes', billetes)
                },
            },
            {
                label: 'Dolares',
                name: 'dolares',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const dolares = e.target.valueAsNumber || 0
                    const tipo_cambio = values.tipo_cambio || 0
                    const monto_total = values.monto_total || 0
                    const monedas = values.monedas || 0
                    const dolares_en_bs = dolares * tipo_cambio
                    const billetestotal = ToTwoDecimal(monto_total - (dolares_en_bs + monedas))

                    setValues('dolares_en_bs', dolares_en_bs)
                    setValues('billetes', billetestotal)
                },
            },
            {
                label: 'Tipo de cambio',
                name: 'tipo_cambio',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const tipo_cambio = e.target.valueAsNumber || 0
                    const dolares = values.dolares || 0
                    const monto_total = values.monto_total || 0
                    const monedas = values.monedas || 0
                    const dolares_en_bs = dolares * tipo_cambio
                    const billetestotal = ToTwoDecimal(monto_total - (dolares_en_bs + monedas))

                    setValues('dolares_en_bs', dolares_en_bs)
                    setValues('billetes', billetestotal)
                },
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ],
    }
}

export const IngresoCuadernoValues = (efectivo_id, cuaderno_id) => {
    return {
        initialValues: {
            efectivo_id: efectivo_id,
            cuaderno_id: cuaderno_id,
            recibo: '',
            obs: '',
            type: 'Ingreso de cuaderno'

        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: [
            {
                label: 'Seleccione un cuaderno',
                name: 'cuaderno_id',
                type: 'cuadernoEfectivo',
                urlApi: 'cuadernos/cerrados',
                labelDescription: 'etiqueta',
                placeholder: 'Cuaderno',
                autoFocus: false,
                selectedId: cuaderno_id,
                // subData: 'Bs.',
                // required: false,
                // ChangeCalculator: (values, setValues, e) => {
                //     const monto = e.target.valueAsNumber || 0
                //     setValues('billetes', monto)
                //     setValues('monedas', 0)
                //     setValues('dolares_en_bs', 0)
                // }
            },
            {
                label: 'Recibo',
                name: 'recibo',
                type: 'text',
                required: false,
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
            },            
        ],
    }
}