import React, { useEffect } from 'react'
import { MinimalActionButton } from '../../../../../components/form/minimalActionButton'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { DefaultParamsForRecall } from '../../../../../utils/defaulStates'
import { Callers } from '../../../../../hooks/Callers'
import { useSelector } from 'react-redux'
import { formatDateWithTime } from '../../../../../utils/dateFormat'

export const ListIngresoCuadernoEfectivoHistorialTable = ({
    params,
    setSelectAllChecked,
    setIsChecked,
    selecteds,
    setSelecteds,
    selectAllChecked,
    isChecked,
}) => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        stateData, setStateData,
        requestAuthPaginate,
        TableContainer,
        Paginator,
        // formatFilters,
        Searcher,
        // FilterSelect,
        RangeDate,
        TableSection,
        ActionSection,
    } = useGeneralParams()
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)
    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/efectivo/cuaderno/${params.cuaderno_id}/historial/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'tipo' }
        })
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), params.efectivo_id, reloadSubTable]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), reloadSubTable]);

    const reloadTable = () => {
        Callers(dispatch)
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
    }

    return (
        <>
            <ActionSection>
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Tipo',
                            value: 'tipo'
                        },
                        {
                            label: 'Producto',
                            value: '$Producto.nombre$'
                        }
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    {/* <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: 'Abiertos y cerrados',
                                value: ''
                            },
                            {
                                label: 'Activo',
                                value: 'activo'
                            },
                            {
                                label: 'Inactivo',
                                value: 'inactivo'
                            }
                        ]}
                    /> */}
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Entidad',
                            columns: ['mainEfectivoNombre', 'nombre', 'encargado', 'producto_nombre']
                        },
                        {
                            label: 'Origen / Destino',
                            columns: ['user_nombres', 'user_apellidos', 'proveedor_encargado']
                        },
                        // {
                        //     label: 'Monto anterior Bs.',
                        //     columns: ['monto_anterior'],
                        //     numberValue: true,
                        // },
                        {
                            label: 'Monto Bs.',
                            columns: ['monto'],
                            numberValue: true,
                        },
                        {
                            label: 'Monto actual Bs.',
                            columns: ['monto_actual'],
                            numberValue: true,
                        },
                        // {
                        //     label: 'Billetes Bs.',
                        //     columns: ['billetes'],
                        //     numberValue: true,
                        // },
                        // {
                        //     label: 'Monedas Bs.',
                        //     columns: ['monedas'],
                        //     numberValue: true,
                        // },
                        // {
                        //     label: 'Dolares en Bs.',
                        //     columns: ['dolares_en_bs'],
                        //     numberValue: true,
                        // },
                        {
                            label: 'ingreso / egreso',
                            columns: ['ingreso'],
                            booleanState: true,
                            booleanOptions: ['ingreso', 'egreso']
                        },
                        {
                            label: 'CÓDIGO DE TRANSACCIÓN',
                            columns: ['codigo_transaccion', 'tipo'],
                        },
                        // {
                        //     label: 'CONFIRMACIÓN',
                        //     columns: ['verificado'],
                        //     boolean: true,
                        //     booleanOptions: ['Confirmado', 'Pendiente'],
                        //     booleanIconTrue: 'fa-check',
                        //     booleanIconFalse: 'fa-xmark'
                        // },
                        {
                            label: 'fecha de registro',
                            columns: ['createdAt'],
                            transform: true,
                            func: formatDateWithTime
                        },
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
        </>
    )
}