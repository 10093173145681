import React, { useEffect, useState } from 'react'
import { requestAuth } from '../../../http/httpRequest'
import { toastOn } from '../../../store/slices/toast'
import { useDispatch } from 'react-redux'
import { TagsStepCompraDictionary } from '../../../utils/dictionary'
import { Loader, TitleFormData } from './aditionalComponents'
import { formatDateNumberWithTime } from '../../../utils/dateFormat'

export const Step6 = ({ generalData, setMainRecall, setReload, stepNumber, stepsPermissions }) => {
  const dispatch = useDispatch()
  const [currentData, setCurrentData] = useState(null)
  const [loading, setLoading] = useState(true)

  const getData = async () => {
    await requestAuth(
      'get',
      `compra/${generalData?.id}/paso/6`,
      null
    )
      .then((response) => {
        setCurrentData(response.data)
        setLoading(false)
      }
      )
      .catch(error => {
        dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las casillas.' }))
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <TitleFormData
        title={TagsStepCompraDictionary[stepNumber]}
      />
      {
        loading ?
          <Loader />
          :
          <div>
            <div className='p-2 grid grid-cols-12'>
              <ListItem
                label={'Proveedor:'}
                text={currentData.almacen_proveedor_nombre}
              />
              <ListItem
                label={'Transportista:'}
                text={currentData.transportista_nombre}
              />
              {
                stepsPermissions?.viewSteps?.includes(6) &&
                <ListItem
                  label={'Monto Bs.:'}
                  text={currentData.monto?.toLocaleString('es-BO', { minimumFractionDigits: 2 })}
                />
              }
              <ListItem
                label={'Fecha de solicitud:'}
                text={formatDateNumberWithTime(currentData.fecha_solicitud)}
              />
              <ListItem
                label={'Fecha de confirmación de compra:'}
                text={formatDateNumberWithTime(currentData.fecha_confirmacion_compra)}
              />
              <ListItem
                label={'Fecha de confirmación de salida:'}
                text={formatDateNumberWithTime(currentData.fecha_confirmacion_salida)}
              />
              <ListItem
                label={'Fecha de conclusión:'}
                text={formatDateNumberWithTime(currentData.fecha_fin)}
              />
            </div>
            <h2 className='mb-2 font-semibold tracking-normal text-gray-900 dark:text-white'>Retorno de productos y envases</h2>
            <div className='grid grid-cols-2 sm:grid-cols-4 gap-3'>
              {
                currentData?.retornoMap?.map((retorno, index) => (
                  <MiniCardItem
                    key={index}
                    cantidad={retorno.cantidad}
                    text={retorno.etiqueta}
                  />
                ))
              }
            </div>
          </div>
      }
    </>
  )
}

const ListItem = ({ text, label }) => {
  return (
    <div className='col-span-12 grid grid-cols-4 text-sm'>
      <div className='col-span-4 sm:col-span-1 flex space-x-2 items-center'>
        <i className='fa-solid fa-check-circle text-green-500' />
        <span className='dark:text-gray-200'>
          {label}
        </span>
      </div>
      <div className='text-sm col-span-4 sm:col-span-3 dark:text-gray-200'>{text}</div>
    </div>
  )
}

const MiniCardItem = ({ cantidad, text }) => {
  return (
    <div className='text-center border border-t-2 border-r-2 border-b-2 border-l-2 border-gray-300 rounded-xl p-1'>
      <div className='text-2xl font-bold text-gray-600 dark:text-gray-300'>{cantidad}</div>
      <div className='text-gray-600 dark:text-gray-300 font-semibold'>{text}</div>
    </div>
  )
}