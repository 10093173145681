import * as Yup from 'yup';

export const RegistroDeudaEnvaseInmediataValues = (metodo_intercambio) => {
    return {
        initialValues: {
            saldo: 0,
            user_id: '',
            encargado_id: '',
            almacen_producto_id: '',
            metodo_intercambio: metodo_intercambio,
            metodo_pago_garantia: 'efectivo',
            monto_garantia: 0,
            fecha_limite: '',
        },
        fields: [
            {
                label: 'Responsable',
                name: 'encargado_id',
                type: 'selectSearch',
                urlApi: '/users/empleados',
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'Empleado...',
                autoFocus: false,
                highlight: 'bg-gray-600 dark:bg-gray-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Deudor',
                name: 'user_id',
                type: 'selectSearch',
                urlApi: '/users/clientes',
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'Cliente...',
                autoFocus: false,
                highlight: 'bg-gray-600 dark:bg-gray-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Seleccione producto de almacén',
                name: 'almacen_producto_id',
                type: 'subSelectDinamicSearch',
                param: 'encargado_id',
                urlApi: `/user/{param}/almacenes/vacios`,
                required: true,
                labelDescription: 'etiqueta',
                labelId: 'id',
                placeholder: 'Item...',
                autoFocus: false,
                // showValidator: true
            },
            {
                label: 'Cantidad envases',
                name: 'saldo',
                type: 'number',
                subData: null,
                required: true,
            },
            ...metodo_intercambio === 'garantía' ?
                [
                    {
                        label: 'Método de pago de garantía',
                        name: 'metodo_pago_garantia',
                        type: 'select',
                        required: true,
                        disabled: false,
                        options: [
                            {
                                label: 'Efectivo',
                                value: 'efectivo'
                            },
                            {
                                label: 'Transferencia',
                                value: 'transferencia'
                            },
                            {
                                label: 'Cheque',
                                value: 'cheque'
                            }
                        ],
                        highlight: 'bg-indigo-600 dark:bg-indigo-500 bg-opacity-25 dark:bg-opacity-20'
                    },
                    {
                        label: 'Garantía',
                        name: 'monto_garantia',
                        type: 'number',
                        subData: 'Bs.',
                        required: true,
                        disabled: false,
                        highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
                    }
                ] :
                [{
                    label: 'Fecha estimada de cobro',
                    name: 'fecha_limite',
                    type: 'date',
                    required: false,
                    disabled: false,
                    highlight: 'bg-gray-600 dark:bg-gray-500 bg-opacity-25 dark:bg-opacity-20'
                }],
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const DeleteValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}