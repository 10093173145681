import React, { useEffect } from 'react'

import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { CreateValues, UpdateValues } from '../../../../FormSchemes/UserScheme'
import { MinimalActionButton } from '../../../../components/form/minimalActionButton'
import { requestAuth } from '../../../../http/httpRequest'
import { loginOn, loginOut, removeToken, saveUserDetail } from '../../../../store/slices/auth'
import { FilterSelectAsync } from '../../../../components/form/FilterSelect'
import { DefaultParamsForRecall } from '../../../../utils/defaulStates'
import { useSelector } from 'react-redux'
import { Callers } from '../../../../hooks/Callers'

export const ListUserTable = () => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        createModal, setCreateModal,
        updateModal, setUpdateModal,
        currentData, setCurrentData,
        // imports
        requestAuthPaginate,
        TableContainer,
        formatDateWithTime,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        // RangeDate,
        Actions,
        TableSection,
        ActionSection,
        Section,
        ModalForm,
        UpdateValuesModal,
        navigate,
    } = useGeneralParams()
    const reloadMainTable = useSelector(state => state.login.reloadMainTable)
    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/users/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'nombres' }
        })
    }
    useEffect(() => {
        getDataPaginate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, paginate.rolname, reloadMainTable]);

    // useEffect(() => {
    //     console.log(selecteds)
    // }, [selecteds]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, paginate.rolname, reloadMainTable]);

    const reloadTable = () => {
        Callers(dispatch)
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
    }

    const updateRecall = async () => {
        getDataPaginate()

        await requestAuth(
            'get',
            `/user/Detail`,
            null
        )
            .then((response) => {
                dispatch(loginOn())
                dispatch(saveUserDetail(response.data))
            }
            )
            .catch(() => {
                dispatch(loginOut())
                dispatch(removeToken())
                navigate('/login')
                dispatch(saveUserDetail(null))
                localStorage.removeItem('token')
            })
    }
    return (
        <Section>
            <ActionSection>
                <Actions
                    buttons={[
                        {
                            icon: 'add',
                            label: 'Crear',
                            action: () => setCreateModal(true)
                        },
                    ]}
                />
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Nombre',
                            value: 'nombres'
                        },
                        {
                            label: 'Código',
                            value: 'codigo'
                        }
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    {/* <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    /> */}
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: 'Estado',
                                value: ''
                            },
                            {
                                label: 'Activo',
                                value: 'activo'
                            },
                            {
                                label: 'Inactivo',
                                value: 'inactivo'
                            }
                        ]}
                    />
                    <FilterSelectAsync
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='rolname'
                        urlApi='/roles'
                        indexes={{
                            labelIndex: 'etiqueta',
                            valueIndex: 'id'
                        }}
                        defaultLabel='Roles'
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Código',
                            columns: ['codigo']
                        },
                        {
                            label: 'Nombre',
                            columns: ['nombres', 'apellido_paterno:apellido_materno'],
                            icon: 'fa-solid fa-user-circle text-[30px] text-cyan-500',
                            className: 'flex items-center',
                            tag: true,
                            // stickyL: true
                        },
                        {
                            label: 'Estado',
                            columns: ['estado'],
                            tag: true
                        },
                        // {
                        //     label: 'Roles',
                        //     type: 'tagList',
                        //     tagList: 'rols',
                        //     tagLabel: 'etiqueta'
                        // },
                        {
                            label: 'Roles',
                            type: 'listData',
                            tagList: 'rols',
                            tagLabel: 'etiqueta'
                        },
                        {
                            label: 'Fecha de creación',
                            columns: ['createdAt'],
                            transform: true,
                            func: formatDateWithTime
                        },
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'edit',
                                    icon: 'fa-edit',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setUpdateModal),
                                },
                                {
                                    type: 'delete',
                                    icon: 'fa-trash',
                                    // action: (id) => deleteUser(id),
                                    reference: 'id'
                                }
                            ],
                            // stickyR: true
                        },
                    ]}
                    data={data.data}
                    checkList={false}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {
                createModal &&
                <ModalForm
                    setModal={setCreateModal}
                    label='Crear usuario'
                    dataValues={CreateValues()}
                    urlApi={'/user'}
                    method={'post'}
                    call={reloadTable}
                    buttonLabel='Registrar'
                />
            }
            {
                updateModal &&
                <ModalForm
                    setModal={setUpdateModal}
                    label='Editar usuario'
                    dataValues={UpdateValues(currentData)}
                    urlApi={'/user'}
                    method={'put'}
                    call={updateRecall}
                    buttonLabel='Registrar'
                />
            }
        </Section>
    )
}