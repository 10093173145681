import React from 'react'
import { useNavigate } from 'react-router-dom'

export const TabTableSection = ({ children }) => {
    const navigate = useNavigate()
    return (
        <div className=''>
            <div className='flex justify-end bg-gray-600 dark:bg-gray-800 p-1 rounded-t-md'>
                <button onClick={() => navigate('..')} className='text-gray-200 dark:text-gray-300 hover:text-gray-100 hover:dark:text-gray-200 bg-red-500 dark:bg-red-600 rounded-md' title='Cerrar ventana'>
                    <i className='fa-solid fa-close text-xl bg-transparent dark:bg-transparent px-2 py-0 rounded-t-md' />
                </button>
            </div>
            <div className='col-span-1 border-l-4 border-r-4 border-b-4 border-gray-600 dark:border-gray-800 pt-1 px-1 rounded-b-md'>
                {children}
            </div>
        </div>
    )
}