import React, { useEffect, useState } from 'react'

import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { CreateValues, DeleteValues, UpdateValues } from '../../../../../FormSchemes/EstadoScheme'
import { MinimalActionButton } from '../../../../../components/form/minimalActionButton'
import { ModalSample } from '../../../../../components/modals/ModalSample'
import { ListSubEstados } from './ListSubEstados'
import { DefaultParamsForRecall } from '../../../../../utils/defaulStates'
import { useSelector } from 'react-redux'
import { Callers } from '../../../../../hooks/Callers'

export const ListSubEstadoTable = () => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        createModal, setCreateModal,
        updateModal, setUpdateModal,
        deleteModal, setDeleteModal,
        currentData, setCurrentData,
        // imports
        requestAuthPaginate,
        TableContainer,
        Paginator,
        Searcher,
        Actions,
        TableSection,
        ActionSection,
        Section,
        ModalForm,
        UpdateValuesModal,
        params

    } = useGeneralParams()
    const [ShowListSubEstadosModal, setShowListSubEstadosModal] = useState(false)
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)
    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/subEstados/${params?.estado_id}/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'nombre' }
        })
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.filters, params.estado_id, reloadSubTable]);

    // useEffect(() => {
    //     console.log(selecteds)
    // }, [selecteds]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.filters, params.estado_id, reloadSubTable]);

    const reloadTable = () => {
        Callers(dispatch)
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
    }

    return (
        <Section>
            <ActionSection>
                <Actions
                    buttons={[
                        {
                            icon: 'add',
                            label: 'Añadir elemento',
                            action: () => setCreateModal(true)
                        },
                    ]}
                />
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Nombre',
                            value: 'nombre'
                        }
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Nombre',
                            columns: ['nombre']
                        },
                        {
                            label: 'Descripción',
                            columns: ['descripcion']
                        },
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'edit',
                                    icon: 'fa-edit',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setUpdateModal),
                                },
                                {
                                    type: 'cyan',
                                    icon: 'fa-list',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setShowListSubEstadosModal),
                                },
                                // {
                                //     type: 'yellow',
                                //     icon: 'fa-list',
                                //     action: (data) => {
                                //         redirect(`items/${data.id}`)
                                //     },
                                //     reference: 'id',
                                //     tooltipText: 'Ver historial',
                                //     redirect: true
                                // },
                                {
                                    type: 'delete',
                                    icon: 'fa-trash',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                    reference: 'id'
                                }
                            ],
                            // stickyR: true
                        },
                    ]}
                    data={data.data}
                    checkList={false}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {
                createModal &&
                <ModalForm
                    setModal={setCreateModal}
                    label='Crear estado'
                    dataValues={CreateValues(params.estado_id)}
                    urlApi={'/estado'}
                    method={'post'}
                    call={reloadTable}
                    buttonLabel='Registrar'
                />
            }
            {
                updateModal &&
                <ModalForm
                    setModal={setUpdateModal}
                    label='Editar estado'
                    dataValues={UpdateValues(currentData)}
                    urlApi={'/estado'}
                    method={'put'}
                    call={reloadTable}
                    buttonLabel='Editar'
                />
            }
            {
                deleteModal &&
                <ModalForm
                    setModal={setDeleteModal}
                    label='Eliminar estado'
                    dataValues={DeleteValues(currentData)}
                    urlApi={`/estado/${currentData.id}`}
                    method={'delete'}
                    call={reloadTable}
                    buttonLabel='Eliminar'
                />
            }
            {
                ShowListSubEstadosModal &&
                <ModalSample
                    setModal={setShowListSubEstadosModal}
                    label={<span>Lista de elementos de <span className='text-green-600 dark:text-green-400'>{currentData?.nombre}</span></span>}
                    call={reloadTable}
                    width='md:max-w-2xl m-auto'
                    component={
                        <ListSubEstados
                            setModal={setDeleteModal}
                            call={reloadTable}
                            selectedData={currentData}
                        />
                    }
                />
            }
        </Section>
    )
}