import React, { useEffect, useState } from 'react'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { MinimalActionButton } from '../../../../../components/form/minimalActionButton'
import { DefaultParamsForRecall } from '../../../../../utils/defaulStates'
import { ModalForm } from '../../../../../components/modals/ModalForm'
import { DeleteValues } from '../../../../../FormSchemes/CuadernoHistorialScheme'
import { UpdateValuesModal } from '../../../../../FormSchemes/GeneralFunctions'
import { useSelector } from 'react-redux'
import { Callers } from '../../../../../hooks/Callers'

export const ListDeudaInmediataTable = ({
    params,
    setSelectAllChecked,
    setIsChecked,
    selecteds,
    setSelecteds,
    selectAllChecked,
    isChecked,
}) => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        stateData, setStateData,
        // imports
        requestAuthPaginate,
        TableContainer,
        formatDateWithTime,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        RangeDate,
        TableSection,
        ActionSection,
    } = useGeneralParams()
    const [DeleteDeudaInmediataModal, setDeleteDeudaInmediataModal] = useState(false)
    const [CurrentData, setCurrentData] = useState(false)
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)

    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/deudaEnvases/todos/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'nombres' }
        })
    }

    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.tipo_accion, params.cuaderno_id, reloadSubTable]);

    // useEffect(() => {
    //     console.log(selecteds)
    // }, [selecteds]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.tipo_accion, reloadSubTable]);

    const reloadTable = () => {
        Callers(dispatch)
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
    }
    return (
        <>
            <ActionSection>
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    width='6/12'
                    options={[
                        {
                            label: 'Nombre',
                            value: 'nombres'
                        },
                        {
                            label: 'Código',
                            value: 'codigo'
                        }
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='tipo_accion'
                        options={[
                            {
                                label: 'Tipo de acción',
                                value: ''
                            },
                            {
                                label: 'Pendientes',
                                value: 'pendientes'
                            },
                            {
                                label: 'Pagadas',
                                value: 'pagadas'
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Encargado',
                            columns: ['envaseEncargadoCodigo', 'envaseEncargadoDetalles'],
                            // icon: 'fa-solid fa-user-circle text-lg',
                            // skipLine: true,
                            className: 'flex items-center',
                            // stickyL: true
                        },
                        {
                            label: 'Deudor',
                            columns: ['envaseDeudorCodigo', 'envaseDeudorDetalles'],
                            // icon: 'fa-solid fa-user-circle text-lg',
                            // skipLine: true,
                            className: 'flex items-center',
                            // stickyL: true
                        },
                        {
                            label: 'Cuaderno',
                            columns: ['cuaderno'],
                        },
                        {
                            label: 'Deuda envase inicial',
                            columns: ['saldo_inicial'],
                            numberValue: true,
                        },
                        {
                            label: 'Deuda envase',
                            columns: ['saldo'],
                            numberValue: true,
                        },
                        {
                            label: 'Monto inicial Bs.',
                            columns: ['monto_garantia_inicial'],
                            numberValue: true,
                        },
                        {
                            label: 'Saldo Bs.',
                            columns: ['monto_garantia'],
                            numberValue: true,
                        },
                        {
                            label: 'Estado',
                            columns: ['estado'],
                            boolean: true,
                            booleanOptions: ['Completado', 'Pendiente'],
                            booleanIconTrue: 'fa-check',
                            booleanIconFalse: 'fa-circle'
                        },
                        {
                            label: 'Fecha',
                            columns: ['createdAt'],
                            transform: true,
                            func: formatDateWithTime
                        },
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'delete',
                                    icon: 'fa-trash',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteDeudaInmediataModal),
                                    reference: 'id'
                                },
                            ],
                        },
                    ]}
                    data={data.data}
                    checkList={false}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
                {
                    DeleteDeudaInmediataModal &&
                    <ModalForm
                        setModal={setDeleteDeudaInmediataModal}
                        label='Eliminar deuda inmediata'
                        dataValues={DeleteValues()}
                        urlApi={`/deudaEnvase/${CurrentData.id}`}
                        method={'delete'}
                        call={reloadTable}
                        buttonLabel='Eliminar'
                    />
                }
            </TableSection>
        </>
    )
}
