import React from 'react'

export const ModalFormBody = ({ children }) => {
    return (
        <div className='p-3 space-y-6 min-h-[270px]'>
            <div className='grid grid-cols-12 gap-x-3 gap-y-5'>
                {children}
            </div>
        </div>
    )
}

export const ModalBodyContent = ({ aditionalClassname, children }) => {
    return (
        <div className={`p-3 space-y-6 min-h-[500px] bg-red-300 ${aditionalClassname}`}>
            <div className='grid grid-cols-12 gap-3'>
                {children}
            </div>
        </div>
    )
}

export const ModalBodySample = ({ aditionalClassname, children }) => {
    return (
        <div className={`p-3 space-y-6 min-h-[500px ${aditionalClassname}`}>
            {children}
        </div>
    )
}