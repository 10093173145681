import React from 'react'
import { ListProductoTable } from './ListProductoTable';
import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { ProductosEstados } from './producto_estados';
import { ErrorPage } from '../../../error/errorPage';

export const Productos = () => {
    // const [mainReloadTable, setMainReloadTable] = useState(false)
    return (
        <div className='grid grid-cols-1 xl:grid-cols-2 gap-2'>
            <div className=''>
            <ListProductoTable />
            </div>
            <Routes>
                    <Route index element={
                        null
                    } />
                    <Route
                        path={`/estados/:producto_id/*`}
                        element={<ProductosEstados />}
                    />
                    <Route path='*' element={<ErrorPage />} />
            </Routes>
        </div>
    )
}