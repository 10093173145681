import { useParams } from 'react-router-dom'
import { ItemCardTargetInfo, LogoTargetPersonalizedInfo, MainTargetInfo, SubTitleTargetBgNotInfo } from '../../../../../components/card/MainTargetInfo'


const CardInfoCli = ({ reload, data, setData }) => {
    const params = useParams()
    return (
        <MainTargetInfo
            extraClassName='grid-cols-1 gap-1'
            urlApi={`/users_cli/${params.user_cli_id}`}
            setData={setData}
            dataRef={params.user_cli_id}
            reload={reload}
        >
            <ItemCardTargetInfo
                logo={<LogoTargetPersonalizedInfo logo='fa-solid fa-user-circle text-2xl' />}
            >
                <SubTitleTargetBgNotInfo label='' data={`${data?.codigo ?? ''} - ${data?.nombres ?? ''} ${data?.apellido_paterno ?? ''} ${data?.apellido_materno ?? ''}`} />
                {/* <DescriptionTargetInfo label='' data={data?.nombres} /> */}
            </ItemCardTargetInfo>
        </MainTargetInfo>
    )
}

export default CardInfoCli