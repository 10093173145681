import React, { useEffect, useState } from 'react'
import { requestAuth } from '../../../../../http/httpRequest'
import { toastOn } from '../../../../../store/slices/toast'
import { useDispatch } from 'react-redux'
import { listToString } from '../../../../../utils/dataValidations'
import MapComponent from '../../../../../components/leafLetViews/MapComponent'
import { CenterSmallSectionList, GeneralContainerProfileCard, ImageOrDefault, LargeSectionList, ProfileData, SectionCard } from '../../../../../components/card/AditionalItems'
import { VerificationImageExist } from '../../../../../utils'
import Avatar from '../../../../../assets/profile.png'

export const PerfilCliente = ({
    reload,
    setReload,
    //
    params,
    recallState,
}) => {
    const [data, setData] = useState(null)
    const dispatch = useDispatch()
    const getData = async () => {
        await requestAuth(
            'get',
            `/users_cli/${params.user_cli_id}`,
            null
        )
            .then((response) => (
                setData(response.data)
            ))
            .catch((error) => (
                dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las casillas.' }))
            ))
    }
    useEffect(() => {
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.user_cli_id]);

    // const recall = () => {
    //     setReload(!reload)
    // }

    return (
        <GeneralContainerProfileCard>
            <SectionCard>
                <div className='bg-white border-t-4 border-red-600 dark:border-red-700 rounded-lg shadow dark:bg-gray-800 p-5'>
                    <div className='flex flex-col items-center'>
                        <ProfileData
                            tipoNegocio={data?.tipo_negocio}
                            image={data?.tipo_negocio ? require(`../../../../../assets/${VerificationImageExist(data?.tipo_negocio)}`) : Avatar}
                            header={listToString([data?.nombres, data?.apellido_paterno, data?.apellido_materno], ' ')}
                        />
                    </div>
                    <CenterSmallSectionList
                        listData={[
                            {
                                label: 'Teléfono:',
                                value: data?.telefono
                            },
                            {
                                label: 'Código:',
                                value: data?.codigo
                            },
                            {
                                label: 'Estado:',
                                value: data?.estado
                            },
                        ]}
                    />
                </div>
                <div className='bg-white border-t-4 border-yellow-600 dark:border-yellow-700 rounded-lg shadow dark:bg-gray-800'>
                    <div className='text-gray-500 w-full dark:text-gray-400 border-b-[1px] dark:border-gray-500 p-2'>
                        Detalles del Cliente
                    </div>
                    <LargeSectionList
                        listData={[
                            {
                                label: 'Nombres:',
                                value: data?.telefono
                            },
                            {
                                label: 'Apellido paterno:',
                                value: data?.apellido_paterno
                            },
                            {
                                label: 'Apellido materno:',
                                value: data?.apellido_materno
                            },
                            {
                                label: 'Carnet:',
                                value: data?.ci
                            },
                            {
                                label: 'Teléfono:',
                                value: data?.telefono
                            },
                            {
                                label: 'Teléfono 2:',
                                value: data?.telefono2
                            },
                            {
                                label: 'Teléfono 3:',
                                value: data?.telefono3
                            },
                            {
                                label: 'Zona:',
                                value: data?.zona
                            },
                            {
                                label: 'Tiempo de consumo por turril:',
                                value: data?.tiempo_de_consumo
                            },
                            {
                                label: 'Vendedores encargados:',
                                value: data?.vendedores,
                                type: 'list'
                            }
                        ]}
                    />
                </div>
            </SectionCard>
            <SectionCard>
                <div className='bg-white border-t-4 border-green-600 dark:border-green-700 rounded-lg shadow dark:bg-gray-800 '>
                    <div className='text-gray-500 w-full dark:text-gray-400 border-b-[1px] dark:border-gray-500 p-2'>
                        Foto del negocio
                    </div>
                    <div className='flex justify-center p-2'>
                        <ImageOrDefault image={data?.foto_negocio} />
                    </div>
                </div>
                <div className='bg-white border-t-4 border-blue-600 dark:border-blue-700 rounded-lg shadow dark:bg-gray-800 '>
                    <div className='text-gray-500 w-full dark:text-gray-400 border-b-[1px] dark:border-gray-500 p-2'>
                        Geolocalización
                    </div>
                    <div className='p-2'>
                        {
                            data?.geolocalizacion ?
                                <MapComponent
                                    data={data}
                                />
                                :
                                <div className='text-gray-400 italic text-sm text-center'>
                                    <i className='fa-solid fa-map-location-dot text-[120px] text-gray-500 dark:text-gray-400 mb-3' />
                                </div>
                    }
                    </div>
                </div>
            </SectionCard>
        </GeneralContainerProfileCard>
    )
}