import React, { useState } from 'react'
import CardInfo from './CardInfo'
import { ListSubEstadoTable } from './ListSubEstadoTable';
import { TabTableSection } from '../../../../../components/tab/TabTableSection';

export const SubEstados = () => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <>
            <TabTableSection>
                <CardInfo
                    reload={reload}
                    data={data}
                    setData={setData}
                />
                <ListSubEstadoTable
                    reload={reload}
                    setReload={setReload}
                />
            </TabTableSection>
        </>
    )
}
