// import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { es } from 'date-fns/locale';
import { utcToZonedTime } from 'date-fns-tz';
// import { formatInputDatetime } from '../../utils/dateFormat';

export function DateTimePickerBasic({
    name,
    value,
    setValues,
    required,
    onChange,
}) {
    // const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        // console.log('date:' + date)
        // if(date !== null)
        // {
        //     const datet = formatInputDatetime(date)
        //     console.log('DATED:' + datet)

        //     setValues(name, new Date(datet))
        // }
        // else{
        //     setValues(name, null)
        // }
        // console.log(date.setHours(-4))
        const adjustedDate = utcToZonedTime(date, 'America/Caracas')
        // console.log(adjustedDate)
        if (!isNaN(adjustedDate.getTime())) {
            // console.log(adjustedDate)
            // setSelectedDate(adjustedDate)
            setValues(name, adjustedDate)
        }
        else {
            setValues(name, null)
            // setSelectedDate(null)
        }

    };

    return (
        <DatePicker
            name={name}
            selected={value !== null ? new Date(value) : null}
            required={required}
            onChange={handleDateChange}
            dateFormat='MMMM d, yyyy HH:mm'
            showTimeSelect
            timeCaption='Hora'
            timeIntervals={30}
            timeFormat='HH:mm'
            className='bg-gray-500 dark:bg-gray-600 text-white rounded-lg'
            calendarClassName='left-8 sm:left-8'
            toggleCalendarOnIconClick
            isClearable
            locale={es}
            placeholderText='enero 1, 2024 12:00'
        />
    );
}
