import React, { useEffect, useState } from 'react'
import { CardTable } from '../../../../../components/card/CardTable'
import { requestDefaulAuth } from '../../../../../http/httpRequest'
import { useDispatch } from 'react-redux'
import { formatDefaultDate } from '../../../../../utils/dateFormat'
import { ActionSection } from '../../../../../components/table/ActionSection'
import { MinimalActionButton } from '../../../../../components/form/minimalActionButton'
import { Actions } from '../../../../../components/form/actions'
import { ModalForm } from '../../../../../components/modals/ModalForm'
import { UpdateAlmacenValues } from '../../../../../FormSchemes/CuadernoScheme'
import { Callers } from '../../../../../hooks/Callers'

export const ListCuadernoAlmacenTable = ({
    params,
    dataCard
}) => {
    const [Data, setData] = useState(null)
    const [Loading, setLoading] = useState(true)
    const [Recall, setRecall] = useState(false)
    const [updateAlmacenModal, setUpdateAlmacenModal] = useState(false)
    const dispatch = useDispatch()

    const getDataPaginate = async () => {
        setLoading(true)
        await requestDefaulAuth(
            'get',
            `cuaderno/${params.cuaderno_id}/almacenes`,
            null,
            setData,
            dispatch
        )
        setLoading(false)
    }
    useEffect(() => {
        getDataPaginate()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Recall])

    const reloadTable = () => {
        Callers(dispatch)
        setRecall(x => !x)
    }

    return (
        <>
            <ActionSection>
                <div className='flex gap-2 flex-wrap p-1'>
                    <Actions
                        buttons={[
                            {
                                icon: 'calendar-day',
                                label: 'Actualizar fecha',
                                action: () => setUpdateAlmacenModal(true),
                                className: 'dark:bg-indigo-700 bg-indigo-500 text-white',
                                disabled: false
                            },
                        ]
                        }
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-1'>
                <CardTable
                    titulo='Turriles con Contenido / 200lt'
                    saldoAnterior={Data?.tcc200?.saldoAnterior}
                    ingreso={Data?.tcc200?.ingreso}
                    totalTurriles={Data?.tcc200?.totalTurriles}
                    egreso={Data?.tcc200?.egreso}
                    saldoActual={Data?.tcc200?.saldoActual}
                    fecha={formatDefaultDate(Data?.fecha, '-')}
                    header={[
                        {
                            label: 'Tipo',
                            columns: ['tipo']
                        },
                        {
                            label: 'Vendedor',
                            columns: ['vendedor']
                        },
                        {
                            label: 'Transportista',
                            columns: ['transportista']
                        },
                        {
                            label: 'Cantidad',
                            columns: ['cantidad'],
                            numberValue: true
                        },
                        {
                            label: 'Observaciones',
                            columns: ['obs'],
                            numberValue: true
                        },
                    ]}
                    dataList={Loading ? [] : Data?.tcc200?.dataList}
                    Loading={Loading}
                    setLoading={setLoading}
                />
                <CardTable
                    titulo='Turriles con Contenido / 100lt'
                    saldoAnterior={Data?.tcc100?.saldoAnterior}
                    ingreso={Data?.tcc100?.ingreso}
                    totalTurriles={Data?.tcc100?.totalTurriles}
                    egreso={Data?.tcc100?.egreso}
                    saldoActual={Data?.tcc100?.saldoActual}
                    fecha={formatDefaultDate(Data?.fecha, '-')}
                    header={[
                        {
                            label: 'Tipo',
                            columns: ['tipo']
                        },
                        {
                            label: 'Vendedor',
                            columns: ['vendedor']
                        },
                        {
                            label: 'Transportista',
                            columns: ['transportista']
                        },
                        {
                            label: 'Cantidad',
                            columns: ['cantidad'],
                            numberValue: true
                        },
                        {
                            label: 'Observaciones',
                            columns: ['obs'],
                            numberValue: true
                        },
                    ]}
                    dataList={Loading ? [] : Data?.tcc100?.dataList}
                    Loading={Loading}
                    setLoading={setLoading}
                />
                <CardTable
                    titulo='Turriles Vacios / 200lt'
                    saldoAnterior={Data?.tv200?.saldoAnterior}
                    ingreso={Data?.tv200?.ingreso}
                    totalTurriles={Data?.tv200?.totalTurriles}
                    egreso={Data?.tv200?.egreso}
                    saldoActual={Data?.tv200?.saldoActual}
                    fecha={formatDefaultDate(Data?.fecha, '-')}
                    header={[
                        {
                            label: 'Tipo',
                            columns: ['tipo']
                        },
                        {
                            label: 'Vendedor',
                            columns: ['vendedor']
                        },
                        {
                            label: 'Transportista',
                            columns: ['transportista']
                        },
                        {
                            label: 'Cantidad',
                            columns: ['cantidad'],
                            numberValue: true
                        },
                        {
                            label: 'Observaciones',
                            columns: ['obs'],
                            numberValue: true
                        },
                    ]}
                    dataList={Loading ? [] : Data?.tv200?.dataList}
                    Loading={Loading}
                    setLoading={setLoading}
                />
                <CardTable
                    titulo='Turriles Vacios / 100lt'
                    saldoAnterior={Data?.tv100?.saldoAnterior}
                    ingreso={Data?.tv100?.ingreso}
                    totalTurriles={Data?.tv100?.totalTurriles}
                    egreso={Data?.tv100?.egreso}
                    saldoActual={Data?.tv100?.saldoActual}
                    fecha={formatDefaultDate(Data?.fecha, '-')}
                    header={[
                        {
                            label: 'Tipo',
                            columns: ['tipo']
                        },
                        {
                            label: 'Vendedor',
                            columns: ['vendedor']
                        },
                        {
                            label: 'Transportista',
                            columns: ['transportista']
                        },
                        {
                            label: 'Cantidad',
                            columns: ['cantidad'],
                            numberValue: true
                        },
                        {
                            label: 'Observaciones',
                            columns: ['obs'],
                            numberValue: true
                        },
                    ]}
                    dataList={Loading ? [] : Data?.tv100?.dataList}
                    Loading={Loading}
                    setLoading={setLoading}
                />
            </div>
            {
                updateAlmacenModal &&
                <ModalForm
                    setModal={setUpdateAlmacenModal}
                    label={<span>Actualizar fecha de inicio y finalización del cuaderno <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={UpdateAlmacenValues(dataCard)}
                    urlApi={`/cuaderno/${params.cuaderno_id}/almacenes`}
                    method={'post'}
                    call={() => {
                        setRecall(x => !x)
                    }
                    }
                    buttonLabel='Actualizar'
                />
            }
        </>
    )
}