import { validationDateParam } from '../utils/dateFormat'
import * as Yup from 'yup';


export const CreateValues = () => {
    return {
        initialValues: {
            etiqueta: '',
            descripcion: '',
            permitir_cuaderno: false,
            permitir_almacen: false,
            empleado: false,
            responsable_vehiculo: false,
            menus: [],
        },
        fieldsValidation: Yup.object().shape({
            etiqueta: Yup.string()
                .min(5, 'Mínimo 4 caracteres!')
                .max(50, 'Máximo 40 caracteres!')
                .required('Campo requerido'),
        }),
        fields: [
            {
                label: 'Nombre del rol',
                name: 'etiqueta',
                type: 'text',
                required: true,
                placeholder: 'Nombre...',
                autoFocus: true
            },
            {
                label: 'Descripción',
                name: 'descripcion',
                type: 'text',
                required: false,
                placeholder: 'Descripción...',
            },
            {
                label: '¿Permitir abrir cuaderno?',
                name: 'permitir_cuaderno',
                type: 'checkbox',
            },
            {
                label: '¿Permitir gestionar almacén?',
                name: 'permitir_almacen',
                type: 'checkbox',
            },
            {
                label: '¿És empleado?',
                name: 'empleado',
                type: 'checkbox',
            },
            {
                label: '¿És responsable de un vehiculo?',
                name: 'responsable_vehiculo',
                type: 'checkbox',
            },
            {
                label: 'Asignar menus',
                name: 'menus',
                type: 'checkboxes',
                required: false,
                urlApi: '/menus',
                indexLabel: 'etiqueta'
            },
        ]
    }
}

export const UpdateValues = (data) => {
    let menus = []
    data?.menus?.forEach(menu => {
        menus.push(menu.id + '')
    });

    return {
        initialValues: {
            id: data?.id,
            etiqueta: data?.etiqueta || '',
            createdAt: validationDateParam(data?.createdAt) || '',
            descripcion: data?.descripcion || '',
            permitir_cuaderno: data?.permitir_cuaderno || false,
            permitir_almacen: data?.permitir_almacen || false,
            empleado: data?.empleado || false,
            responsable_vehiculo: data?.responsable_vehiculo || false,
            menus: menus,
        },
        fieldsValidation: Yup.object().shape({
            etiqueta: Yup.string()
                .min(5, 'Mínimo 4 caracteres!')
                .max(50, 'Máximo 40 caracteres!')
                .required('Campo requerido'),
        }),
        fields: [
            {
                label: 'Nombre del rol',
                name: 'etiqueta',
                type: 'text',
                required: true,
                placeholder: 'Nombre...',
                autoFocus: true
            },
            {
                label: 'Fecha de creación',
                name: 'createdAt',
                type: 'date',
                required: false,
                disabled: true
            },
            {
                label: 'Descripción',
                name: 'descripcion',
                type: 'text',
                required: false,
                placeholder: 'Descripción...',
            },
            {
                label: '¿Permitir abrir cuaderno?',
                name: 'permitir_cuaderno',
                type: 'checkbox',
            },
            {
                label: '¿Permitir gestionar almacén?',
                name: 'permitir_almacen',
                type: 'checkbox',
            },
            {
                label: '¿És empleado?',
                name: 'empleado',
                type: 'checkbox',
            },
            {
                label: '¿És responsable de un vehiculo?',
                name: 'responsable_vehiculo',
                type: 'checkbox',
            },
            {
                label: 'Asignar menus',
                name: 'menus',
                type: 'checkboxes',
                required: false,
                urlApi: '/menus',
                indexLabel: 'etiqueta'
            },
        ]
    }
}

export const DeleteValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}