import React, { useState } from 'react'
import CardInfo from './CardInfo'
import { CuadernoTab } from './CuadernoTab'
import { TabTableSection } from '../../../../../components/tab/TabTableSection'

export const CuadernoHistorial = ({ mainReloadTable, setMainReloadTable }) => {
    const [reload, setReload] = useState(false)
    const [data, setData] = useState(null)
    return (
        // <>
        //     <div className='relative col-span-1 border-t-[30px] border-b-2 border-l-2 border-r-2 dark:border-gray-800 border-gray-600 p-1 rounded-t-xl rounded-b-md'>
        //         <div>
        //             <button onClick={() => navigate('..')} className='absolute z-10 right-2 -top-7 text-gray-300 dark:text-gray-400 hover:text-gray-400 hover:dark:text-gray-300' title='Cerrar ventana'>
        //                 <i className='fa-solid fa-close text-lg' />
        //             </button>
        //         </div>
        <TabTableSection>
            <CardInfo
                reload={reload}
                data={data}
                setData={setData}
            />
            <CuadernoTab
                reload={reload}
                setReload={setReload}
                dataCard={data}
                mainReloadTable={mainReloadTable}
                setMainReloadTable={setMainReloadTable}
            />
            {/* </div> */}
        </TabTableSection>
    )
}
