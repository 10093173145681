import React, { useState } from 'react'
import CardInfoCli from './CardInfo'
import { ClienteTab } from './ClienteTab'
import { TabTableSection } from '../../../../../components/tab/TabTableSection';

export const PerfilCliente = () => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <TabTableSection>
            <CardInfoCli
                reload={reload}
                data={data}
                setData={setData}
            />
            <ClienteTab
                reload={reload}
                setReload={setReload}
                dataCard={data}
            />
        </TabTableSection>
    )
}